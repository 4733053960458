/* eslint-disable */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Product from '../../types/product';
import productService from '../../services/product-service';
import Definition from '../../types/listing-component-definition';
import { ColumnType } from 'antd/lib/table';
import ListingComponent from '../../support/create-listing-component';
import ProductFieldPurpose from '../../types/product-field-purpose';
import productFieldService from '../../services/product-field-service';
import categoriesService from '../../services/categories-service';
import ProductField from '../../types/product-field';
import { OrderMap } from '../../types/category';

const initialDefinition : Definition<Product> = {
  singular: 'product',
  columns: [
    {
      title: 'Supplier',
      dataIndex: 'supplierName',
      key: 'supplierName'
    }
  ],
  editLink: `/products`
};

const getProductsAndFlatten = async () => {
  const products = await productService.getAll();

  return products.map((product : Product) => ({
    ...product,
    supplierName: product.supplier.displayName,
    ...product.data,
    id: product.id
  }))
}

const createColumn = (field: ProductField) : ColumnType<Product> => {
  const col : ColumnType<Product> = { key: field.name, dataIndex: field.name, title: field.displayName };
  if (field.isMultiple) {
    col.render = (value: any[], item: Product) => {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return <></>;
        }

        const values = value.slice(0, 2);
        return (<span>{values.join(', ')}...</span>);
      } else {
        return <></>;
      }
    }
  }

  return col;
}

type ProductFieldWithIndex = ProductField & { index?: number };

type Props = {
}

const Products : React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [def, setDef] = useState<Definition<Product>>({ ...initialDefinition });
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const categoryGroup = (await categoriesService.getByType('product_field'));
      
      const fieldOrders = categoryGroup.values.map(x => x.fieldOrder).reduce((prev: OrderMap, curr: OrderMap) => ({ ...prev, ...curr }), {});

      let productFields = (await productFieldService.getAll()).filter(x => x.purpose === ProductFieldPurpose.core);

      for (const productField of productFields as ProductFieldWithIndex[]) {
        const index = fieldOrders[productField.id];
        productField.index = index;
      }

      productFields = _.sortBy(productFields, ['category', 'index']);

      const newDef = { ...def };

      let summaryProductFields = productFields.filter(x => x.isSummary);

      if (summaryProductFields.length === 0 && productFields.length > 0) {
        summaryProductFields = [productFields[0]];
      }

      const columns : ColumnType<Product>[] = summaryProductFields.map(createColumn);

      newDef.columns = [...newDef.columns, ...columns];

      setDef(newDef);
      setIsLoading(false);
    }

    run();
  }, []);

  return (
    <>
      <h1>Products</h1>
      <ListingComponent
        definition={def}
        getAll={getProductsAndFlatten}
        deleteOne={productService.delete}
        isLoading={isLoading}
      />
    </>
  )
}

export default Products;
