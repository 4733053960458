import React from 'react';
import { Input } from 'antd';
import FormItem from '../../../../components/forms/form-item';
import { EditClientState, Actions } from './logic';
import Client from '../../../../types/client';
import { TemplateProps } from '../../../../support/edit-wrapper-v2/edit-wrapper-v2';

type Props = TemplateProps<Client, EditClientState, Actions>;

const Template : React.FunctionComponent<Props> = ({ state, actions }) => {
  const { item } = state;

  return (
    <>
      <FormItem
        label="Name"
      >
        <Input
          value={item.name}
          disabled={state.isLoading}
          onChange={e => actions.mutate(item, 'name', e.target.value)}
        />
      </FormItem>
    </>
  )
};

export default Template;