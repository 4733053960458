import React from 'react';
import { EditProductFieldState } from './logic';
import ProductField from '../../../types/product-field';

import SmartDynamicForm from '../../../components/dynamic-form/smart-dynamic-form';

interface EditProductFieldDumbTemplateProps {
  state: EditProductFieldState,
  mutateAction: (...args: any[]) => void
};

const Template: React.FunctionComponent<EditProductFieldDumbTemplateProps> = ({ state, mutateAction }) => {
  return (
    <>
      
      {/* TODO: This should also display the errors that come back from the server
      
      <Alert
        message="Validation Error"
        description="You need to fix the errors on the form"
        type="error"
      /> */}
      <SmartDynamicForm<ProductField>
        structure={state.structure}
        value={state.item as unknown as ProductField}
        onChange={mutateAction}
      />
    </>
  )
}

export default Template;