import React from 'react';
import { Error } from './error';

type Props = {
  errors?: Error[]
}

const Errors: React.FC<Props> = ({ errors }) => {
  if (Array.isArray(errors) && errors.length > 0) {
    return (
      <div>
        <h2>Please fix these problems:</h2>

        <ul>
          {errors.map((e: Error) => (
            <li>{e.description}</li>
          ))}
        </ul>
      </div>
    ) 
  } else {
    return <></>;
  }
}

export default Errors;
