import React from 'react';
import Condition from '../../types/condition';
import DumbEditTemplate from './edit-template/dumb';
import logic, { EditConditionState } from './edit-template/logic';
import PageId from '../../types/page-id';
import EditWrapper from '../../support/EditWrapper';

type Props = {
  id: string,
  history: any
}

const EditCondition : React.FC<Props> = ({ id, history }) => {
  return (
    <EditWrapper<Condition, EditConditionState>
      titleText="Matching Rule"
      itemTitle="matching rule"
      logic={logic}
      createTemplate={() => DumbEditTemplate}
      listingLink={PageId.conditions}
      id={id}
      history={history}
    />
  )
 };

export default EditCondition;
