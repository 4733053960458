import React, { useReducer } from 'react';
import DumbEditTemplate from './edit-template/dumb';
import { reducer, initialState, init, EditSearchItemFieldState } from './edit-template/logic/reducer';
import { Actions, createActions } from './edit-template/logic/actions';
import EditWrapper from '../../support/edit-wrapper-v2/edit-wrapper-v2';
import PageId from '../../types/page-id';
import SearchItemField from '../../types/search-item-field';

type Props = {
  id: string,
  history: any
}

export const EditSearchItemFieldForStoryBook : React.FC<{}> = () => {
  const [state, dispatch] = useReducer(reducer, initialState, init);

  const actions = createActions(dispatch);

  return (
    <DumbEditTemplate
      actions={actions}
      state={state}
    />
  )
}

const WrappedEditSearchItemField : React.FC<Props> = ({ id, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);

  const actions = createActions(dispatch);

  return (
    <EditWrapper<SearchItemField, EditSearchItemFieldState, Actions>
      id={id}
      titleText="Edit Search Item Field"
      itemTitle="search item field"
      helpText="This is some text that describes what a search item field is"
      listingLink={PageId.searchItemDefinition}
      history={history}
      createTemplate={() => DumbEditTemplate}
      actions={actions}
      state={state}
    />
  )
}

export default WrappedEditSearchItemField;
