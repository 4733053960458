import produce from 'immer';
import services from '../../../../services';
import { createActions as createEditorActions, reducer as editorReducer, EditorActions, StandardEditActions, EditItemState, getInitialState } from '../../../../support/edit-wrapper-v2/logic/reducer';
import Client from '../../../../types/client';

const debug = require('debug')('mk:api-access:clients:edit:logic');

const MUTATE = 'MUTATE';
export interface Actions extends EditorActions<Client> {
  mutate: (val: Client, fieldName: keyof Client, fieldValue: any) => void
}
export type EditClientState = EditItemState<Client>;
export interface MutateAction {
  type: typeof MUTATE,
  val: Client,
  fieldName: string,
  fieldValue: any
}
export type Action = StandardEditActions<Client> | MutateAction;

export function createActions(dispatch: React.Dispatch<Action>): Actions {
  const definition = {
    getOne: services.clientsService.get,
    save: services.clientsService.create
  }
  const actions: Actions = {
    ...createEditorActions(dispatch, definition),
    mutate: (val, fieldName, fieldValue) => {
      dispatch({
        type: MUTATE,
        val,
        fieldName,
        fieldValue
      })
    }
  }
  return actions;
}

export const initialState: EditClientState = {
  ...getInitialState(),
  item: {
    id: 0,
    name: ''
  }
}

export function reducer(state: EditClientState, action: Action) : EditClientState {
  switch (action.type) {
    case MUTATE: {
      debug('MUTATE', action);
      return produce(state, draft => {
        (draft.item as { [key: string]: any })[action.fieldName] = action.fieldValue;
        return draft;
      });
    }
    default: {
      return editorReducer(state, action);
    }
  }
}