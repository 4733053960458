import React from 'react';
import ReactDOM from 'react-dom';
import Main from './router/main';
import * as serviceWorker from './serviceWorker';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import LogRocket from 'logrocket';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('6osrbt/test-project');
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_IVlg7eIHbl7AlMrIv5iqc1sb00OocAYGJT");

// ReactDOM.render(
//   <React.StrictMode>
//     {/* <Auth0Provider
//       domain={AUTH_DOMAIN}
//       clientId={CLIENT_ID}
//       redirectUri={REDIRECT_URI}
//       audience={AUDIENCE}
//       scope="manage:data-structure"
//     > */}
//       {/* <Elements stripe={stripePromise}> */}
//         {/* <AuthContext.Provider value={{ isLoggedIn: false }}> */}
//           <Main />
//         {/* </AuthContext.Provider> */}
//       {/* </Elements> */}
//       {/* </Auth0Provider> */}
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);

serviceWorker.unregister();
