import ProductField from "./product-field";
import SearchItemField from "./search-item-field";

enum ConditionType {
  GTE = 'GTE',
  LTE = 'LTE',
  EQ = 'EQ',
  ITEM_EXISTS_IN_ARRAY = 'ITEM_EXISTS_IN_ARRAY',
  ITEM_NOT_EXISTS_IN_ARRAY = 'ITEM_NOT_EXISTS_IN_ARRAY',
  SOME_ITEMS_EXIST_IN_ARRAY = 'SOME_ITEMS_EXIST_IN_ARRAY',
  NO_ITEMS_EXIST_IN_ARRAY = 'NO_ITEMS_EXIST_IN_ARRAY',
  ALL_ITEMS_EXIST_IN_ARRAY = 'ALL_ITEMS_EXIST_IN_ARRAY',
  ARRAYS_ARE_IDENTICAL = 'ARRAYS_ARE_IDENTICAL',
  GEO_COORDS_WITHIN = 'GEO_COORDS_WITHIN',
  GEO_POSTCODE_WITHIN = 'GEO_POSTCODE_WITHIN'
}

export { ConditionType }

type Condition = {
  id: number;
  displayName: string;
  type: ConditionType;
  productFieldId: number;
  searchItemFieldId: number;
  productField?: ProductField;
  searchItemField?: SearchItemField;
  code: string;
  category: string;
  isEmptyProductValueMatches: boolean;
  isEnabled: boolean;
};

export default Condition;