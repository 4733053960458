enum EventCode {
  LOAD_TIMED_OUT = 'LOAD_TIMED_OUT',
  LOAD_TAKING_LONG_TIME = 'LOAD_TAKING_LONG_TIME',
  ITEM_SAVED_SUCCESSFULLY = 'ITEM_SAVED_SUCCESSFULLY',
  ITEM_SAVE_ERROR = 'ITEM_SAVE_ERROR',
  USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN',
  ITEM_DELETED_SUCCESSFULLY = 'ITEM_DELETED_SUCCESSFULLY',
  ITEM_NOT_DELETED_SUCCESSFULLY = 'ITEM_NOT_DELETED_SUCCESSFULLY'
}

export default EventCode;