import React from 'react';
import { useLocation } from 'react-router-dom';

const debug = require('debug')('mk:user-status-guard');

type Props = {
  location: string
}

class UserStatusGuard extends React.Component<Props> {
  componentDidMount() {
    debug('componentDidMount');
  }

  componentDidUpdate(prevProps : Props) {
    debug('componentDidUpdate');
    debug('props.location', this.props.location);
    debug('prevProps.location', prevProps.location);
  }

  render() {
    return <></>;
  }
}

const withLocation = (Child: any) => () => {
  const location = useLocation();

  return (
    <Child location={location} />
  );
}

export default withLocation(UserStatusGuard);
