import React from 'react';
// import ChangePassword from './change-password';

export default function Profile () {
  return (
    <>
      
    </>
  );
}
