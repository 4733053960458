import React from 'react';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;

  button {
    margin-right: 15px;
  }
`;

export default ButtonsContainer;
