/* eslint-disable */ // useEffect linting causes a problem otherwise
import React, { useEffect, useState } from 'react';
import Product from '../../types/product';
import productCommonValuesService from '../../services/product-common-values';
import Definition from '../../types/listing-component-definition';
import { ColumnType } from 'antd/lib/table';
import ListingComponent from '../../support/create-listing-component';
import ProductFieldPurpose from '../../types/product-field-purpose';
import productFieldService from '../../services/product-field-service';

const initialDefinition : Definition<any> = {
  singular: 'product',
  columns: [
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier'
    }
  ],
  editLink: `/products-common`
};

type Props = {
}

const ProductsCommon : React.FC<Props> = () => {
  const [def, setDef] = useState<Definition<any>>({ ...initialDefinition });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const productFields = await productFieldService.getAll();

      const filteredProductFields = productFields.filter(x => x.purpose === ProductFieldPurpose.common);

      const newDef = { ...def };

      let summaryProductFields = filteredProductFields.filter(x => x.isSummary);

      if (summaryProductFields.length === 0 && filteredProductFields.length > 0) {
        summaryProductFields = [filteredProductFields[0]];
      }
    
      const columns : ColumnType<Product>[] = filteredProductFields.map(x => ({ key: x.name, dataIndex: x.name, title: x.displayName }));
    
      newDef.columns = [ ...def.columns, ...columns ];

      setDef(newDef);
      setIsLoading(false);
    }

    run();
  }, []);

  return (
    <>
      <h1>Common Product Values</h1>
      <ListingComponent
        definition={def}
        getAll={productCommonValuesService.getAll}
        deleteOne={productCommonValuesService.delete}
        isLoading={isLoading}
      />
    </>
  )
}

export default ProductsCommon;
