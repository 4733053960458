import { Subject } from 'rxjs';
import createDebug from 'debug';
import Condition, { ConditionType } from "../../../types/condition";
import ProductField from "../../../types/product-field";
import SearchItemField from "../../../types/search-item-field";
import FieldType from "../../../types/field-type";
import services from "../../../services";
import ItemSaveResult from "../../../types/item-save-result";

const debug = createDebug('mk:edit-condition:logic');

export type EditConditionState = {
  item: Partial<Condition>,
  types: ConditionType[],
  productFields: ProductField[],
  searchItemFields: SearchItemField[],
  isConditionTypeDisabled: boolean,
  isFetchingReferenceData: boolean,
  isLoading: boolean,
  isSaving: boolean
}

export type Actions = {
  mutate: (item: Partial<Condition>) => Promise<void>,
  newItem: () => Promise<void>,
  edit: (id : number) => Promise<void>,
  save: () => Promise<ItemSaveResult<Condition>>
}

const setAllowedTypes = (state: EditConditionState, newItem: Partial<Condition>, productField?: ProductField, searchItemField?: SearchItemField) => {
  if (! (productField && searchItemField)) {
    return {
      ...state,
      item: newItem,
      types: [] as ConditionType[],
      isConditionTypeDisabled: true
    };
  }

  if (productField.isMultiple && !searchItemField.isMultiple) {
    return {
      ...state,
      item: newItem,
      types: [ConditionType.ITEM_EXISTS_IN_ARRAY, ConditionType.ITEM_NOT_EXISTS_IN_ARRAY],
      isConditionTypeDisabled: false
    };
  }

  if (productField.isMultiple && searchItemField.isMultiple) {
    return {
      ...state,
      item: newItem,
      types: [ConditionType.NO_ITEMS_EXIST_IN_ARRAY, ConditionType.ALL_ITEMS_EXIST_IN_ARRAY, ConditionType.SOME_ITEMS_EXIST_IN_ARRAY],
      isConditionTypeDisabled: false
    };
  }

  if ((productField.type === FieldType.number && searchItemField.type === FieldType.number) ||
       (productField.type === FieldType.integer && searchItemField.type === FieldType.integer)) {
    return {
      ...state,
      item: newItem,
      types: [ConditionType.GTE, ConditionType.LTE, ConditionType.EQ],
      isConditionTypeDisabled: false
    };
  }

  if (productField.type === FieldType.string && searchItemField.type === FieldType.string) {
    return {
      ...state,
      item: {
        ...newItem,
        type: ConditionType.EQ
      },
      types: [ConditionType.EQ],
      isConditionTypeDisabled: false
    };
  }

  return {
    ...state,
    types: [],
    isConditionTypeDisabled: true,
    item: {
      ...newItem,
      type: undefined
    }
  }
}

const createActions = (stateChanges: Subject<Partial<EditConditionState>>) => function handle(state: EditConditionState) : Actions {
  return {
    newItem: async () => {
      debug('NEW ITEM');
      stateChanges.next({ isLoading: true, isFetchingReferenceData: true });

      const [productFields, searchItemFields] = await Promise.all<ProductField[], SearchItemField[]>([services.productFieldService.getAll(), services.searchItemFieldService.getAll()]);

      const newState = {
        ...state,
        isValid: false,
        productFields,
        searchItemFields,
        isFetchReferenceData: false,
        isLoading: false
      }

      stateChanges.next(newState)
    },
    edit: async (id: number) => {
      debug('EDIT', id);
      stateChanges.next({ isLoading: true });
      const [productFields, searchItemFields] = await Promise.all<ProductField[], SearchItemField[]>([services.productFieldService.getAll(), services.searchItemFieldService.getAll()]);
      const condition = await services.conditionService.get(id);

      const newState = setAllowedTypes(state, condition, condition.productField, condition.searchItemField);

      stateChanges.next({
        ...newState,
        isLoading: false,
        productFields,
        searchItemFields
      });
    },
    save: async () => {
      stateChanges.next({ isLoading: true });

      const itemToSave = {
        ...state.item
      };

      delete itemToSave.productField;
      delete itemToSave.searchItemField;
      
      const itemSaveResult = await services.conditionService.create(itemToSave as Condition);

      stateChanges.next({ isLoading: false }); // TODO: This would send back some kind of save success / validation error
      return itemSaveResult;
    },
    mutate: async (item: Partial<Condition>) => {
      debug('MUTATE', item);
      const newItem = {
        ...state.item,
        ...item
      };

      const productField = state.productFields.find(x => x.id === newItem.productFieldId);
      const searchItemField = state.searchItemFields.find(x => x.id === newItem.searchItemFieldId);

      newItem.productField = productField;
      newItem.searchItemField = searchItemField;
      
      const newState = setAllowedTypes(state, newItem, productField, searchItemField);
    
      stateChanges.next(newState);
      return;
    }
  }
}

const logic = {
  getInitialState: () => {
    const state : EditConditionState = {
      item: {
        isEmptyProductValueMatches: false,
        isEnabled: true
      },
      types: [],
      productFields: [],
      searchItemFields: [],
      isConditionTypeDisabled: false,
      isFetchingReferenceData: true,
      isLoading: true,
      isSaving: false
    }
  
    return state;
  },
  createActions
}

export default logic;
