import React from 'react';
import ListingComponent from '../../../support/create-listing-component';
import Definition from '../../../types/listing-component-definition';
import Client from '../../../types/client';
import ItemDeleteResult from '../../../types/item-delete-result';

const def : Definition<Client> = {  
  singular: 'client',
  columns: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    }
  ],
  editLink: `/clients`
};

type Props = {
  getAll: () => Promise<Client[]>,
  deleteOne: (id: number) => Promise<ItemDeleteResult>
}

const ClientsIndexDumb : React.FC<Props> = ({ getAll, deleteOne }) => (
  <>
    <h2>API Clients</h2>

    <ListingComponent
      definition={def}
      getAll={getAll}
      deleteOne={deleteOne}
      isLoading={false}
    />
  </>
);

export default ClientsIndexDumb;
