import React from 'react';
import ListingComponent from '../../support/create-listing-component';
import services from '../../services';
import { Link } from 'react-router-dom';
import { List } from '../../types/list';

const def = {
  singular: 'data source',
  columns: [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (val : any, item: any) => (
        <Link to={`${def.editLink}/${item.id}`}>
          {val}
        </Link>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Number of items',
      dataIndex: 'numItems',
      key: 'numItems',
      render: (val: any, record : List) => {
        return record.values.length
      }
    },
  ],
  editLink: `/lists`
}

type Props = {
};

type State = {
}

class ListsIndex extends React.Component<Props, State> {  
  render() {
    return (
      <>
        <h1>Data Sources</h1>

        <p>These are lists that can be shared between product fields and search item fields.</p>

        <ListingComponent
          definition={def}
          getAll={services.listService.getAll}
          deleteOne={services.listService.delete}
          isLoading={true}
        />
      </>
    );
  }  
}

export default ListsIndex;
