import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import PageId from '../types/page-id';

// TODO: Put these in REACT_APP env vars
//   Set differently for staging
export const AUTH_DOMAIN = 'match-kernel.eu.auth0.com';
export const CLIENT_ID = 'fGobcp6vTn6SHCauFJiW601sfZUrk6iy';
export const REDIRECT_URI = window.location.origin;
export const AUDIENCE = 'https://matchkernel.com/api';

type AuthClient = {
  isAuthenticated: () => Promise<boolean>,
  signUp: () => Promise<void>,
  login: (returnUrl?: string) => Promise<void>,
  logout: (returnUrl?: string) => Promise<void>,
  getTokenForMarketplaceAdmin: () => Promise<string>,
}

let auth0Client: Auth0Client;

const ensureAuth0Client = async () => {
  if (!auth0Client) {
    auth0Client = await createAuth0Client({
      domain: AUTH_DOMAIN,
      client_id: CLIENT_ID,
      audience: AUDIENCE,
      scope: 'manage:data-structure',
      responseType: 'id_token token',
      cacheLocation: 'localstorage'
    });
  }

  return auth0Client;
}

const client: AuthClient = {
  isAuthenticated: async () => {
    const auth0Client = await ensureAuth0Client();
    return auth0Client.isAuthenticated();
  },

  signUp: async (returnUrl?: string) => {
    const auth0Client = await ensureAuth0Client();

    return auth0Client.loginWithRedirect({
      redirect_uri: returnUrl || `${window.location.origin}${PageId.callback}`,
      screen_hint: 'signup'
    });
  },

  login: async (returnUrl?: string) => {
    const auth0Client = await ensureAuth0Client();

    return auth0Client.loginWithRedirect({
      redirect_uri: returnUrl || `${window.location.origin}${PageId.callback}`
    });
  },

  logout: async (returnUrl?: string) => {
    const auth0Client = await ensureAuth0Client();

    return auth0Client.logout({
      returnTo: returnUrl || window.location.origin
    });
  },

  getTokenForMarketplaceAdmin: async () => {
    const auth0Client = await ensureAuth0Client();

    const accessToken = await auth0Client.getTokenSilently({
      scope: 'manage:data-structure',
      responseType: 'id_token token'
    });

    return accessToken;
  }
}

export default client;
