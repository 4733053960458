import CrudService from "../types/crud-service";
import backoff from "../util/retry";
import notify from '../events/notify';
import EventCode from '../events/event-code';
import addToken from './add-token';
import ItemSaveResult from '../types/item-save-result';
import ItemDeleteResult from "../types/item-delete-result";
import authClient from '../auth/auth-client';
import PageId from "../types/page-id";

export default <T>(url: string) : CrudService<T> => ({
  getAll: async () : Promise<T[]> => {   
    let requestInfo : any = {
      method: 'GET',
      headers: {
        'content-type': 'application/json', 
      }
    };

    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(url, requestInfo)) as Response;

    if (response.status === 401) {
      // notify(EventCode.USER_NOT_LOGGED_IN, {});
      await authClient.login();
      return [];
    }

    if (response.status === 403) {
      // TODO: Check response body for error code indicating that the
      //       user has not finished onboarding
      window.location.replace(PageId.onboard);
    }

    const data = await response.json();
  
    return data;
  },
  get: async (id: number) : Promise<T> => {
    let requestInfo : any = {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    };

    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(`${url}/${id}`, requestInfo));
    const data = await response.json();
  
    return data;
  },
  create: async (item: T) : Promise<ItemSaveResult<T>> => {
    let requestInfo : any = {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(item)
    };

    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(url, requestInfo)) as Response;
    const data = await response.json();

    if (response.status === 400) {
      notify(EventCode.ITEM_SAVE_ERROR, data);
    }
  
    return data;
  },
  delete: async (id: number) : Promise<ItemDeleteResult> => {
    let requestInfo : any = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json'
      }
    };

    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(`${url}/${id}`, requestInfo));
    const data = await response.json();
  
    return data;
  }
})