import React from 'react';
import styled from 'styled-components';
import { Menu, Layout } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import PageId from '../types/page-id';
import { getMenuChildrenOrSiblingsOf, isRouteMatch } from './sitemap';
import { StyledMenu, StyledMenuItem } from '../components/menu';

const { Sider } = Layout;

const SideMenuLink = styled(Link)`
  font-size: 12px;
`;

export default function SideMenu() {
  const theLocation = useLocation();
  const currentPageId = Object.values(PageId).find(x => isRouteMatch(theLocation.pathname, x)) as PageId;
  const nodeAndItems = getMenuChildrenOrSiblingsOf(currentPageId);

  let SideComponent = <></>;

  if (nodeAndItems) {
    const { items, node } = nodeAndItems;
    let activePageId = PageId.home;
    if (node.isNonMenu && node.showActivePageId) {
      activePageId = node.showActivePageId
    } else {
      activePageId = currentPageId;
    }

    if (items) {
      SideComponent = (
        <StyledMenu direction="vertical">
          {items.map(child => (
            <StyledMenuItem>
              <Link
                style={{ fontSize: '13px' }}
                to={child.link}
              >
                {child.title}
              </Link>
            </StyledMenuItem>
          ))}
        </StyledMenu>
      )
    }
  }

  return (
    <>
      {SideComponent}
    </>
  );
}

