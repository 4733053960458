import React from 'react';

export default function StoredPaymentDetails () {
  return (
    <>
      <h2>Payment Details</h2>

      <div>stuff here</div>
    </>
  );
}