import React from 'react';
import styled from 'styled-components';
import { Table, Skeleton, Popconfirm, message } from 'antd';
import Button from '../components/buttons/button';
import WithId from '../types/with-id';
import { Link } from 'react-router-dom';
import Definition from '../types/listing-component-definition';
import ItemDeleteResult from '../types/item-delete-result';

const debug = require('debug')('mk:support:listing-component');

type GetDataFn<T> = () => Promise<T[]>;
type DeleteOneFn = (id: any) => Promise<ItemDeleteResult>;

type Props<T> = {
  definition: Definition<T>,
  getAll: GetDataFn<T>,
  deleteOne: DeleteOneFn,
  isLoading: boolean,
  isDeleteNotSupported?: boolean,
  isAddButtonHidden?: boolean
};

type ListingComponentState<T> = {
  isLoading: boolean;
  dataSource: T[]
};

const ControlsDiv = styled.div`
  padding: 0 0 15px 0;
`;

// stream of set isLoading: true
// debounce 500ms
// takeuntil(getData$)

class ListingComponent<T extends WithId> extends React.Component<Props<T>, ListingComponentState<T>> {
  constructor(props : Props<T>) {
    super(props);
    this.state = {
      isLoading: false,
      dataSource: []
    }

    this.confirmDelete = this.confirmDelete.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  async confirmDelete(item: T) {
    const result = await this.props.deleteOne(item.id);

    if (result.isSuccess) {
      message.success(`${this.props.definition.singular} was deleted`);
      await this.loadData();
    } else {
      const ruleResults = result.validationResult?.ruleValidationResults || [];
      const ruleMessages = ruleResults.map(x => x.message);
      const details = (
        <span>
          <span>The {this.props.definition.singular} could not be deleted:</span>
          {ruleMessages.map((err?: string) => <div>{err}</div>)}
        </span>
      );
      message.error(details);
    }
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    debug('loadData');
    this.setState({ isLoading: this.props.isLoading }); // initially set isLoading to the prop value
    const data = await this.props.getAll();
    const dataMapped = data.map((x: T) => ({ ...x, key: x.id }));
    debug('dataMapped', dataMapped);
    this.setState({ dataSource: dataMapped });
    this.setState({ isLoading: false  });
  }

  componentDidUpdate(prevProps : Props<T>, prevState: ListingComponentState<T>) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.setState({ isLoading: this.props.isLoading });
    }

    if (prevProps.getAll !== this.props.getAll) {
      this.loadData();
    }
  }

  render() {
    debug('re-render');
    debug('this.state.dataSource', this.state.dataSource);

    const columns = [
      ...this.props.definition.columns
    ];

    if (this.props.isDeleteNotSupported) {
      columns.push({
        title: 'Edit',
        render: (value: any, item: T) => {
          const editLink = this.props.definition.editLink;

          const link = typeof editLink === 'function'
            ? editLink(item)
            : `${editLink}/${item.id}`;
          
          return (
            <>
              <Link to={link}>
                Edit
              </Link>
            </>
          )
        }
      })
    } else {
      columns.push({
        title: 'Edit/Delete',
        render: (value: any, item: T) => {
          const editLink = this.props.definition.editLink;

          const link = typeof editLink === 'function'
            ? editLink(item)
            : `${editLink}/${item.id}`;
          
          return (
            <>
              <Link to={link}>
                Edit
              </Link>
  
              <span> | </span>
  
              <Popconfirm
                title={`Are you sure delete this ${this.props.definition.singular}?`}
                onConfirm={x => this.confirmDelete(item)}
                okText="Yes"
                cancelText="No"
              >
                <a href="/delete#">Delete</a>
              </Popconfirm>
            </>
          )
        }
      })
    }

    let component = <div></div>;
    if (this.state.isLoading) {
      component = (
        <Skeleton active />
      )
    } else {
      component = (
        <>
          <Table
            columns={columns}
            dataSource={this.state.dataSource}
            pagination={false}
          />
        </>
      );
    }

    return (
      <>
        {this.props.isAddButtonHidden
          ? <></>
          : (
            <ControlsDiv>
              <Link to={`${this.props.definition.editLink}/-1`}>
                <Button type="primary">New {this.props.definition.singular}</Button>
              </Link>
            </ControlsDiv>
          )
        }

        {component}
      </>
    );
  }    
};

export default ListingComponent