import React from 'react';
import createDebug from 'debug';
import { Checkbox } from 'antd';
import { FieldProps } from '../field-props';
import ValidationMessages from './validation-messages';

const debug = createDebug('mk:dyn-form:fields:checkbox');

export default class CheckBoxField extends React.Component<FieldProps> {
  shouldComponentUpdate(nextProps: FieldProps) {
    return nextProps.value !== this.props.value
      || nextProps.field !== this.props.field
      || nextProps.validationState !== this.props.validationState
      || nextProps.isDisabled !== this.props.isDisabled;
  }

  render() {
    debug(`CheckBox - render ${this.props.field.name}`);

    const { field, value, validationState, isDisabled } = this.props;
    const { name } = field;

    return (
      <React.Fragment key={name}>
        <Checkbox
          checked={value}
          onChange={x => this.props.onChange(x.target.checked)}
          disabled={isDisabled}
        />

        <ValidationMessages
          validationState={validationState}
        />
    </React.Fragment>
    )
  }
}