import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageId from '../types/page-id';
import { StyledMenu, StyledMenuItem, StyledHamburger } from '../components/menu';

export default function Menu() {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <StyledMenu direction="horizontal">
      <StyledMenuItem isActive={pathname === PageId.home}><Link to={PageId.home}>Dashboard</Link></StyledMenuItem>
      <StyledMenuItem isActive={pathname === PageId.suppliers}><Link to={PageId.suppliers}>Suppliers</Link></StyledMenuItem>
      <StyledMenuItem isActive={pathname === PageId.productDefinitionCore}><Link to={PageId.productDefinitionCore}>Data Structure</Link></StyledMenuItem>
      <StyledMenuItem isActive={pathname === PageId.products}><Link to={PageId.products}>Products</Link></StyledMenuItem>
      <StyledMenuItem isActive={pathname === PageId.playground}><Link to={PageId.playground}>Playground</Link></StyledMenuItem>
      <StyledMenuItem isActive={pathname === PageId.apiAccess}><Link to={PageId.apiAccess}>API</Link></StyledMenuItem>

      <StyledHamburger>
        ---
      </StyledHamburger>
    </StyledMenu>
  );
}
