import Product from "./product";
import ExecutableCondition from './executable-condition';

export interface ProductWithMatchResult {
  product: Product;
  matchType: MatchType;
  categories: string[];
  codes: string[];
  totalDistance?: number;
}

export interface ResultSet {
  count: number;
  results: ProductWithMatchResult[];
}

export interface MatchResultSet {
  count: number,
  results: ProductWithMatchResult[]
}

export interface MatchResults {
  matches: MatchResultSet;
  nearMatches: MatchResultSet;
  nonMatches: MatchResultSet;
}

export interface ConditionResult {
  condition: ExecutableCondition;
  matchType: MatchType;
  message?: string;
  distance?: number;
}

export interface NearMatchedConditionResult {
  isMatch: boolean;
  distance: number;
}

export enum MatchType {
  exact = 'exact',
  exactNotRequired = 'exact-not-required',
  indeterminate = 'indeterminate', // product has conditions that could not be checked
  near = 'near',
  not = 'not'
}