import React from 'react';
import { State } from './logic';
import FormItem from '../../../components/forms/form-item';
import { CategoryGroup } from '../../../types/category';
import KeyValueList from '../../../components/key-value-list';

interface Props {
  state: State,
  mutateAction: (item: Partial<CategoryGroup>) => void
};

const addItem = (mutateAction: any, item: any) => {
  mutateAction({
    values: [...item.values!, { name: '', displayName: '' }]
  })
};

const removeItem = (mutateAction: any, item : any, index: number) => {
  const values = item.values!;

  values.splice(index, 1);

  setImmediate(() => {
    mutateAction({
      values
    })
  })  
};

const changeValue = (mutateAction: any, item: any, index: number, value: string) => {
  const values = item!.values!;
  values[index].name = value;

  mutateAction({ values })
}

const changeDisplayText = (mutateAction: any, item: any, index: number, value: string) => {
  const values = item!.values!;
  values[index].displayName = value;

  mutateAction({ values })
}

const Template: React.FunctionComponent<Props> = ({ state, mutateAction }) => {
  const item = state.item as CategoryGroup;

  return (
    <>
      <FormItem
        label="Categories"
      >
        <KeyValueList
          items={item.values}
          onAddItem={() => addItem(mutateAction, item)}
          onRemoveItem={(index: number) => removeItem(mutateAction, item, index)}
          onChangeValue={(index: number, value: string) => changeValue(mutateAction, item, index, value)}
          onChangeDisplayText={(index: number, value: string) => changeDisplayText(mutateAction, item, index, value)}
        />
      </FormItem>
    </>
  )
}

export default Template;