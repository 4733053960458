import React from 'react';
import StoredPaymentDetails from './stored-payment-details';
import CheckoutForm from './payments/checkout-form';

export default function Subscription () {
  return (
    <>
      <StoredPaymentDetails />

      <CheckoutForm />
    </>
  );
}