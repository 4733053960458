import React from 'react';
import _ from 'lodash';
import FieldSet from './field-set';
import { FormStructure } from './types';

const debug = require('debug')('mk:dyn-form:dumb-dynamic-form');

type Props<TRoot> = {
  structure: FormStructure,
  value: TRoot,
  onChange: (categoryName: string, fieldName: string, value: TRoot) => void
}

export default class DumbDynamicForm<TRoot> extends React.Component<Props<TRoot>> {
  shouldComponentUpdate(nextProps : Props<TRoot>) {
    const shouldUpdate = nextProps.structure !== this.props.structure || nextProps.value !== this.props.value;
    debug('DumbDynamicForm shouldUpdate', shouldUpdate);
    return shouldUpdate;
  }

  render() {
    debug('DumbDynamicForm render()');
    const { structure, onChange, value } = this.props;
    const { categories, isDisabled } = structure;

    debug('structure', structure);

    const rawCategories = Object.values(structure.categories);
    const sortedCategories = _.sortBy(rawCategories, ['index']);
    const categoryNames = sortedCategories.map(x => x.name);

    return (
      <>
        {categoryNames.map(categoryName => {
          return (
            <FieldSet
              key={categoryName}
              categoryName={categoryName}
              structure={categories[categoryName]}
              value={value}
              isDisabled={isDisabled}
              onChange={(categoryName, fieldName, value) => onChange(categoryName, fieldName, value)}
            />
          )
        })}
      </>
    )
  }
}