import PageId from '../types/page-id';
import { matchPath } from "react-router";

export const isRouteMatch = (currentLocation: string, pageId: PageId) => {
  const result = matchPath(currentLocation, {
    path: pageId,
    exact: true,
    strict: true
  });

  return result !== null;
}

type Node = {
  link: PageId,
  title: string,
  children?: Node[],
  isNonMenu?: boolean,
  showActivePageId?: PageId
}

const sitemap : Node[] = [
  { link: PageId.home, title: 'Dashboard' },
  { link: PageId.suppliers, title: 'Suppliers', children: [
      { link: PageId.editSupplier, title: 'Edit Supplier', isNonMenu: true, showActivePageId: PageId.suppliers }
    ] },
  {
    link: PageId.productDefinitionCore, title: 'Data Structure', children: [
      { link: PageId.productDefinitionCore, title: 'Product' },
      { link: PageId.productDefinitionCommon, title: 'Product Common' },
      { link: PageId.reorderProductFields, title: 'Product Field Order' },
      { link: PageId.searchItemDefinition, title: 'Search Item' },
      { link: PageId.conditions, title: 'Matching Rules' },
      { link: PageId.lists, title: 'Data Sources' },
      { link: PageId.categories, title: 'Categories' },
      { link: PageId.editProductField, title: 'Edit Product Field', isNonMenu: true, showActivePageId: PageId.productDefinitionCore },
      { link: PageId.productsEditCommon, title: 'Edit Common Product Field', isNonMenu: true, showActivePageId: PageId.productDefinitionCommon },
      { link: PageId.editCategories, title: 'Edit Categories', isNonMenu: true, showActivePageId: PageId.categories },
      { link: PageId.editCondition, title: 'Edit Condition', isNonMenu: true, showActivePageId: PageId.conditions },
      { link: PageId.editSearchItemField, title: 'Edit Search Item Field', isNonMenu: true, showActivePageId: PageId.searchItemDefinition },
      { link: PageId.editList, title: 'Edit Data Source', isNonMenu: true, showActivePageId: PageId.lists }
    ]
  },
  {
    link: PageId.products, title: 'Products', children: [
      { link: PageId.products, title: 'Products' },
      { link: PageId.productsCommon, title: 'Product Common Values' },
      { link: PageId.editProduct, title: 'Edit Product', isNonMenu: true, showActivePageId: PageId.products }
    ]
  },
  { link: PageId.playground, title: 'Playground' },
  {
    link: PageId.profile, title: 'My Account', children: [
      { link: PageId.profile, title: 'Profile' },
      { link: PageId.subscription, title: 'Subscription' }
    ]
  },
  { link: PageId.playground, title: 'Playground' },
  {
    link: PageId.apiAccess, title: 'API Access',
    children: [
      { link: PageId.apiAccess, title: 'Endpoint', showActivePageId: PageId.apiAccess },
      { link: PageId.clients, title: 'Clients', showActivePageId: PageId.apiAccess },
      { link: PageId.editClient, title: 'Edit Client', isNonMenu: true, showActivePageId: PageId.apiAccess }
    ]
  },
];

export const getActiveMainPageId = (activePageId: PageId) : PageId => {
  const node = sitemap.find(x => isRouteMatch(activePageId, x.link));

  if (node) {
    return node.link;
  }

  if (!node) {
    // tree search for node
    for (const n of sitemap) {
      if (n.children) {
        const subject = n.children.find(x => isRouteMatch(activePageId, x.link));
        if (subject) {
          return n.link;
        }
      }
    }
  }

  return PageId.home;
}

// Return items for the menu
export const getMenuChildrenOrSiblingsOf = (page: PageId) : { node: Node, items?: Node[] } | undefined => {
  // const node = sitemap.find(x => x.link === page);

  const node = sitemap.find(x => isRouteMatch(page, x.link));

  // if it's a top level node...
  if (node) {
    return {
      node,
      items: node.children && node.children.filter(x => !x.isNonMenu)
    }
  }

  // if it's not top-level, we need to search
  if (!node) {
    // tree search for node
    for (const n of sitemap) {
      if (n.children) {
        const subject = n.children.find(x => isRouteMatch(x.link, page));
        if (subject) {
          return {
            node: subject,
            items: n.children.filter(x => !x.isNonMenu)
          }
        }
      }
    }
  }
}

export default sitemap;