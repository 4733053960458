import React from 'react';
import DumbDynamicForm from './dumb-dynamic-form';
import { validateSingleField } from './validator';
import { FormStructure } from './types';

type Indexable = {
  [key: string]: any
}

type Props<TRoot extends Indexable> = {
  structure: FormStructure,
  value: TRoot,
  onChange?: (structure: FormStructure, val: TRoot, categoryName: string, fieldName: string, fieldValue: any) => void
}

type State<TRoot extends Indexable> = {
  structure: FormStructure,
  value: TRoot,
  hasInitialValidationRun: boolean
}

export default class SmartDynamicForm<TRoot extends Indexable> extends React.Component<Props<TRoot>, State<TRoot>> {
  constructor(props: Props<TRoot>) {
    super(props);
    const state: State<TRoot> = {
      structure: props.structure,
      value: props.value,
      hasInitialValidationRun: false
    };
    this.state = state;

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // TODO: Consider putting this back
    // if (!this.state.hasInitialValidationRun) {
    //   const structure = validateAllFields(this.props.structure, this.state.value);      
    //   this.setState({
    //     structure,
    //     hasInitialValidationRun: true
    //   })
    // }
  }

  componentDidUpdate(prevProps: Props<TRoot>) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }

    if (prevProps.structure !== this.props.structure) {
      this.setState({ structure: this.props.structure });
    }
  }

  shouldComponentUpdate(nextProps : Props<TRoot>, nextState: State<TRoot>) {
    const hasStateChanged = (
      nextState.structure !== this.state.structure
      || nextState.value !== this.state.value
    );
    
    const havePropsChanged = (
      nextProps.structure !== this.props.structure
      || nextProps.value !== this.props.value
      || nextProps.onChange !== this.props.onChange
    );
    
    return hasStateChanged || havePropsChanged;
  }

  handleChange(categoryName: string, fieldName: string, fieldValue: any) {
    const { structure, value } = this.state;
    const newValue = { ...value };

    // @ts-ignore // typescript not working properly
    newValue[fieldName] = fieldValue;

    const newStructure = validateSingleField(structure, categoryName, fieldName, fieldValue);

    if (this.props.onChange) {
      this.props.onChange(newStructure, newValue, categoryName, fieldName, fieldValue);
    } else {
      this.setState({ structure: newStructure, value: newValue });
    }
  }

  render() {
    const { structure } = this.state;
    const { value } = this.state;

    return (
      <>
        <DumbDynamicForm
          structure={structure}
          value={value}
          onChange={this.handleChange}
        />
      </>
    )
  }
}