import React from 'react';
import createDebug from 'debug';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

const debug = createDebug('mk:smart-multiple-items');

type Disableable = {
  onClick: (event: any) => void;
  isDisabled?: boolean;
}

const AddButton = (props: ButtonProps & Disableable) => {
  let clickAction : any;
  if (!!props.isDisabled) {
    debug('AddButton.DISABLED');
    clickAction = () => true;
  } else {
    debug('AddButton.NOT_DISABLED');
    clickAction = props.onClick;
  }

  return (
    <Button
      disabled={props.isDisabled}
      size="small"
      type="primary"
      onClick={(x) => clickAction(x)}
      icon={<PlusOutlined />}
    >
      Add Item
    </Button>
  );
}

export default AddButton;
