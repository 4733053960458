import React from 'react';
import styled from 'styled-components';
import Cleave from "cleave.js/react";
import createDebug from 'debug';
import { FieldProps } from '../field-props';

const debug = createDebug('mk:dyn-form:fields:date');

const StyledCleave = styled(Cleave)`
  border: 1px solid #d9d9d9;
  padding: 4px 8px;
  width: 90%;

  &:focus {
    outline: none;
  }
`;

export default class DateField extends React.Component<FieldProps> {
  constructor(props: FieldProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value: string) {
    this.props.onChange(value);
  }

  shouldComponentUpdate(nextProps: FieldProps) {
    return nextProps.value !== this.props.value
      || nextProps.field !== this.props.field
      || nextProps.validationState !== this.props.validationState
      || nextProps.isDisabled !== this.props.isDisabled;
  }

  render() {
    debug(`Date - render ${this.props.field.name}`);
    const { isDisabled } = this.props;

    return (
      <>
        <StyledCleave
          placeholder="0.00"
          options={{
            date: true,
            datePattern: ['d', 'm', 'Y']
          }}
          value={this.props.value}
          onChange={e => this.handleChange(e.target.rawValue)}
          disabled={isDisabled}
        />
      </>
    )
  }
}