import React from 'react';
import Condition from '../../types/condition';
import ListingComponent from '../../support/create-listing-component';
import services from '../../services';
import Definition from '../../types/listing-component-definition';

const friendlyNames = {
  GTE: 'Greater Than or Equal',
  LTE: 'Less Than or Equal',
  EQ: 'EQ',
  ITEM_EXISTS_IN_ARRAY: 'Contains',
  ITEM_NOT_EXISTS_IN_ARRAY: "Doesn't contain",
  SOME_ITEMS_EXIST_IN_ARRAY: 'Contains some',
  NO_ITEMS_EXIST_IN_ARRAY: 'Contains none',
  ALL_ITEMS_EXIST_IN_ARRAY: 'Contains all',
  ARRAYS_ARE_IDENTICAL: 'Arrays identical',
  GEO_COORDS_WITHIN: 'Coords within',
  GEO_POSTCODE_WITHIN: 'Postcode within'
}

const def : Definition<Condition> = {
  singular: 'matching rule',
  columns: [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Product Field', 
      dataIndex: 'productField',
      key: 'productField',
      render: (val, condition) => {
        const field = condition.productField;
        return field
             ? field.displayName
             : '';
      }
    },
    {
      title: 'Search Item Field',
      dataIndex: 'searchItemField',
      key: 'searchItemField',
      render: (val, condition) => {
        const field = condition.searchItemField;
        return field
             ? field.displayName
             : '';
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (val: any, condition: Condition) => {
        return friendlyNames[condition.type];
      }
    },
    {
      title: 'Enabled',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (val: any) => {
        return val ? 'Yes' : 'No'
      }
    },
  ],
  editLink: `/conditions`
};

type Props = {
}

const ConditionsIndex : React.FC<Props> = () => (
  <>
    <h1>Matching Rules</h1>

    <p>These are the rules that your matching endpoint will use to return results. For example if you were building a car marketplace, you could set up a rule for mileage less
      than the value searched for.</p>

    <ListingComponent
      definition={def}
      getAll={services.conditionService.getAll}
      deleteOne={services.conditionService.delete}
      isLoading={false}
    />
  </>
);

export default ConditionsIndex;
