import React from 'react';
import { Subject } from 'rxjs';
import SaveButton from '../../components/buttons/save-button';
import { Button } from 'antd';
import logic, { State, Actions } from './edit-template/logic';
import PageId from '../../types/page-id';
import { CategoryGroup } from '../../types/category';
import ItemSaveOutcome from '../../types/item-save-outcome';
import EventCode from '../../events/event-code';
import notify from '../../events/notify';
import Dumb from './edit-template/dumb';
import ButtonsContainer from '../../components/layout/buttons-container';

type Props = {
  type: string,
  history: any
}

const map : any = {
  'product_field': 'Product Field',
  'search_item_field': 'Search Item Field',
}

class EditCategoryGroup extends React.Component<Props, State> {
  stateChanges: Subject<Partial<State>>;
  actions: (state: State) => Actions;
  subscription: any;
  constructor(props : Props) {
    super(props);
    this.stateChanges = new Subject();
    this.actions = logic.createActions(this.stateChanges);
    this.state = logic.getInitialState();
    this.mutateHandlerProxy = this.mutateHandlerProxy.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  mutateHandlerProxy(item: Partial<CategoryGroup>) {
    this.actions(this.state).mutate(item);
  }

  async componentDidMount() {
    this.subscription = this.stateChanges.subscribe(state => {
      this.setState(state as State);
    });

    const { type } = this.props;

    this.actions(this.state).edit(type);
  }

  componentWillMount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async save() {
    const saveResult = await this.actions(this.state).save();
    if (saveResult.outcome === ItemSaveOutcome.created || saveResult.outcome === ItemSaveOutcome.updated) {
      this.backToListing();
    } else {
      notify(EventCode.ITEM_SAVE_ERROR, saveResult);
    }
  }

  cancel() {
    this.backToListing();
  }

  backToListing() {
    const listingLink = PageId.categories;
    const { history } = this.props;
    history.push(`${listingLink}`);
  }

  render() {
    const { type } = this.props;
    const { isSaving, isLoading } = this.state;
    
    return (
      <>
        <h1>Edit {map[type]} Categories</h1>

        <Dumb
          state={this.state}
          mutateAction={this.mutateHandlerProxy}
        />

        <ButtonsContainer>
          <SaveButton
            onClick={() => this.save()}
            isSaving={isSaving}
            isLoading={isLoading}
          />

          <Button
            type="link"
            onClick={() => this.cancel()}>Cancel</Button>
          </ButtonsContainer>
      </>
    )
  }
};

export default EditCategoryGroup;
