import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  cursor: pointer;
  border-radius: 3px;
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;  
  font-size: 15px;
  background-color: #22C0C0;
  border: none;
  color: white;

  &:hover {
    background-color: #01a2a2;
    transition: background-color 0.3s linear;
  }
`

export default StyledButton;
