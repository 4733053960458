import _ from 'lodash';
import { Subject } from 'rxjs';
import createDebug from 'debug';
import ProductField from '../../../types/product-field';
import categoriesService from '../../../services/categories-service';
import listService from '../../../services/list-service';
import productFieldService from '../../../services/product-field-service';
import ItemSaveResult from '../../../types/item-save-result';
import { Schema } from '../../../types/schema';
import productFieldSchema from '../product-field-schema';
import FieldType from '../../../types/field-type';
import ProductFieldPurpose from '../../../types/product-field-purpose';
import { FormStructure } from '../../../components/dynamic-form/types';
import formStructure from './form-structure';
import { Category } from '../../../types/category';
import ItemSaveOutcome from '../../../types/item-save-outcome';
import { Error } from '../../../support/error';

// TODO TRY OUT "IMMER" Library

const debug = createDebug('mk:edit-product-field:logic');

const toUndefinedOrNumber = (val: any) : number | null => {
  debug('CHECKING VALUE', val);
  if (val === '') {
    debug('RETURNING NULL');
    return null;
  }

  const coercedValue = Number(val);
  if (Number.isNaN(coercedValue)) {
    return null;
  } else {
    return coercedValue;
  }
}

export type EditProductFieldState = {
  structure: FormStructure,
  item: Partial<ProductField>,
  schema: Schema,
  isValid: boolean,
  isLoading: boolean,
  isSaving: boolean,
  errors?: Error[]
}

export type Actions = {
  mutate: (structure: FormStructure, item: Partial<ProductField>, categoryName: string, fieldName: string, fieldValue: any) => Promise<void>,
  newItem: () => Promise<void>,
  edit: (id : number) => Promise<void>,
  save: () => Promise<ItemSaveResult<ProductField>>
}
const convertAllowedValuesToEnclosingType = (item: ProductField): void => {
  if (!Array.isArray(item.allowedValues)) {
    return;
  }

  if (item.type === FieldType.number) {
    for (let i = 0; i < item.allowedValues.length; i++) {
      const candidateValue = Number(item.allowedValues[i]);
      if (Number.isNaN(candidateValue)) {
        item.allowedValues.splice(i, 1);
      } else {
        item.allowedValues[i] = Number(item.allowedValues[i]);
      }
    }
  }
}

const loadCategories = async (structure: FormStructure) : Promise<FormStructure> => {
  const categoryGroup = await categoriesService.getByType('product_field');

  const basicCategory = { ...structure.categories['basic'] };
  basicCategory.fields = { ...basicCategory.fields };
  basicCategory.fields['category'] = { ...basicCategory.fields['category'] };
  basicCategory.fields['category'].values = categoryGroup.values.map((x: Category) => ({ value: x.name, label: x.displayName }));

  return {
    ...structure,
    categories: {
      ...structure.categories,
      basic: basicCategory
    }
  }
}

function camelize(str: string) {
  return _.camelCase(str);
}

const setIsValuesRestricted = (state: EditProductFieldState, item: ProductField) : EditProductFieldState => {
  const validation = { ...state.structure.categories['validation'] };
  const isSeparateListSpecified = { ...validation.fields['isSeparateListSpecified'] };
  const allowedValuesListId = { ...validation.fields['allowedValuesListId'] };
  const allowedValues = { ...validation.fields['allowedValues'] };

  const newState = {
    ...state,
    item
  }

  if (item.isValuesRestricted) {
    newState.item.minLength = '';
    newState.item.maxLength = '';
    newState.item.minimum = '';
    newState.item.maximum = '';
    newState.item.pattern = '';
    isSeparateListSpecified.isVisible = true;
    allowedValues.isVisible = true;
    newState.item.allowedValues = [];
    
  } else { // if it's been set to false
    newState.item.isSeparateListSpecified = null;
    newState.item.allowedValuesListId = null;
    isSeparateListSpecified.isVisible = false;
    allowedValuesListId.isVisible = false;
    allowedValues.isVisible = false;
  }

  validation.fields = {
    ...validation.fields,
    isSeparateListSpecified,
    allowedValues,
    allowedValuesListId
  }

  const newStructure = {
    ...state.structure,
    categories: {
      ...state.structure.categories,
      validation
    }
  }

  newState.structure = newStructure;

  return newState;
}

const setIsSeparateListSpecified = async (state: EditProductFieldState, item: ProductField) => {
  const validation = { ...state.structure.categories['validation'] };
  const allowedValuesListId = { ...validation.fields['allowedValuesListId'] };
  const allowedValues = { ...validation.fields['allowedValues'] };

  if (item.isSeparateListSpecified) {
    // ensure have lists loaded
    const lists = await listService.getAll();
    const mapped = lists.map(x => ({ value: x.id, label: x.displayName }));
    allowedValuesListId.values = mapped;
    allowedValuesListId.isLoading = false;
    allowedValuesListId.isVisible = true;
    allowedValues.isVisible = false;
  } else {
    allowedValuesListId.isVisible = false;
    allowedValues.isVisible = true;
    item.allowedValues = [];
  }

  validation.fields = {
    ...validation.fields,
    allowedValues,
    allowedValuesListId
  }

  const newState = {
    ...state,
    structure: {
      ...state.structure,
      categories: { ...state.structure.categories, validation }
    },
    item
  }

  return newState;
}

const setType = (state: EditProductFieldState, item: ProductField): EditProductFieldState => {
  const newState = { ...state };
  const validation = { ...state.structure.categories['validation'] };
  const basic = { ...state.structure.categories['basic'] };
  const isMultiple = { ...basic.fields['isMultiple'] };
  const isRequired = { ...validation.fields['isRequired'] };
  const isValuesRestrictedField = { ...validation.fields['isValuesRestricted'] };
  const minLength = { ...validation.fields['minLength'] };
  const maxLength = { ...validation.fields['maxLength'] };
  const minimum = { ...validation.fields['minimum'] };
  const maximum = { ...validation.fields['maximum'] };
  const pattern = { ...validation.fields['pattern'] };
  const allowedValues = { ...validation.fields['allowedValues'] };
  const allowedValuesListId = { ...validation.fields['allowedValuesListId'] };
  const isSeparateListSpecified = { ...validation.fields['isSeparateListSpecified'] };

  switch (item.type) {
    case FieldType.number: {
      minLength.isVisible = false;
      maxLength.isVisible = false;
      minimum.isVisible = true && !item.isValuesRestricted!;
      maximum.isVisible = true && !item.isValuesRestricted!;
      pattern.isVisible = false;
  
      newState.item.minLength = '';
      newState.item.maxLength = '';
      newState.item.pattern = '';
      break;
    }
    case FieldType.string: {
      minLength.isVisible = true && !item.isValuesRestricted;
      maxLength.isVisible = true && !item.isValuesRestricted;
      minimum.isVisible = false;
      maximum.isVisible = false;
      pattern.isVisible = true && !item.isValuesRestricted;
  
      newState.item.minimum = '';
      newState.item.maximum = '';
      break;
    }
    case FieldType.boolean: {
      minLength.isVisible = false;
      maxLength.isVisible = false;
      minimum.isVisible = false;
      maximum.isVisible = false;
      pattern.isVisible = false;
      isValuesRestrictedField.isVisible = false;
      isMultiple.isVisible = false;
      allowedValues.isVisible = false;
      allowedValuesListId.isVisible = false;
      isSeparateListSpecified.isVisible = false;
  
      newState.item.minLength = '';
      newState.item.maxLength = '';
      newState.item.minimum = '';
      newState.item.maximum = '';
      newState.item.pattern = '';
      newState.item.isMultiple = false;
      newState.item.isValuesRestricted = false;
      newState.item.allowedValues = [];
      break;
    }
  }

  newState.item = item;
  newState.structure = { ...state.structure };
  basic.fields = {
    ...basic.fields,
    isMultiple
  };

  validation.fields = {
    ...validation.fields,
    minLength,
    maxLength,
    minimum,
    maximum,
    pattern,
    isValuesRestricted: isValuesRestrictedField,
    isRequired,
    allowedValues,
    isSeparateListSpecified
  };

  newState.structure.categories = { ...state.structure.categories, basic, validation };

  return newState;
}

type ProductFieldKey = keyof ProductField;

const handler = async (state: EditProductFieldState, item: ProductField, categoryName: string, fieldName: ProductFieldKey, fieldValue: any): Promise<EditProductFieldState> => {
  debug(`handling... ${categoryName}, ${fieldName} = ${fieldValue}`);
  
  switch (fieldName) {
    case 'displayName': {
      const newItem = {
        ...item,
        displayName: fieldValue,
        name: camelize(fieldValue)
      };
      return {
        ...state,
        item: newItem
      }
    }
    case 'isValuesRestricted': {
      return setIsValuesRestricted(state, item);
    }
    case 'isSeparateListSpecified': {
      return setIsSeparateListSpecified(state, item);
    }
    case 'type': {
      return setType(state, item);
    }
    default: {
      debug('default handler', item);
      const newState = { ...state, item: { ...item } };
      return newState;
    }
  }
}

const createActions = (stateChanges: Subject<Partial<EditProductFieldState>>) => function handle(state: EditProductFieldState) : Actions {
  return {
    newItem: async () => {
      debug('NEW ITEM');
      stateChanges.next({ isLoading: true });

      const newStructure = await loadCategories(state.structure);

      let newState : EditProductFieldState = {
        ...state,
        structure: newStructure,
        isValid: false,
        isLoading: false
      }

      newState = setType(newState, state.item as ProductField);
      newState = setIsValuesRestricted(newState, state.item as ProductField);
      newState = await setIsSeparateListSpecified(newState, state.item as ProductField);

      stateChanges.next(newState)
    },
    edit: async (id: number) => {
      debug('EDIT', id);
      stateChanges.next({ isLoading: true });
      const productField = await productFieldService.get(id);
      const newStructure = await loadCategories(state.structure);

      let newState : EditProductFieldState = {
        ...state,
        structure: newStructure,
        isLoading: false
      };

      newState = setType(newState, productField);
      newState = setIsValuesRestricted(newState, productField);
      newState = await setIsSeparateListSpecified(newState, productField);

      newState.item = productField;

      stateChanges.next(newState);
    },
    save: async () => {
      stateChanges.next({ isLoading: true });

      const itemToSave = {
        ...state.item,
        minimum: toUndefinedOrNumber(state.item.minimum),
        maximum: toUndefinedOrNumber(state.item.maximum),
        minLength: toUndefinedOrNumber(state.item.minLength),
        maxLength: toUndefinedOrNumber(state.item.maxLength)
      };

      convertAllowedValuesToEnclosingType(itemToSave as ProductField);

      debug('itemToSave', itemToSave);

      const itemSaveResult = await productFieldService.create(itemToSave as ProductField);

      if (itemSaveResult.outcome === ItemSaveOutcome.businessValidationError) {
        stateChanges.next({ isLoading: false, errors: itemSaveResult.validationErrors });
      } else if (itemSaveResult.outcome === ItemSaveOutcome.created || itemSaveResult.outcome === ItemSaveOutcome.updated) {
        stateChanges.next({ isLoading: false });
      } else {
        stateChanges.next({ isLoading: false, errors: [{ description: 'Unknown error',  }] });
      }

      return itemSaveResult;
    },
    mutate: async (structure: FormStructure, item: Partial<ProductField>, categoryName: string, fieldName: string, fieldValue: any) => {
      debug('MUTATE', structure, item);

      const newState = await handler(state, item as ProductField, categoryName, fieldName as ProductFieldKey, fieldValue);

      debug('NEW STATE', newState);
    
      stateChanges.next(newState);
      return;
    }
  }
}

const logic = (purpose: ProductFieldPurpose) => ({
  getInitialState: () => {
    const state: EditProductFieldState = {
      structure: { ...formStructure },
      item: {
        displayName: '',
        name: '',
        type: FieldType.number,
        isValuesRestricted: false,
        allowedValues: [],
        isRequired: false,
        isMultiple: false,
        isSummary: false,
        minimum: '',
        maximum: '',
        minLength: '',
        maxLength: '',
        purpose
      },
      schema: productFieldSchema,
      isValid: true,
      isLoading: true,
      isSaving: false
    }

    // setOverrides(state);
  
    return state;
  },
  createActions
})

export default logic;
