import React from "react";
import styled from 'styled-components';
import authClient from './auth-client';

type Props = {};
type State = {
  isLoading: boolean
}

const StyledLinkButton = styled.button`
  font-size: 1em;
  color: white;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;

  &:focus {
    outline:0;
    outline: none;
  }
`;

export default class LoginButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true
    }

    this.redirectToLoginPage = this.redirectToLoginPage.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  async redirectToLoginPage() {
    await authClient.login();
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>
    }
    return (
      <StyledLinkButton
        onClick={() => this.redirectToLoginPage()}>Sign in
      </StyledLinkButton>
    )
  }
}
