import { Subject } from 'rxjs';
import createDebug from 'debug';
import services from "../../../services";
import ItemSaveResult from '../../../types/item-save-result';
import { CategoryGroup } from '../../../types/category';

const debug = createDebug('mk:edit-list:logic');

export type State = {
  item: Partial<CategoryGroup>,
  isValid: boolean,
  isLoading: boolean,
  isSaving: boolean
}

export type Actions = {
  mutate: (item: Partial<CategoryGroup>) => Promise<void>,
  edit: (type : string) => Promise<void>,
  save: () => Promise<ItemSaveResult<CategoryGroup>>
}

// const convertAllowedValuesToEnclosingType = (item: Field): void => {
//   if (!Array.isArray(item.allowedValues)) {
//     throw new Error('DEFECT: item.allowedValues was not an array');
//   }

//   if (item.type === FieldType.number) {
//     for (let i = 0; i < item.allowedValues.length; i++) {
//       const candidateValue = Number(item.allowedValues[i]);
//       if (Number.isNaN(candidateValue)) {
//         item.allowedValues.splice(i, 1);
//       } else {
//         item.allowedValues[i] = Number(item.allowedValues[i]);
//       }
//     }
//   }
// }

const createActions = (stateChanges: Subject<Partial<State>>) => function handle(state: State) : Actions {
  return {
    edit: async (type: string) => {
      stateChanges.next({ isLoading: true });
      const categoryGroup = await services.categoriesService.getByType(type);

      stateChanges.next({
        item: categoryGroup,
        isLoading: false
      });
    },
    save: async () => {
      stateChanges.next({ isLoading: true });

      const itemToSave = {
        ...state.item
      };

      // convertAllowedValuesToEnclosingType(itemToSave as SearchItemField);

      debug('itemToSave', itemToSave);

      const itemSaveResult = await services.categoriesService.create(itemToSave as CategoryGroup);

      stateChanges.next({ isLoading: false }); // TODO: This would send back some kind of save success / validation error
      return itemSaveResult;
    },
    mutate: async (item: Partial<CategoryGroup>) => {
      debug('MUTATE', item);

      const newState: State = {
        ...state
      };

      const newItem = {
        ...state.item,
        ...item
      };

      const stateChange = {
        ...newState,
        item: {
          ...newItem
        }
      }

      debug('NEW STATE', stateChange);
    
      stateChanges.next(stateChange);
      return;
    }
  }
}

const logic = {
  getInitialState: () => {
    const state : State = {
      item: {
        type: '',
        values: []
      },
      isValid: true,
      isLoading: true,
      isSaving: false
    }
  
    return state;
  },
  createActions
};

export default logic;
