const BIGDEBUG = (name: string, value: any) => {
  console.log();
  console.log();
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('DEBUG DEBUG DEBUG DEBUG DEBUG DEBUG DEBUG DEBUG DEBUG')
  console.log(`-----> ${name}`);
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.dir(value);
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log('* * * * * * * * * * * * * * * * * * * * * * * * * * *');
  console.log();
  console.log();
}

export default BIGDEBUG;