import urls from './urls';
import backoff from '../util/retry';
import Account from '../types/account';
import addToken from '../support/add-token';
import { OnboardingResult } from '../types/onboarding-info';
import { OnboardingDetails } from '../pages/account/types';

const service = {
  get: async () : Promise<Account> => {
    let requestInfo : any = {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    };

    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(`${urls.api()}/accounts`, requestInfo));
    const data = await response.json();
  
    return data;
  },
  onboardingInfo: async () : Promise<OnboardingResult> => {
    let requestInfo : any = {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    };

    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(`${urls.api()}/accounts/onboarding`, requestInfo));
    const data = await response.json();
  
    return data;
  },
  onboard: async (onboardingDetails: OnboardingDetails) : Promise<OnboardingResult> => {
    let requestInfo : any = {
      method: 'POST',
      body: JSON.stringify(onboardingDetails),
      headers: {
        'content-type': 'application/json'
      }
    };

    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(`${urls.api()}/accounts/onboard`, requestInfo));
    const data = await response.json();
  
    return data;
  }
}

export default service;