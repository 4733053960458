import produce from 'immer';
import { FormStructure, ValidationState } from '../types';
import { Schema } from '../../../types/schema';
import rules from './rules';

const debug = require('debug')('mk:dyn-form:validator');

const validateValueAgainstSchema = (schema: Schema, value: string) => {
  const validationMessages = [];
  for (const key of Object.keys(schema)) {
    const rule = rules[(key as keyof Schema)];
    if (!rule) {
      // console.warn(`[Dynamic form validator] WARNING: Unrecognised schema element: ${key}`);
      continue;
    }

    const result = rule(schema, value);

    if (!result.isValid) {
      validationMessages.push(result.message);
    }
  }

  return validationMessages;
}

const isMultipleValueType = (structure: FormStructure, categoryName: string, fieldName: string) => {
  return structure.categories[categoryName].fields[fieldName].isMultiple;
}

export const validateSingleField = (structure: FormStructure, categoryName: string, fieldName: string, value: any) => {
  const { categories } = structure;

  const category = categories[categoryName];
  const field = category.fields[fieldName];
  const schema = field.schema;

  let validationMessages: string[] = [];

  const validationState : ValidationState = {
    isValid: true,
    isDirty: false,
    messages: []
  }

  if (isMultipleValueType(structure, categoryName, fieldName)) {
    debug('isMultipleValueType');
    validationState.items = [];
    const values = value || [];
    let allItemsValid = true;
    for (let i = 0; i < values.length; i++) {
      debug(`checking value: ${values[i]}`);
      if (schema.items) {
        debug('INDIVIDUAL VALUE', values[i]);
        const itemMessages = validateValueAgainstSchema(schema.items, values[i]);
        if (itemMessages.length > 0) {
          debug(`value ${values[i]} is not valid`);
          allItemsValid = false;
          validationState.items[i] = {
            isValid: false,
            isDirty: true,
            messages: itemMessages
          }
        } else {
          debug(`value ${values[i]} is valid`);
          validationState.items[i] = {
            isValid: true,
            isDirty: true,
            messages: []
          }
        }
      }
    }

    validationState.isValid = allItemsValid;
  } else {
    validationMessages = validateValueAgainstSchema(schema, value);
    debug('validationMessages', validationMessages);
    if (validationMessages.length > 0) {
      debug(`value ${value} is not valid`);
      validationState.isValid = false;
      validationState.messages = validationMessages;
    }
  }

  debug('validationState', validationState);

  if (!validationState.isValid) {
    // debug('is not valid, setting new validationState');
    // const newField = { ...field };
    // newField.validationState = { ...validationState };

    // debug('newField.validationState', newField.validationState);

    // const newStructure = { ...structure };
    // newStructure.categories = { ...categories };
    // const category = { ...newStructure.categories[categoryName] };
    // category.fields = { ...newStructure.categories[categoryName].fields };
    // category.fields[fieldName] = newField;

    // newStructure.categories[categoryName] = category;

    // newStructure.validationSummary.map = newStructure.validationSummary.map || {};
    // newStructure.validationSummary.map[fieldName] = newField.validationState;

    const newStructure = produce(structure, draft => {
      if (!draft.validationSummary.map) {
        draft.validationSummary.map = {};
      }

      draft.categories[categoryName].fields[fieldName].validationState = validationState;
      draft.validationSummary.map![fieldName] = validationState;
      draft.validationSummary.isValid = false;
      return draft;
    })

    // setGlobalIsValid(newStructure);

    debug('newStructure', newStructure);

    return newStructure;
  } else {
    const validationState = structure.categories[categoryName].fields[fieldName].validationState || { isValid: true };
    const { isValid } = validationState;

    const currentlyValid = isValid;
    if (!currentlyValid) {
      // const newField = { ...field };
      // newField.validationState = {
      //   isValid: true,
      //   isDirty: true,
      //   messages: []
      // };

      const newStructure = produce(structure, draft => {
        draft.categories[categoryName].fields[fieldName].validationState = {
          isValid: true,
          isDirty: true,
          messages: []
        };

        if (draft.validationSummary.map && draft.validationSummary.map[fieldName]) {
          delete draft.validationSummary.map[fieldName];
        }

        draft.validationSummary.isValid = draft.validationSummary.map && Object.keys(draft.validationSummary.map).length === 0;

        return draft;
      });

      // const newStructure = { ...structure };
      // newStructure.categories = { ...categories };
      // newStructure.categories[categoryName].fields = { ...newStructure.categories[categoryName].fields };
      // newStructure.categories[categoryName].fields[fieldName] = newField;

      // if (newStructure.validationSummary.map && newStructure.validationSummary.map[fieldName]) {
      //   delete newStructure.validationSummary.map[fieldName];
      // }

      // setGlobalIsValid(newStructure);

      return newStructure;
    }

    return structure;
  }
}

const setGlobalIsValid = (structure: FormStructure) => {
  const keys = Object.keys(structure.validationSummary.map || {});
  structure.validationSummary.isValid = keys.length === 0;
}

export const validateAllFields = (structure: FormStructure, value: any) => {
  // loop through categories, loop through fields.
  // validate each field
  let newStructure = { ...structure };
  const categoryNames = Object.keys(structure.categories);

  for (const categoryName of categoryNames) {
    const fields = structure.categories[categoryName].fields;
    const fieldNames = Object.keys(fields);

    for (const fieldName of fieldNames) {
      newStructure = validateSingleField(newStructure, categoryName, fieldName, value[fieldName]);
    }
  }

  return newStructure;
}