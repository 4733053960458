import React from 'react';
import ListingComponent from '../../support/create-listing-component';
import services from '../../services';
import { Link } from 'react-router-dom';
import searchItemFieldService from '../../services/search-item-field-service';
import typeFriendlyNames from '../../support/type-friendly-names';
import SearchItemField from '../../types/search-item-field';

const def = {
  singular: 'search item field',
  columns: [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (val : any, item: any) => (
        <Link to={`${def.editLink}/${item.id}`}>
          {val}
        </Link>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (val: any, record : SearchItemField) => {
        return typeFriendlyNames[record.type] || record.type;
      }
    },
  ],
  editLink: `/search-item-fields`
}

type Props = {
};

type State = {
  getData: () => Promise<SearchItemField[]>;
}

class SearchItemFieldsIndex extends React.Component<Props, State> {
  render() {
    return (
      <>
        <h1>Search Item Fields</h1>

        <p>This is the structure of the object that the matching API endpoint is called with</p>

        <ListingComponent
          definition={def}
          getAll={searchItemFieldService.getAll}
          deleteOne={services.searchItemFieldService.delete}
          isLoading={true}
        />
      </>
    );
  }  
}

export default SearchItemFieldsIndex;
