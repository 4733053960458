import urls from './urls';
import { Schema } from '../types/schema';
import backoff from "../util/retry";
import ProductFieldPurpose from '../types/product-field-purpose';
import addToken from '../support/add-token';

export default {
  product: async (purpose: ProductFieldPurpose) : Promise<Schema> => {
    let requestInfo : any = {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    };

    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(`${urls.api()}/schema/product/${purpose}`, requestInfo));
    const data = await response.json();

    return data;
  },
  searchItem: async () : Promise<Schema> => {
    let requestInfo : any = {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    };

    // requestInfo = addAuth(requestInfo);
    requestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(`${urls.api()}/schema/search-item`, requestInfo));
    const data = await response.json();

    return data;
  },
}