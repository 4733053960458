import services from '../../services';
import { FormStructure, FormField } from '../../components/dynamic-form/types';
import SearchItemField from '../../types/search-item-field';

const getFieldType = (pf: SearchItemField): string => {
  if (pf.isValuesRestricted) {
    return 'select';
  }

  switch (pf.type) {
    case 'string':
      return 'text';
    case 'number':
      return 'number';
    case 'integer':
        return 'number'; // TODO: This could be a dedicated "Integer" field that doesn't allow decimals
    case 'boolean':
        return 'check';
  }

  throw new Error('DEFECT: No suitable field type could be found');
}

const createFormDefinition = async () : Promise<FormStructure> => {
  const form : FormStructure = {
    validationSummary: {
    },
    categories: {}
  }

  const productFields = await services.searchItemFieldService.getAll();

  for (const pf of productFields) {
    const categoryName = pf.category || 'General';

    if (!form.categories[categoryName]) {
      form.categories[categoryName] = {
        name: categoryName,
        title: categoryName,
        isDisabled: false,
        isVisible: true,
        validationState: {
          isValid: true,
          isDirty: false,
          messages: []
        },
        fields: {}
      }
    }

    const field : FormField = {
      name: pf.name,
      title: pf.displayName,
      type: getFieldType(pf),
      isMultiple: pf.isMultiple,
      validationState: {
        isDirty: false,
        isValid: true,
        messages: []
      },
      schema: { type: pf.type }
    }

    if (pf.isValuesRestricted) {
      if (pf.isSeparateListSpecified && pf.allowedValuesListId) {
        field.isLoading = true;
        const list = await services.listService.get(pf.allowedValuesListId);
        field.values = list.values.map(x => ({ value: x.name, label: x.displayName }));
        field.isLoading = false;        
      } else {
        // @ts-ignore
        field.values = pf.allowedValues.map((x: any) => ({ value: x, label: x }));
      }
    }

    if (pf.minimum) {
      // field.schema.minimum = Number(pf.minimum);
      // TODO...
    }

    form.categories[categoryName].fields[pf.name] = field;
  }

  return form;
}

export default createFormDefinition;