import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import SaveButton from '../../components/buttons/save-button';
import CancelButton from '../../components/buttons/cancel-button';
import Errors from '../errors';
import { EditItemState, EditorActions } from './logic/reducer';
import ButtonsContainer from '../../components/layout/buttons-container';

const debug = require('debug')('mk:EditWrapper');

export type TemplateProps<TEntity, TState, TActions extends EditorActions<TEntity>> = {
  state: TState,
  actions: TActions
}

export type EditWrapperProps<TEntity, TState, TActions extends EditorActions<TEntity>> = {
  titleText: string,
  itemTitle: string,
  helpText?: string | React.ReactNode,
  listingLink: string,
  id: string,
  history: any,
  createTemplate: () => React.ComponentType<TemplateProps<TEntity, TState, TActions>>,
  actions: EditorActions<TEntity>,
  state: TState
}

class EditWrapper<TEntity, TState extends EditItemState<TEntity>, TActions extends EditorActions<TEntity>> extends React.Component<EditWrapperProps<TEntity, TState, TActions>, TState> {
  async componentDidMount() {
    const { id, actions } = this.props;
    actions.edit(id);
  }

  componentDidUpdate(prevProps: EditWrapperProps<TEntity, TState, TActions>, prevState: TState) {
    if (this.props.state.isFinished) {
      const { history, listingLink } = this.props;
      history.push(`${listingLink}`);
    }
  }

  render() {
    const { createTemplate, id, itemTitle, actions, state, helpText } = this.props;
    const { isSaving, isLoading } = state;

    const titleText = id === '-1'
                    ? `Add ${itemTitle}`
                    : `Edit ${itemTitle}`;
    
    const Template = createTemplate();
    
    return (
      <>
        <h1>{titleText}</h1>

        {helpText && <p>{helpText}</p>}

        <Errors
          errors={state.errors}
        />

        <Template
          state={state}
          actions={actions as TActions} // not sure why I need this type hint, but I do
        />

        <ButtonsContainer>
          <SaveButton
            onClick={() => actions.save(state.item as TEntity)}
            isSaving={isSaving}
            isLoading={isLoading}
          />
          <CancelButton
            onClick={() => actions.cancel()} />
        </ButtonsContainer>
      </>
    )
  }
};

export default EditWrapper;
