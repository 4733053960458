import { FormStructure } from "../../../components/dynamic-form/types";
import schema from '../supplier-schema';

const form : FormStructure = {
  validationSummary: {
  },
  categories: {
    basic: {
      name: 'basic',
      title: 'Details',
      isDisabled: false,
      isVisible: true,
      fields: {
        displayName: {
          name: 'displayName',
          title: 'Display Name',
          description: "The display name is the name of the supplier",
          type: 'text',
          isMultiple: false,
          isRequired: true,
          schema: schema.properties['displayName']
        },
        logo: {
          name: 'logo',
          title: 'Logo',
          description: "The logo for the supplier",
          type: 'text',
          isMultiple: false,
          isRequired: true,
          schema: schema.properties['logo']
        }
      }
    }
  }
}

export default form;
