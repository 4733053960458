import fieldGenericSchema from '../../support/fields-common-schema';
import ProductFieldPurpose from '../../types/product-field-purpose';

const schema = {
  type: 'object',
  properties: {
    ...fieldGenericSchema.properties,
    isSummary: { type: 'boolean', title: 'Summary' },
    purpose: { title: 'Purpose', type: 'string', enum: [ProductFieldPurpose.common, ProductFieldPurpose.core, ProductFieldPurpose.inventory] }
  },
  required: [...fieldGenericSchema.required],
  additionalProperties: false
};

export default schema;
