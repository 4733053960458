import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PageId from '../../types/page-id';

const SignOut = (props: any) => (
  <>
    <h1>Signed out</h1>

    <p>You are signed out</p>

    <p><Link to={PageId.login}>Log back in</Link></p>
  </>
)

export default withRouter(props => <SignOut {...props} />);
