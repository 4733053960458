import React, { useEffect, useState } from 'react';
import clientsService from '../../services/clients-service';
import ClientsIndexDumb from './clients/clients-dumb';
import ApiEndpointDetails from './endpoint/api-endpoint-details';

// TODO: Use Faker on the server to generate an example

type Props = {
}

const ApiIndex: React.FC<Props> = () => {
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const run = async () => {
      // setIsLoading(false);
      // setSchema(JSON.stringify(schema, null, 2));
    }

    run();
  }, []);

  // if (isLoading) {
  //   return <>
  //     <h1>API</h1>
  //     <Skeleton />`
  //   </>
  // }

  return (
    <>
      <h1>API</h1>

      <ClientsIndexDumb
        getAll={clientsService.getAll}
        deleteOne={clientsService.delete}
      />
    </>
  )
}

export default ApiIndex;
