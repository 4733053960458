import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData } from '@stripe/stripe-js';
import CardSection from './card-section';
import services from '../../../../services';
import { Button } from 'antd';

const debug = require('debug')('mk:checkout-form');

const stripePaymentMethodHandler = async (paymentMethod : any) => {
  // Otherwise send paymentMethod.id to your server
  debug(paymentMethod);
  const customer = services.subscriptionService.create(paymentMethod.id);

  debug('customer', customer);
}

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event : any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const el = elements.getElement(CardElement);

    if (!el) {
      throw new Error('DEFECT: Element was null');
    }

    const data : CreatePaymentMethodCardData = {
      type: 'card', 
      card: el,
      billing_details: {
        email: 'g-subscriber-01@garethdown.com',
      },
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod(data);

    if (error) {
      console.error(error);
      throw new Error('ERROR WHEN CREATING PAYMENT METHOD ON STRIPE ' + error);
    }

    stripePaymentMethodHandler(paymentMethod);
  };

  return (
    <>
      <h1>Card Details</h1>
      <form onSubmit={handleSubmit}>
        <CardSection />
        
        <Button
          size="large"
          type="primary"
          htmlType="submit">
          Subscribe
        </Button>
          
      </form>
    </>
  );
}