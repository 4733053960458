import React from 'react';
import { withRouter } from 'react-router-dom';
import PageId from '../types/page-id';
import { getAuthClient } from '../auth/auth0';
import accountService from '../services/account-service';

const debug = require('debug')('mk:auth0-callback');

type State = {
  isError: boolean,
  errorMessage: string | null
}

class Callback extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isError: false,
      errorMessage: ''
    }
  }
  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const query = window.location.search;

    if (query.includes("error=")) {
      this.setState({
        isError: true,
        errorMessage: urlParams.get('error_description')
      })
    }

    // TODO: use urlParams.has('code')
    if (query.includes("code=") && query.includes("state=")) {

      debug('Getting client...');
      const client = await getAuthClient();

      debug('Processing login state...');
      try {
        await client.handleRedirectCallback();
      } catch (e) {
        console.log(e);
      }

      const onboardingInfo = await accountService.onboardingInfo();

      if (onboardingInfo.isOnboarded) {
        debug('redirecting...');
        window.location.replace(PageId.home);
      } else {
        debug('user has not finished onboarding...');
        window.location.replace(PageId.onboard);
      }
    }
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          <h1>Error</h1>

          <p>{this.state.errorMessage}</p>
        </div>
      )
    }
    return <div>Logging in...</div>
  }
}

export default withRouter(props => <Callback {...props} />);
