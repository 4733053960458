import fieldGenericSchema from '../../support/fields-common-schema';

const schema = {
  type: 'object',
  properties: {
    ...fieldGenericSchema.properties
  },
  required: [...fieldGenericSchema.required],
  additionalProperties: false
};

export default schema;
