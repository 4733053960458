import authClient from '../auth/auth-client';

const addToken = async (requestInfo: any) => {
  const accessToken : string = await authClient.getTokenForMarketplaceAdmin();

  const headers = {
    ...requestInfo.headers,
    authorization: `Bearer ${accessToken}`
  }

  const newInfo = {
    ...requestInfo,
    headers
  }

  return newInfo;
}

export default addToken;
