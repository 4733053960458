import React from 'react';
import styled from 'styled-components';
import {
  BrowserRouter,
  Switch,
  Route,
  useParams,
  useHistory
} from 'react-router-dom';
// import { Layout } from 'antd';
import '../main.less';
import Home from '../pages/index';
import Suppliers from '../pages/suppliers';
import EditSupplier from '../pages/suppliers/edit';
import SupplierUsers from '../pages/supplier-users';
import ProductDefinition from '../pages/product-fields';
import EditProductField from '../pages/product-fields/edit-v2';
import SearchItemFields from '../pages/search-item-fields/index';
import EditSearchItemField from '../pages/search-item-fields/edit';
import Lists from '../pages/lists/index';
import EditList from '../pages/lists/edit';
import Categories from '../pages/categories/index';
import EditCategories from '../pages/categories/edit';
import Conditions from '../pages/conditions';
import EditCondition from '../pages/conditions/edit';
import Products from '../pages/products';
import EditProduct from '../pages/products/edit';
import PageId from '../types/page-id';
import Playground from '../pages/playground/main';
import Auth0Callback from '../pages/auth0-callback';
import SignOut from '../pages/account/signout';
import Login from '../pages/login';
import Menu from '../layout/menu';
import SideMenu from '../layout/side-menu';
import Profile from '../pages/account/user-details';
import Subscription from '../pages/account/subscription';
import ProductsCommon from '../pages/product-common-values/index';
import ProductsEditCommon from '../pages/product-common-values/edit';
import LocalAdminMenu from '../layout/local-admin-menu';
import ProductFieldPurpose from '../types/product-field-purpose';
import ReorderProductFields from '../pages/product-fields/reordering/reorder-product-fields';
import ApiAccess from '../pages/api-access/endpoint/api-endpoint-details';
import Clients from '../pages/api-access';
import EditClient from '../pages/api-access/clients/edit/edit';
import Onboard from '../pages/account/onboard/onboard';
import UserStatusGuard from './user-status-guard';
import authClient from '../auth/auth-client';
import AccountMenu from '../layout/account-menu';
import { getAuthClient } from '../auth/auth0';
import { isRouteMatch } from '../layout/sitemap';
import { User } from '@auth0/auth0-spa-js';
import logo from '../layout/mk-logo.png';

const debug = require('debug')('mk:main');

// const { Header, Content, Footer } = Layout;

const makeEditor = (Child: any) => () => {
  const { id } = useParams() as { id: string };
  const history = useHistory();

  return (
    <Child id={id} history={history} />
  );
}

const makeProductFieldEditor = (Child: any) => () => {
  const { purpose, id } = useParams() as { purpose: string, id: string };
  const history = useHistory();

  return (
    <Child id={id} purpose={purpose} history={history} />
  );
}

const makeCategoriesEditor = (Child: any) => () => {
  const { type } = useParams() as { type: string };
  const history = useHistory();

  return (
    <Child type={type} history={history} />
  );
}

const EditConditionHOC = makeEditor(EditCondition);
const EditProductHOC = makeEditor(EditProduct);
const EditSearchItemFieldHOC = makeEditor(EditSearchItemField);
const EditProductFieldHOC = makeProductFieldEditor(EditProductField);
const EditListHOC = makeEditor(EditList);
const EditCategoriesHOC = makeCategoriesEditor(EditCategories);
const EditSupplierHOC = makeEditor(EditSupplier);
const EditClientHOC = makeEditor(EditClient);

const SiteLayoutContent = styled.div`
  background-color: #fff;
  padding: 24px;
  min-height: 280px;
`;

const ContentItem = () => (
  <Switch>
    <Route path={PageId.login}>
      <Login />
    </Route>
    <Route path={PageId.home}>
      <Home />
    </Route>
    <Route path={PageId.suppliers}>
      <Suppliers />
    </Route>
    <Route path={PageId.editSupplier}>
      <EditSupplierHOC />
    </Route>
    <Route path={PageId.supplierUsers}>
      <SupplierUsers />
    </Route>
    <Route path={PageId.productDefinitionCore} exact>
      <ProductDefinition purpose={ProductFieldPurpose.core} />
    </Route>
    <Route path={PageId.productDefinitionCommon} exact>
      <ProductDefinition purpose={ProductFieldPurpose.common} />
    </Route>
    <Route path={PageId.editProductField}>
      <EditProductFieldHOC />
    </Route>
    <Route path={PageId.reorderProductFields} exact>
      <ReorderProductFields />
    </Route>
    <Route path={PageId.searchItemDefinition}>
      <SearchItemFields />
    </Route>
    <Route path={PageId.editSearchItemField}>
      <EditSearchItemFieldHOC />
    </Route>
    <Route path={PageId.lists} exact>
      <Lists />
    </Route>
    <Route path={PageId.editList} exact>
      <EditListHOC />
    </Route>
    <Route path={PageId.categories} exact>
      <Categories />
    </Route>
    <Route path={PageId.editCategories} exact>
      <EditCategoriesHOC />
    </Route>
    <Route path={PageId.conditions}>
      <Conditions />
    </Route>
    <Route path={PageId.editCondition}>
      <EditConditionHOC />
    </Route>
    <Route path={PageId.products}>
      <Products />
    </Route>
    <Route path={PageId.productsCommon}>
      <ProductsCommon />
    </Route>
    <Route path={PageId.productsEditCommon}>
      <ProductsEditCommon />
    </Route>
    <Route path={PageId.editProduct}>
      <EditProductHOC />
    </Route>
    <Route path={PageId.playground}>
      <Playground />
    </Route>
    <Route path={PageId.apiAccess}>
      <ApiAccess />
    </Route>
    <Route path={PageId.clients}>
      <Clients />
    </Route>
    <Route path={PageId.editClient}>
      <EditClientHOC />
    </Route>
    <Route path={PageId.callback}>
      <Auth0Callback />
    </Route>
    <Route path={PageId.signOut}>
      <SignOut />
    </Route>
    <Route path={PageId.profile}>
      <Profile />
    </Route>
    <Route path={PageId.subscription}>
      <Subscription />
    </Route>
    <Route path={PageId.onboard}>
      <Onboard />
    </Route>
  </Switch>
)

const LayoutOuter = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const LayoutHeader = styled.div`
  display: flex;
  background-color: #08090B;
  color: white;
  min-height: 50px;
  /* border: 1px dashed red; */
  /* width: auto; */
`;

const LayoutLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
`;

const LayoutMain = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #eee;
  flex: 1;
  justify-content: flex-start;
  width: auto;
`;

const LayoutSideMenu = styled.div`
  width: 220px;
  background: #0F0F14;
  color: white;
`;

const LayoutContent = styled.div`
  padding: 12px;
  display: flex;
  align-items: stretch;
  /* border: 1px dashed green; */
  flex: 1;
`;

const LayoutMenuSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border: 1px dashed blue;
  background: grey; */
  justify-content: space-between;
`;

const LayoutAccountMenuContainer = styled.div`
  /* border: 1px dashed green; */
`;

const LayoutApplicationMenuContainer = styled.div`
  /* border: 1px dashed purple; */
`;

const ContentWrapper = styled.div`
  box-shadow: 1px 1px 5px 1px #ccc;
  background: white;
  padding: 12px;
  flex: 1;
`;

// MOBILE FIRST

type State = {
  isAuthenticated: boolean,
  user?: User
}

export default class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isAuthenticated: false,
      user: { }
    }
  }
  async componentDidMount() {
    const client = await getAuthClient();
    const isAuthenticated = await client.isAuthenticated();
    const user = await client.getUser();

    this.setState({
      isAuthenticated,
      user
    })

    debug('authClient.isAuthenticated', authClient.isAuthenticated());
    if (!await authClient.isAuthenticated()) {
      await authClient.login();
    }
  }

  render() {
    return (
      <BrowserRouter>
        <LayoutOuter>
          <LayoutHeader>
            <LayoutLogo>
              <img src={logo} style={{ height: '35px' }} />
            </LayoutLogo>
            <LayoutMenuSection>
              <LayoutApplicationMenuContainer>
                <Menu />
              </LayoutApplicationMenuContainer>
              <LayoutAccountMenuContainer>
                <AccountMenu
                  user={this.state.user}
                  isAuthenticated={this.state.isAuthenticated}
                  />
              </LayoutAccountMenuContainer>
            </LayoutMenuSection>
          </LayoutHeader>
          <LayoutMain>
            <LayoutSideMenu>
              <SideMenu />
            </LayoutSideMenu>
            <LayoutContent>
              <UserStatusGuard />
              <ContentWrapper>
                <ContentItem />
              </ContentWrapper>
            </LayoutContent>
          </LayoutMain>
        </LayoutOuter>
      </BrowserRouter>
    )
  }
}