import React from 'react';
import _ from 'lodash';
import { message } from 'antd';
import DumbReorderProductFields from './dumb-reorder-product-fields';
import SaveButton from '../../../components/buttons/save-button';
import { Subject, Subscription } from 'rxjs';
import ButtonsContainer from '../../../components/layout/buttons-container';
import logic, { State, UserNotification, Actions } from './reorder-product-fields-logic';

const debug = require('debug')('mk:product-fields:reorder-product-fields');

class ReorderProductFieldsPage extends React.Component<{}, State> {
  stateChanges: Subject<State>;
  notifications: Subject<UserNotification>;
  actions: (state: State) => Actions;
  subscription?: Subscription;
  notificationsSubscription?: Subscription;

  constructor(props: {}) {
    super(props);
    this.stateChanges = new Subject();
    this.notifications = new Subject();

    this.actions = logic.createActions(this.stateChanges, this.notifications);
    this.state = logic.getInitialState();
    this.subscription = undefined;
    this.notificationsSubscription = undefined;
  }

  async componentDidMount() {
    this.subscription = this.stateChanges.subscribe(state => {
      this.setState(state);
    });

    this.notificationsSubscription = this.notifications.subscribe((ev: UserNotification) => {
      const fn = message[ev.type];
      if (fn) {
        fn([ev.message]);
      }
    });

    this.actions(this.state).edit();
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  shouldComponentUpdate(nextProps: {}, nextState: State) {
    return this.state.categories !== nextState.categories
      || this.state.isSaving !== nextState.isSaving
      || this.state.isLoading !== nextState.isLoading
      || this.state.isSaveSuccess !== nextState.isSaveSuccess;
  }

  render() {
    let categories = Object.values(this.state.categories);
    categories = _.sortBy(categories, ['index']);

    return (
      <>
        <h1>Reorder Product Fields</h1>

        <p>You can reorder fields within a category and fields within</p>

        <ButtonsContainer>
          <SaveButton
            isSaving={this.state.isSaving}
            isLoading={this.state.isLoading}
            onClick={() => this.actions(this.state).save()}
            />          
        </ButtonsContainer>
          
        <DumbReorderProductFields
          categories={categories}
          onFieldSortEnd={this.actions(this.state).onFieldSortEnd}
          onCategoriesSortEnd={cats => this.actions(this.state).onCategoriesSortEnd(cats)}
        />
      </>
    )
  }
}

export default ReorderProductFieldsPage;
