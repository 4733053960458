import React from 'react';
import DumbEditTemplate from './edit-template/dumb';
import logic, { EditListState } from './edit-template/logic';
import EditWrapper from '../../support/EditWrapper';
import PageId from '../../types/page-id';
import { List } from '../../types/list';

type Props = {
  id: string,
  history: any
}

const helpText = (
  <>
    <p>Each item of the list has a value and display text. The value is what will be used by the API. This could be an ID, for example.</p>
    <p>The display text is friendly text that represents the value.</p>
  </>
)

const EditList : React.FC<Props> = ({ id, history }) => {
  return (
    <EditWrapper<List, EditListState>
      titleText="Data Source"
      itemTitle="data source"
      logic={logic}
      createTemplate={() => DumbEditTemplate}
      listingLink={PageId.lists}
      id={id}
      history={history}
      helpText={helpText}
    />
  )
};


export default EditList;
