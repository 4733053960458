import React, { useReducer } from 'react';
import Client from '../../../../types/client';
import DumbEditTemplate from './dumb-template';
import { createActions, reducer, EditClientState, initialState, Actions } from './logic';
import PageId from '../../../../types/page-id';
import EditWrapper from '../../../../support/edit-wrapper-v2/edit-wrapper-v2';

type Props = {
  id: string,
  history: any
}

const EditClient: React.FC<Props> = ({ id, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = createActions(dispatch);

  return (
    <EditWrapper<Client, EditClientState, Actions>
      titleText="Client"
      itemTitle="client"
      actions={actions}
      state={state}
      createTemplate={() => DumbEditTemplate}
      listingLink={PageId.clients}
      id={id}
      history={history}
    />
  )
 };

export default EditClient;
