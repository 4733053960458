import createDebug from 'debug';
import { Schema, SchemaType } from "../../../types/schema";

const debug = createDebug('mk:schema-form:validation');

type ValResult = { isValid: boolean; message: string };
type IMap<R> = {[key in keyof Schema]?: R };
type ValidatorFn = (s: Schema, v: string) => ValResult;

const rules : IMap<ValidatorFn> = {
  minLength: (schema : Schema, value: string) => {
    const isValid = value ? value.length >= (schema.minLength as number) : false;
    return {
      isValid,
      message: !isValid ? `Must be at least ${schema.minLength} character${schema.minLength === 1 ? '' : 's'} ` : ''
    }
  },
  maxLength: (schema : Schema, value: string) => {
    const isValid = value ? value.length <= (schema.maxLength as number) : false;
    return {
      isValid,
      message: !isValid ? `Must be at most ${schema.maxLength} character${schema.maxLength === 1 ? '' : 's'} ` : ''
    }
  },
  minimum: (schema: Schema, value: string) => {
    const isValid = Number(value) >= (schema.minimum as number);
    return {
      isValid,
      message: !isValid ? `Value must be at least ${schema.minimum}`: ''
    }
  },
  maximum: (schema: Schema, value: string) => {
    const isValid = Number(value) <= (schema.maximum as number);
    return {
      isValid,
      message: !isValid ? `Value must be at most ${schema.maximum}`: ''
    }
  },
  pattern: (schema: Schema, value: string = '') => {
    debug('checking the pattern');
    if (!schema.pattern) {
      debug('no pattern was supplied...');
      return {
        isValid: true,
        message: ''
      }
    }
    const regex = new RegExp(schema.pattern);

    const result = value.match(regex);

    debug('result was', result);

    const isValid = result !== null ? true : false;

    debug('pattern isValid', isValid);
    
    return {
      isValid,
      message: !isValid ? `Value must match the format`: ''
    }
  },
  type: (schema : Schema, value: string) => {
    if (schema.type === SchemaType.number) {
      if (value === '') {
        return {
          isValid: true,
          message: ''
        }
      }
      if (! (typeof value === 'number')) {
        return {
          isValid: false,
          message: 'Must be a number'
        }
      }
    }
    return {
      isValid: true,
      message: ''
    }
  }
};

export default rules;