import urls from './urls';
import createCrudService from "../support/create-crud-service";
import { CategoryGroup } from '../types/category';
import backoff from '../util/retry';
import CrudService from '../types/crud-service';
import addToken from '../support/add-token';

const service = createCrudService<CategoryGroup>(`${urls.api()}/category-groups`);

type CategoryService = CrudService<CategoryGroup> & {
  getByType: (type: string) => Promise<CategoryGroup>;
}

const getByType = async (type: string): Promise<CategoryGroup> => {
  let requestInfo: any = {
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  };

  requestInfo = await addToken(requestInfo);
  
  const response = await backoff(10, () => fetch(`${urls.api()}/category-groups/${type}`, requestInfo));
  const data = await response.json();

  return data;
};

const categoryService: CategoryService = { ...service, getByType }

export default categoryService;
