import React from 'react';
import ProductField from '../../types/product-field';
import ListingComponent from '../../support/create-listing-component';
import services from '../../services';
import { Link } from 'react-router-dom';
import productFieldService from '../../services/product-field-service';
import ProductFieldPurpose from '../../types/product-field-purpose';
import typeFriendlyNames from '../../support/type-friendly-names';

const getDef = (purpose: ProductFieldPurpose) => {
  const def = {
    singular: 'product field',
    columns: [
      {
        title: 'Display Name',
        dataIndex: 'displayName',
        key: 'displayName',
        render: (val : any, item: any) => (
          <Link to={`${def.editLink}/${item.id}`}>
            {val}
          </Link>
        )
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (val: any, record : ProductField) => {
          return typeFriendlyNames[record.type] || record.type;
        }
      },
    ],
    editLink: `/product-fields/${purpose}`
  }

  return def;
};

type Props = {
  purpose: ProductFieldPurpose
}

type State = {
  getData: () => Promise<ProductField[]>;
}

class ProductFields extends React.Component<Props, State> {
  constructor(props : any) {
    super(props);

    this.state = {
      getData: this.getAllFiltered(props.purpose)
    };
    this.onChangePurpose = this.onChangePurpose.bind(this);
    this.getAllFiltered = this.getAllFiltered.bind(this)
  }

  componentDidUpdate(prevProps : Props, prevState : State) {
    if (prevProps.purpose !== this.props.purpose) {
      this.setState({
        getData: this.getAllFiltered(this.props.purpose)
      })
    }
  }

  getAllFiltered(purpose: ProductFieldPurpose) {
    return async () : Promise<ProductField[]> => {
      const data = await productFieldService.getAll();
      return data.filter(x => x.purpose === purpose);
    }
  }
  
  onChangePurpose(purpose : any) {
    this.setState({ getData: this.getAllFiltered(purpose) });
  }

  getDescription(purpose: ProductFieldPurpose) {
    switch (purpose) {
      case ProductFieldPurpose.core: return 'These are the fields that make up your product';
      case ProductFieldPurpose.common: return 'These are fields that are common to all products for a supplier';
      case ProductFieldPurpose.inventory: return 'These are fields that can regularly change about a product, such as a stock level';
    }
  }

  render() {
    const title = this.props.purpose !== ProductFieldPurpose.core
      ? ` - ${this.props.purpose}`
      : '';
    
    const description = this.getDescription(this.props.purpose);

    return (
      <>
        <h1>Product Structure{title}</h1>

        <p>{description}</p>

        <ListingComponent
          definition={getDef(this.props.purpose)}
          getAll={this.state.getData}
          deleteOne={services.productFieldService.delete}
          isLoading={true}
        />
      </>
    );
  }  
}

export default ProductFields;
