import _ from 'lodash';
import urls from './urls';
import createCrudService from "../support/create-crud-service";
import ProductField from "../types/product-field";
import CrudService from '../types/crud-service';
import categoriesService from './categories-service';
import { OrderMap } from '../types/category';

const service = createCrudService<ProductField>(`${urls.api()}/product-fields`);

type ProductFieldWithIndex = ProductField & { index?: number };

type ProductFieldService = CrudService<ProductField> & {
  getAllSorted: () => Promise<ProductField[]>
}

const productFieldService: ProductFieldService = {
  ...service,
  getAll: async (): Promise<ProductField[]> => {
    const results = await service.getAll();

    if (results && Array.isArray(results)) {
      return _.orderBy(results, [r => r.displayName.toLowerCase()], ['asc']);
    }

    return results;
  },
  getAllSorted: async () => {
    const categoryGroup = (await categoriesService.getByType('product_field'));
    const fieldOrders = categoryGroup.values.map(x => x.fieldOrder).reduce((prev: OrderMap, curr: OrderMap) => ({ ...prev, ...curr }), {});

    let productFields = await productFieldService.getAll();

    for (const productField of productFields as ProductFieldWithIndex[]) {
      const index = fieldOrders[productField.id];
      productField.index = index;
    }

    productFields = _.sortBy(productFields, ['index']);

    return productFields;
  }
}

export default productFieldService;
