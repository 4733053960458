import React from 'react';
import createDebug from 'debug';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

const debug = createDebug('mk:dyn-form:multiple-items:remove-button');

type Disableable = {
  onClick: (event: any) => void;
  isDisabled?: boolean;
}

const AddButton = (props: ButtonProps & Disableable) => {
  let clickAction : any;
  if (!!props.isDisabled) {
    debug('RemoveButton.DISABLED');
    clickAction = () => true;
  } else {
    debug('RemoveButton.NOT_DISABLED');
    clickAction = props.onClick;
  }

  return (
    <Tooltip title="Remove">
      <Button
        size="small"
        type="primary"
        onClick={clickAction}
        icon={<MinusCircleOutlined />}
      />
    </Tooltip>
  );
}

export default AddButton;
