import React from 'react';
import EditSupplierTemplate from './template';
import { Supplier } from '../../types/supplier';
import PageId from '../../types/page-id';
import logic, { EditSupplierState } from './edit/logic';
import EditWrapper from '../../support/EditWrapper';

type Props = {
  id: string,
  history: any
}

const EditSupplier: React.FC<Props> = ({ id, history }) => {
  return (
    <EditWrapper<Supplier, EditSupplierState>
      titleText="Supplier"
      itemTitle="supplier"
      logic={logic()}
      createTemplate={() => EditSupplierTemplate}
      listingLink={PageId.suppliers}
      id={id}
      history={history}
    />
  )
};

export default EditSupplier;
