import React from 'react';
// import { loadStripe } from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import './styles.css';

const CARD_OPTIONS : any = {
  iconStyle: 'solid',
  style: {
    base: {
      color: '#333',
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
      fontSize: '15px',
      '::placeholder': {
        color: '#ccc'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },
};

const CardField = () => (
  <div>
    <CardElement
      options={CARD_OPTIONS}
    />
  </div>
);

export default CardField;