import React from 'react';
import { FormStructure } from '../../../components/dynamic-form/types';
import { OnboardingDetails } from '../types';
import formStructure from './form-structure';
import accountService from '../../../services/account-service';
import SaveButton from '../../../components/buttons/save-button';
import PageId from '../../../types/page-id';
import SmartDynamicForm from '../../../components/dynamic-form/smart-dynamic-form';
import ButtonsContainer from '../../../components/layout/buttons-container';

const debug = require('debug')('mk:pages:onboard');

type State = {
  value: OnboardingDetails,
  isSaving: boolean,
  isLoading: boolean
}

export default class Onboard extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.state = {
      isLoading: true,
      isSaving: false,
      value: {
        companyName: '',
        firstName: '',
        lastName: ''
      }
    }
  }

  async componentDidMount() {
    const result = await accountService.onboardingInfo();
    
    if (result.isOnboarded) {
      window.location.replace(PageId.home);
    } else {
      this.setState({ isLoading: false })
    }
  }

  onChange(_: FormStructure, val: OnboardingDetails) {
    this.setState({
      value: val
    })
  
    debug('value', val);
  }

  async onSave() {
    this.setState({ isSaving: true });
    debug('onSave', this.state.value);
    const result = await accountService.onboard(this.state.value);

    if (result.isOnboarded) {
      this.setState({ isSaving: false });
      window.location.replace(PageId.home);
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <h1>Let's set up your account</h1>
  
        <SmartDynamicForm<OnboardingDetails>
          structure={formStructure}
          value={this.state.value}
          onChange={this.onChange} />
        
        <ButtonsContainer>
          <SaveButton
            onClick={this.onSave}
            isLoading={false}
            isSaving={this.state.isSaving}
          />
        </ButtonsContainer>
        
      </div>
    )
  }
}
