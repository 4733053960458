import React from 'react';
import ListingComponent from '../../support/create-listing-component';
import services from '../../services';
import Definition from '../../types/listing-component-definition';
import { Link } from 'react-router-dom';
import { SupplierUser } from '../../types/supplier-user';

const def : Definition<SupplierUser> = {
  singular: 'supplier user',
  columns: [
    {
      title: 'Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (val, item) => (
        <Link to={`${def.editLink}/${item.id}`}>
          {item.firstName} {item.lastName}
        </Link>
      )
    },
    {
      title: 'Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (val, item) => (
        <Link to={`${def.editLink}/${item.id}`}>
          {item.firstName} {item.lastName}
        </Link>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLoginDate',
      key: 'lastLoginDate'
    },
  ],
  editLink: `/supplier`
}

type Props = {
}

const SupplierUsers : React.FC<Props> = () => (
  <>
    <h1>Supplier Users</h1>
    <ListingComponent
      definition={def}
      getAll={services.supplierUserService.getAll}
      deleteOne={services.supplierService.delete}
      isLoading={true}
    />
  </>
);

export default SupplierUsers;
