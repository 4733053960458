import React from 'react';
import { FormField, ValidationState } from './types';
import TextField from './fields/text';
import NumberField from './fields/number';
import SelectField from './fields/select';
import CheckBoxField from './fields/checkbox';
import RadioField from './fields/radio';
import CurrencyField from './fields/currency';
import DateField from './fields/date';

// TODO: This factory could be taken from React context
//       The factory could be set by a DynamicForm.Provider component that sets the context
const factory = (field: FormField, value: any, onChange: (v : any) => void, additionalProps?: object, validationState?: ValidationState, isDisabled?: boolean) => {
  switch (field.type) {
    case 'text':
      return (
        <TextField
          field={field}
          value={value}
          validationState={validationState}
          onChange={onChange}
          isDisabled={isDisabled}
          {...additionalProps}
        />
      )
    case 'number':
      return (
        <NumberField
          field={field}
          value={value}
          validationState={validationState}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      )
    case 'currency':
      return (
        <CurrencyField
          field={field}
          value={value}
          validationState={validationState}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      )
    case 'date':
      return (
        <DateField
          field={field}
          value={value}
          validationState={validationState}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      )
  
    case 'check':
      return (
        <CheckBoxField
          field={field}
          value={value}
          validationState={validationState}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      )
    case 'select':
      return (
        <SelectField
          field={field}
          value={value}
          validationState={validationState}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      )
    case 'radio':
      return (
        <RadioField
          field={field}
          value={value}
          validationState={validationState}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      )
  }
}

export default factory;
