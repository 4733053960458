import React from 'react';

const Dashboard = () => (
  <>
    <h1>Dashboard</h1>

    <div>
      API Calls: 124k
    </div>
  </>
);

export default Dashboard;