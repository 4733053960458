const schema = {
  type: 'object',
  properties: {
    displayName: { title: 'Display Name', type: 'string' },
    logo: { title: 'Logo', type: 'string' }
  },
  required: ['displayName'],
  additionalProperties: false
};

export default schema;