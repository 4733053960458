import React from 'react';
import StyledButton from './button';

export default (props: any) => {
  return (
    <StyledButton
      type="outline"
      {...props}
    >
      Cancel
    </StyledButton>
  );
}