import React from 'react';
import Condition, { ConditionType } from '../../../types/condition';
import ProductField from '../../../types/product-field';
import SearchItemField from '../../../types/search-item-field';
import WithId from '../../../types/with-id';
import Table from 'antd/lib/table';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import FieldType from '../../../types/field-type';

const findWithId = <T extends WithId>(items : T[], id: number) : T => {
  return items.find(x => x.id === id) as T;
}

type ExamplesProps = {
  productFields: ProductField[],
  searchItemFields: SearchItemField[],
  item: Condition
};

const Examples = ({ productFields, searchItemFields, item } : ExamplesProps) => {
  const productField = findWithId(productFields, item.productFieldId);
  const searchItemField = findWithId(searchItemFields, item.searchItemFieldId);

  if (! (productField && searchItemField)) {
    return <div></div>
  }

  const columnsToSet = [
    {
      title: `Search Value: ${searchItemField?.displayName}`,
      dataIndex: 'searchItemField',
      key: 'searchItemField'
    },
    {
      title: `Product Value: ${productField?.displayName}`,
      dataIndex: 'productField',
      key: 'productField'
    },
    {
      title: 'Would the product match?',
      dataIndex: 'isMatch',
      key: 'isMatch',
      render: (val : boolean) => val ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#ff4d4f" />
    },
  ];

  let exampleData;

  switch (productField.type) {
    case FieldType.integer:
    case FieldType.number:
      switch (item.type) {
        case ConditionType.EQ:
          exampleData = ([
            { productField: '10', searchItemField: '9', isMatch: false },
            { productField: '10', searchItemField: '10', isMatch: true },
            { productField: '10', searchItemField: '11', isMatch: false }
          ]);

          if (item.isEmptyProductValueMatches) {
            exampleData.push({ productField: '', searchItemField: '10', isMatch: true })
          } else {
            exampleData.push({ productField: '', searchItemField: '10', isMatch: false })
          }
          break;
        case ConditionType.GTE:
          exampleData = ([
            { productField: 11, searchItemField: 10, isMatch: false },
            { productField: 10, searchItemField: 10, isMatch: true },
            { productField: 9, searchItemField: 10, isMatch: true }
          ]);
          break;
        case ConditionType.LTE:
          exampleData = ([
            { productField: 9, searchItemField: 10, isMatch: false },
            { productField: 10, searchItemField: 10, isMatch: true },
            { productField: 11, searchItemField: 10, isMatch: true }
          ]);
          break;
        case ConditionType.ITEM_EXISTS_IN_ARRAY:
          exampleData = ([
            { productField: '1,2,3', searchItemField: '1', isMatch: true },
            { productField: '1,2,3', searchItemField: '3', isMatch: true },
            { productField: '1,2,3', searchItemField: '4', isMatch: false }
          ]);
          break;
        }
      break;
    case FieldType.string:
      switch (item.type) {
        case ConditionType.EQ:
          exampleData = ([
            { productField: 'some text', searchItemField: 'some text', isMatch: true },
            { productField: 'other text', searchItemField: 'some text', isMatch: false }
          ]);
          break;
        case ConditionType.ITEM_EXISTS_IN_ARRAY:
          exampleData = ([
            { productField: '[a,b,c]', searchItemField: 'a', isMatch: true },
            { productField: '[a,b,c]', searchItemField: 'c', isMatch: true },
            { productField: '[a,b,c]', searchItemField: 'd', isMatch: false }
          ]);

          if (item.isEmptyProductValueMatches) {
            exampleData.push({ productField: '[]', searchItemField: 'a', isMatch: true })
          } else {
            exampleData.push({ productField: '[]', searchItemField: 'a', isMatch: false })
          }
          break;
        case ConditionType.ITEM_NOT_EXISTS_IN_ARRAY:
          exampleData = ([
            { productField: '[a,b,c]', searchItemField: 'a', isMatch: false },
            { productField: '[a,b,c]', searchItemField: 'c', isMatch: false },
            { productField: '[a,b,c]', searchItemField: 'd', isMatch: true }
          ]);

          break;
        case ConditionType.SOME_ITEMS_EXIST_IN_ARRAY:
            exampleData = ([
              { productField: '[a,b,c]', searchItemField: 'a', isMatch: true },
              { productField: '[a,b,c]', searchItemField: 'a,b', isMatch: true },
              { productField: '[a,b,c]', searchItemField: 'a,c', isMatch: true },
              { productField: '[a,b,c]', searchItemField: 'a,d', isMatch: true },
              { productField: '[a,b,c]', searchItemField: 'd,e', isMatch: false }
            ]);
          
            if (item.isEmptyProductValueMatches) {
              exampleData.push({ productField: '[]', searchItemField: 'a', isMatch: true })
            } else {
              exampleData.push({ productField: '[]', searchItemField: 'a', isMatch: false })
            }
          break;
        case ConditionType.ALL_ITEMS_EXIST_IN_ARRAY:
          exampleData = ([
            { productField: '[a,b,c]', searchItemField: '[a]', isMatch: true },
            { productField: '[a,b,c]', searchItemField: '[a,b]', isMatch: true },
            { productField: '[a,b,c]', searchItemField: '[a,d]', isMatch: false },
            { productField: '[a,b,c]', searchItemField: '[d,e]', isMatch: false }
          ]);
        
          if (item.isEmptyProductValueMatches) {
            exampleData.push({ productField: '[]', searchItemField: 'a', isMatch: true })
          } else {
            exampleData.push({ productField: '[]', searchItemField: 'a', isMatch: false })
          }
          break;
        case ConditionType.NO_ITEMS_EXIST_IN_ARRAY:
          exampleData = ([
            { productField: '[a,b,c]', searchItemField: '[a]', isMatch: false },
            { productField: '[a,b,c]', searchItemField: '[a,b]', isMatch: false },
            { productField: '[a,b,c]', searchItemField: '[a,d]', isMatch: false },
            { productField: '[a,b,c]', searchItemField: '[d,e]', isMatch: true }
          ]);
        
          if (item.isEmptyProductValueMatches) {
            exampleData.push({ productField: '[]', searchItemField: '[a]', isMatch: true })
          } else {
            exampleData.push({ productField: '[]', searchItemField: '[a]', isMatch: false })
          }
          break;
      }
      break;
  }

  return (
    <Table
      size="small"
      columns={columnsToSet}
      dataSource={exampleData}
      pagination={false}
    />
  );
}

export default Examples;