import React from 'react';
import { Table } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import './style.css'

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'move', color: '#999' }} />
));

const columns = [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: 'Display Name',
    dataIndex: 'displayName',
    className: 'drag-visible',
  },
  {
    title: 'Type',
    dataIndex: 'type',
  }
];

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

type OnSortEndArgs = { oldIndex: number, newIndex: number };

type Field = {
  id: number,
  displayName: string,
  index: number
}

type Props = {
  fields: Field[],
  onSortEnd: (fields: Field[]) => void
}

class SortableTable extends React.Component<Props, {}> {
  constructor(props: any) {
    super(props);
    this.state = {
      // dataSource: props.fields.map((x: Field) => ({
      //   // key: x.id.toString(),
      //   ...x
      // })),
    };
    // this.onSave = this.onSave.bind(this);
  }

  onSortEnd = ({ oldIndex, newIndex }: OnSortEndArgs) => {
    // const dataSource: Field[] = this.state.dataSource;
    const dataSource: Field[] = this.props.fields;
    if (oldIndex !== newIndex) {
      // @ts-ignore
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter((el) => !!el) as Field[];
      // @ts-ignore
      // this.setState({ dataSource: newData });
      this.props.onSortEnd(newData);
    }
  };

  // @ts-ignore
  DraggableBodyRow = ({ className, style, ...restProps }) => {
    // const { dataSource } = this.state;
    const dataSource = this.props.fields;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  render() {
    // const { dataSource } = this.state;
    const DraggableContainer = (props: any) => (
      <SortableBody
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
    return (
      <>
        <Table
          pagination={false}
          dataSource={this.props.fields}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: this.DraggableBodyRow
            },
          }}>
        </Table>
      </>
    );
  }
}

export default SortableTable;
