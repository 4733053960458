import React from 'react';
import styled from 'styled-components';

type MenuProps = {
  direction: 'horizontal' | 'vertical'
}

export const StyledMenu = styled.div<MenuProps>`
  background-color: #0D0D10;
  overflow: hidden;
  display: flex;
  flex-direction: ${props => props.direction === 'horizontal' ? 'row' : 'column'};
  flex-wrap: nowrap;
`;

type StyledMenuItemProps = {
  isActive?: boolean
}

export const StyledMenuItem = styled.div<StyledMenuItemProps>`
  display: block;
  color: #f2f2f2;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    display: none;
  }

  a {
    color: white;
    width: 100%;
    height: 100%;
    display: block;
    /* border: 1px dashed red; */
  }

  background: ${props => props.isActive ? '#1FA175' : '#0D0D10'};

  &:hover {
    background-color: #1FA175;
    color: black;
  }
`;

export const StyledHamburger = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    padding: 14px 16px;
    display: block;
    color: white;

    cursor: pointer;

    &:hover {
      background-color: #1FA175;
      color: black;
    }
  }
`;
