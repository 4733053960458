import jsf from 'json-schema-faker';

const options = {
  alwaysFakeOptionals: true
}

jsf.option(options);

export const generate = (schema: any) => {
  return jsf.generate(schema, ); 
}
