import React from 'react';
import styled from 'styled-components';
import Help from './help';

type FormItemProps = {
  label: string,
  helpText?: string,
  children: any,
  isRequired?: boolean,
  isVisible?: boolean,
  isDisabled?: boolean
}

type ContainerProps = {
  isVisible: boolean,
  isDisabled: boolean
}

const Container = styled.div<ContainerProps>`
  display: ${props => props.isVisible ? 'block' : 'none'};
  color: ${props => props.isDisabled ? 'lightgray' : 'black' };
`;

const FormItemInner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

const LabelContent = styled.div`
  width: 20%;
  text-align: right;
  padding: 4px 5px 0 0;
`;

const ChildrenContainer = styled.div`
  width: 40%;
`;

const StyledRequired = styled.span`
  color: red;
`;

const ShowRequired = ({ isRequired }: { isRequired?: boolean }) => {
  return isRequired
    ? <StyledRequired> *</StyledRequired>
    : <></>;
}

const FormItem = ({ label, helpText, children, isRequired, isVisible = true, isDisabled = false }: FormItemProps) => {
  return (
    <Container isVisible={isVisible} isDisabled={isDisabled}>
      <label>
        <FormItemInner>
          <LabelContent>{label}<ShowRequired isRequired={isRequired} /></LabelContent>
          <Help helpText={helpText} />
          <ChildrenContainer>
            {children}
          </ChildrenContainer>
        </FormItemInner>
      </label>
    </Container>
  )
};

const areEqual = (prevProps: FormItemProps, nextProps: FormItemProps) => {
  return prevProps.label === nextProps.label
    && prevProps.helpText === nextProps.helpText
    && prevProps.children === nextProps.children
    && prevProps.isRequired === nextProps.isRequired
    && prevProps.isVisible === nextProps.isVisible
    && prevProps.isDisabled === nextProps.isDisabled;
}

export default React.memo(FormItem, areEqual);

// export default FormItem;
