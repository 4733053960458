import React from 'react';
import { Select } from 'antd';
import logic, { EditProductState, Actions } from './logic';
import SaveButton from '../../components/buttons/save-button';
import CancelButton from '../../components/buttons/cancel-button';
import FormItem from '../../components/forms/form-item';
import SmartDynamicForm from '../../components/dynamic-form/smart-dynamic-form';
import { Subject } from 'rxjs';
import PageId from '../../types/page-id';
import ItemSaveOutcome from '../../types/item-save-outcome';
import notify from '../../events/notify';
import EventCode from '../../events/event-code';
import ButtonsContainer from '../../components/layout/buttons-container';

const { Option } = Select;

type Props = {
  id: string,
  history: any
}

class EditProduct extends React.Component<Props, EditProductState> {
  stateChanges: Subject<Partial<EditProductState>>;
  // notifications: Subject<Partial<EditProductState>>;
  actions: (state: EditProductState) => Actions;
  subscription: any;
  constructor(props : Props) {
    super(props);
    this.stateChanges = new Subject();
    // this.notifications = new Subject();

    this.actions = logic.createActions(this.stateChanges);
    this.state = logic.getInitialState();
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  async componentDidMount() {
    this.subscription = this.stateChanges.subscribe(state => {
      this.setState(state as EditProductState);
    });

    const { id } = this.props;

    if (id !== '-1') {
      this.actions(this.state).edit(Number(id));
    } else {
      this.actions(this.state).newItem();
    }
  }

  componentWillMount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async save() {
    const saveResult = await this.actions(this.state).save();

    if (saveResult.outcome === ItemSaveOutcome.created || saveResult.outcome === ItemSaveOutcome.updated) {
      notify(EventCode.ITEM_SAVED_SUCCESSFULLY, saveResult);
      this.backToListing();
    } else {
      notify(EventCode.ITEM_SAVE_ERROR, saveResult);
    }    
  }

  cancel() {
    this.backToListing();
  }

  backToListing() {
    const { history } = this.props;
    history.push(PageId.products);
  }

  render() {
    const { state } = this;
    const { isLoading, isSaving, item, suppliers } = state;
    const { supplierId } = item;

    return (
      <>
        <h1>Edit Product</h1>
  
        <FormItem
          label="Supplier"
        >
          <Select
            disabled={isLoading}
            value={supplierId}
            style={{ width: '90%' }}
            onChange={x =>  this.actions(state).setSupplierId(x)}
          >
            {suppliers.map(d => (
              <Option key={d.id} value={d.id}>{d.displayName}</Option>
            ))}
          </Select>
        </FormItem>
        {
          (state.structure ? <SmartDynamicForm<object>
          structure={state.structure}
          onChange={(structure, data) => this.actions(state).mutate(structure, data)}
          value={item.data!}
        /> : <></>)
        }

        <ButtonsContainer>
          <SaveButton
            onClick={() => this.save()}
            isSaving={isSaving}
            isLoading={isLoading}
          />
    
          <CancelButton
            onClick={() => this.cancel()} />
        </ButtonsContainer>
      </>
    )
  }
};

export default EditProduct;
