const debug = require('debug')('mk:utils:retry');

const pause = (duration: number) => new Promise(res => setTimeout(res, duration));

const backoff = (retries : number, fn : any, delay = 500) => 
fn().catch( (err : any) => {
  debug('error when fetching', err);
  return retries > 1
      ? pause(delay).then(() => backoff(retries - 1, fn, delay * 2))
      : Promise.reject(err)
});

export default backoff;