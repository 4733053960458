/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Tag, Popover } from 'antd';
import Product from '../../types/product';
import { ColumnType } from 'antd/lib/table/interface';
import numeral from 'numeral';
import productFieldService from '../../services/product-field-service';
import { MatchType } from '../../types/results';

const CURRENCY_FORMAT = '(0a)';
const formatCurrency = (amount : number) => '£' + numeral(amount).format(CURRENCY_FORMAT);

type Props = {
  results: Array<Product>,
  isLoading: boolean,
  height: number,
  onInspectProduct: (product: Product) => void;
  problemColumnTitle: string | React.ReactNode
}

const TableWrapper = styled.div`
  .ant-table {
    font-size: 10px;
  }

  .highlight {
    color: red;
    font-weight: bold;
  }
  .id-cell {
    cursor: pointer;
  }

  .id-cell:hover {
    background-color: grey;
  }

  .id-link {
    cursor: pointer;
  }
`;

const ProductMatches : React.FunctionComponent<Props> = ({ results, height, onInspectProduct, problemColumnTitle }) => {
  const initialColumns : Array<ColumnType<Product>> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      className: 'id-cell',
      render: (val: any, product: Product) => {
        return (
          <span onClick={x => onInspectProduct(product)} className="id-link">
            {val}
          </span>
        );
      }
    },
  ];

  const [columns, setColumns] = useState<ColumnType<Product>[]>(initialColumns);

  useEffect(() => {
    const run = async () => {
      const productFields = await productFieldService.getAllSorted();

      let summaryFields = productFields.filter(x => x.isSummary);

      if (summaryFields.length === 0) {
        summaryFields = productFields.slice(0, 1);
      }

      const additionalColumns : ColumnType<Product>[] = summaryFields.map(f => ({
        dataIndex: f.name,
        key: f.id,
        title: f.displayName
      }));

      additionalColumns.push({
        title: problemColumnTitle,
        dataIndex: 'categories',
        key: 'categories',
        width: '20%',
        render: (categories : string[], product : any) => {
          if (!categories || !Array.isArray(categories)) {
            return <></>
          }

          const content = (
            <div>
              {product.codes.map((code : string, index: number) => <p key={`p-${index}`}>{code}</p>)}
            </div>
          );
    
          if (product.matchType === MatchType.indeterminate) { 
            return (
              <Popover
                content={content}
                title="Unchecked rules"
                trigger="hover"
              >
                <div>
                  {categories.map((cat, index) => <Tag key={`tag-${index}`} color="#ccc">{cat}?</Tag>)}
                </div>
              </Popover>
            )
          }
    
          if (product.matchType === MatchType.near) {
            return (
              <Popover
                content={content}
                title="Reasons"
                trigger="hover"
              >
                <div>
                  {categories.map((cat, index) => <Tag key={`tag-${index}`} color="orange">{cat}</Tag>)}
                </div>
              </Popover>
            )
          }
    
          if (product.matchType === MatchType.not) {
            return (
              <Popover content={content} title="Reasons" trigger="hover">
                <div>
                  {categories.map((cat, index) => <Tag key={`tag-${index}`} color="volcano">{cat}</Tag>)}
                </div>
              </Popover>
            )
          }
        }
      })

      setColumns([...initialColumns, ...additionalColumns]);
    };
    run();
  }, []);

  return (
    <TableWrapper>
      <Table
        scroll={{ y: `calc(${height}vh)` }}
        size={'small'}
        dataSource={results}
        columns={columns}
        pagination={false}
      />
    </TableWrapper>
  );
};

export default ProductMatches;
