import FieldType from "../types/field-type";

const schema = {
  type: 'object',
  properties: {
    displayName: { type: 'string', title: 'Display Name', pattern: `^[a-zA-Z 0-9]*$`, minLength: 1 },
    name: { type: 'string', title: 'Name', minLength: 1 },
    type: { title: 'Type', type: 'string', enum: [FieldType.number, FieldType.string, FieldType.boolean, FieldType.date]},
    isValuesRestricted: { title: 'Restrict Values', type: 'boolean' },
    isSeparateListSpecified: { type: 'boolean', title: 'Use Data Source' },
    allowedValuesListId: { type: 'number', title: 'Allowed Values Data Source' },
    allowedValues: { title: 'Allowed Values', type: 'array', items: { type: FieldType.string } },
    isRequired: { title: 'Required', type: 'boolean' },
    isMultiple: { title: 'Multiple', type: 'boolean' },
    minLength: { title: 'Min Length', type: 'number', minimum: 0 },
    maxLength: { title: 'Max Length', type: 'number', minimum: 0 },
    minimum: { title: 'Min', type: 'number', minimum: 0 },
    maximum: { title: 'Max', type: 'number', minimum: 0 },
    category: { type: 'string', title: 'Category' },
    pattern: { type: 'string', title: 'Pattern' }
  },
  required: ['name', 'displayName', 'type', 'isRequired', 'isMultiple', 'isValuesRestricted'],
  additionalProperties: false
};

export default schema;