import conditionService from './condition-service';
import productFieldService from './product-field-service';
import searchItemFieldService from './search-item-field-service';
import schemaService from './schema-service';
import supplierService from './supplier-service';
import supplierUserService from './supplier-user-service';
import subscriptionService from './subscription-service';
import listService from './list-service';
import categoriesService from './categories-service';
import clientsService from './clients-service';
import listsService from './list-service';

export default {
  conditionService,
  productFieldService,
  searchItemFieldService,
  schemaService,
  supplierService,
  supplierUserService,
  subscriptionService,
  listService,
  categoriesService,
  clientsService,
  listsService
}