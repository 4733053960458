import backoff from "../util/retry";
import urls from "./urls";
import SearchItem from "../types/search-item";
import { MatchResults } from "../types/results";
import addToken from "../support/add-token";

export default {
  matches: async (item: SearchItem) : Promise<MatchResults> => {
    const requestInfo : any = {
      method: 'POST',
      body: JSON.stringify(item),
      headers: {
        'content-type': 'application/json'
      }
    };

    const newRequestInfo = await addToken(requestInfo);
    
    const response = await backoff(10, () => fetch(`${urls.api()}/matches`, newRequestInfo));
    const data = await response.json();
  
    return data;
  },
}