import React from 'react';
import createDebug from 'debug';
import { Select, Spin } from 'antd';
import { FieldProps } from '../field-props';
import ValidationMessages from './validation-messages';

const debug = createDebug('mk:dyn-form:dumb-select');

const { Option } = Select;

export default class DumbSelect extends React.Component<FieldProps> {
  changeValue(value: string) {
    this.props.onChange(value);
  }

  shouldComponentUpdate(nextProps: FieldProps) {
    return nextProps.value !== this.props.value
      || nextProps.field !== this.props.field
      || nextProps.validationState !== this.props.validationState
      || nextProps.isDisabled !== this.props.isDisabled;
  }

  render() {
    debug(`DumbSelect - render ${this.props.field.name}`);

    const { field, value, validationState, isDisabled } = this.props;
    const { name, isLoading } = field;
    // const { isValid, messages } = validationState || { isValid: true };

    const values = field.values || [];

    // if (!values) {
    //   throw new Error('No values supplied to select field');
    // }

    return (
      <React.Fragment key={name}>
        <Select
          size="middle"
          disabled={isLoading || isDisabled}
          value={isLoading ? 'Loading...' : value}
          style={{ width: '100%' }}
          notFoundContent={isLoading ? <Spin size="small" /> : null}
          onChange={x => this.changeValue(x)}>
          {values.map(opt => (
            <Option
              key={opt.value}
              value={opt.value}
            >
              {opt.label}
            </Option>
          ))}
        </Select>

        <ValidationMessages
          validationState={validationState}
        />
      </React.Fragment>
    )
  }
}