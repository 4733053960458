const debug = require('debug')('mk:util:to-undefined-or-number');

const toUndefinedOrNumber = (val: any): undefined | number => {
  debug('CHECKING VALUE', val);
  if (val === '') {
    debug('RETURNING NULL');
    return undefined;
  }

  const coercedValue = Number(val);
  if (Number.isNaN(coercedValue)) {
    return undefined;
  } else {
    return coercedValue;
  }  
}

export default toUndefinedOrNumber;
