import { Subject } from 'rxjs';
import createDebug from 'debug';
import services from "../../../services";
import ItemSaveResult from '../../../types/item-save-result';
import { List } from '../../../types/list';

const debug = createDebug('mk:edit-list:logic');

export type EditListState = {
  item: Partial<List>,
  isValid: boolean,
  isLoading: boolean,
  isSaving: boolean
}

export type Actions = {
  mutate: (item: Partial<List>) => Promise<void>,
  newItem: () => Promise<void>,
  edit: (id : number) => Promise<void>,
  save: () => Promise<ItemSaveResult<List>>
}

const createActions = (stateChanges: Subject<Partial<EditListState>>) => function handle(state: EditListState) : Actions {
  return {
    newItem: async () => {
      debug('NEW ITEM');
      stateChanges.next({ isLoading: true });

      const newState = {
        ...state,
        isValid: false,
        isLoading: false
      }

      stateChanges.next(newState)
    },
    edit: async (id: number) => {
      debug('EDIT', id);
      stateChanges.next({ isLoading: true });
      const list = await services.listService.get(id);

      stateChanges.next({
        item: list,
        isLoading: false
      });
    },
    save: async () => {
      stateChanges.next({ isLoading: true });

      const itemToSave = {
        ...state.item
      };

      debug('itemToSave', itemToSave);

      const itemSaveResult = await services.listService.create(itemToSave as List);

      stateChanges.next({ isLoading: false }); // TODO: This would send back some kind of save success / validation error
      return itemSaveResult;
    },
    mutate: async (item: Partial<List>) => {
      debug('MUTATE', item);

      const newState: EditListState = {
        ...state
      };

      const newItem = {
        ...state.item,
        ...item
      };

      const stateChange = {
        ...newState,
        item: {
          ...newItem
        }
      }

      debug('NEW STATE', stateChange);
    
      stateChanges.next(stateChange);
      return;
    }
  }
}

const logic = {
  getInitialState: () => {
    const state: EditListState = {
      item: {
        displayName: '',
        values: []
      },
      isValid: true,
      isLoading: true,
      isSaving: false
    }
  
    return state;
  },
  createActions
};

export default logic;
