// import _ from 'lodash';
import { Subject } from 'rxjs';
import createDebug from 'debug';
// import ProductField from '../../../types/product-field';
import services from "../../../services";
import ItemSaveResult from '../../../types/item-save-result';
import { Schema } from '../../../types/schema';
import { FormStructure } from '../../../components/dynamic-form/types';
import formStructure from './form-structure';
// import { Category } from '../../../types/category';
import ItemSaveOutcome from '../../../types/item-save-outcome';
import { Error } from '../../../support/error';
import { Supplier } from '../../../types/supplier';
import supplierSchema from '../supplier-schema';
// import produce from 'immer';

// TODO TRY OUT "IMMER" Library

const debug = createDebug('mk:edit-product-field:logic');

// const toUndefinedOrNumber = (val: any) : number | null => {
//   debug('CHECKING VALUE', val);
//   if (val === '') {
//     debug('RETURNING NULL');
//     return null;
//   }

//   const coercedValue = Number(val);
//   if (Number.isNaN(coercedValue)) {
//     return null;
//   } else {
//     return coercedValue;
//   }
// }

export type EditSupplierState = {
  structure: FormStructure,
  item: Partial<Supplier>,
  schema: Schema,
  isValid: boolean,
  isLoading: boolean,
  isSaving: boolean,
  errors?: Error[]
}

export type Actions = {
  mutate: (structure: FormStructure, item: Partial<Supplier>, categoryName: string, fieldName: string, fieldValue: any) => Promise<void>,
  newItem: () => Promise<void>,
  edit: (id : number) => Promise<void>,
  save: () => Promise<ItemSaveResult<Supplier>>
}

type SupplierKey = keyof Supplier;

const handler = async (state: EditSupplierState, item: Supplier, categoryName: string, fieldName: SupplierKey, fieldValue: any): Promise<EditSupplierState> => {
  debug(`handling... ${categoryName}, ${fieldName} = ${fieldValue}`);
  
  // switch (fieldName) {
  //   case 'displayName': {
  //     const newItem = { ...item };
  //     newItem.name = camelize(fieldValue);
  //     const newState = {
  //       ...state,
  //       item: newItem
  //     }
  //     return newState;
  //   }
  //   case 'isValuesRestricted': {
  //     return setIsValuesRestricted(state, item);
  //   }
  //   case 'isSeparateListSpecified': {
  //     return setIsSeparateListSpecified(state, item);
  //   }
  //   case 'type': {
  //     return setType(state, item);
  //   }
  //   default: {
  //     debug('default handler', item);
  //     const newState = { ...state, item: { ...item } };
  //     return newState;
  //   }
  // }

  // return 

  // const newState = produce(state, draft => {
    
  // })

  const newState = {
    ...state,
    item: {
      ...state.item,
      [fieldName]: fieldValue
    }
  }

  return newState;
}

const createActions = (stateChanges: Subject<Partial<EditSupplierState>>) => function handle(state: EditSupplierState) : Actions {
  return {
    newItem: async () => {
      debug('NEW ITEM');
      stateChanges.next({ isLoading: true });

      // const newStructure = await loadCategories(state.structure);

      let newState : EditSupplierState = {
        ...state,
        structure: formStructure,
        isValid: false,
        isLoading: false
      }

      // newState = setType(newState, state.item as ProductField);
      // newState = setIsValuesRestricted(newState, state.item as ProductField);
      // newState = await setIsSeparateListSpecified(newState, state.item as ProductField);

      stateChanges.next(newState)
    },
    edit: async (id: number) => {
      debug('EDIT', id);
      stateChanges.next({ isLoading: true });
      const supplier = await services.supplierService.get(id);
      // const newStructure = await loadCategories(state.structure);

      let newState : EditSupplierState = {
        ...state,
        structure: formStructure,
        isLoading: false
      };

      // newState = setType(newState, productField);
      // newState = setIsValuesRestricted(newState, productField);
      // newState = await setIsSeparateListSpecified(newState, productField);

      newState.item = supplier;

      stateChanges.next(newState);
    },
    save: async () => {
      stateChanges.next({ isLoading: true });

      // this is unnecessary now, but it might be needed in the future, e.g. for processing logos
      const itemToSave = {
        ...state.item
      };

      debug('itemToSave', itemToSave);

      const itemSaveResult = await services.supplierService.create(itemToSave as Supplier);

      if (itemSaveResult.outcome === ItemSaveOutcome.businessValidationError) {
        stateChanges.next({ isLoading: false, errors: itemSaveResult.validationErrors });
      } else if (itemSaveResult.outcome === ItemSaveOutcome.created || itemSaveResult.outcome === ItemSaveOutcome.updated) {
        stateChanges.next({ isLoading: false });
      } else {
        stateChanges.next({ isLoading: false, errors: [{ description: 'Unknown error',  }] });
      }

      return itemSaveResult;
    },
    mutate: async (structure: FormStructure, item: Partial<Supplier>, categoryName: string, fieldName: string, fieldValue: any) => {
      debug('MUTATE', structure, item);

      const newState = await handler(state, item as Supplier, categoryName, fieldName as SupplierKey, fieldValue);

      debug('NEW STATE', newState);
    
      stateChanges.next(newState);
      return;
    }
  }
}

const logic = () => ({
  getInitialState: () => {
    const state: EditSupplierState = {
      structure: { ...formStructure },
      item: {
        displayName: '',
        logo: ''
      },
      schema: supplierSchema,
      isValid: true,
      isLoading: true,
      isSaving: false
    }
  
    return state;
  },
  createActions
})

export default logic;
