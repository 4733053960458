import _ from 'lodash';
import { Subject } from 'rxjs';
import createDebug from 'debug';
import productService from "../../../services/product-service";
import supplierService from "../../../services/supplier-service";
import ItemSaveResult from "../../../types/item-save-result";
import Product from '../../../types/product';
import { Supplier } from '../../../types/supplier';
import { Schema } from '../../../types/schema';
import schemaService from '../../../services/schema-service';
import ProductFieldPurpose from '../../../types/product-field-purpose';
import { FormStructure, FormField } from '../../../components/dynamic-form/types';
import createFormDefinition from '../../playground/create-form-structure';

const debug = createDebug('mk:edit-product:logic');

export type EditProductState = {
  structure?: FormStructure,
  item: Partial<Product>,
  suppliers: Supplier[],
  schema: Schema,
  isLoading: boolean,
  isSaving: boolean,
  isValid: boolean
}

export type Actions = {
  setSupplierId: (supplierId: number) => void,
  mutate: (structure: FormStructure, data: object) => Promise<void>,
  newItem: () => Promise<void>,
  edit: (id : number) => Promise<void>,
  save: () => Promise<ItemSaveResult<Product>>
}

const createActions = (stateChanges: Subject<Partial<EditProductState>>) => function handle(state: EditProductState) : Actions {
  return {
    newItem: async () => {
      debug('NEW ITEM');
      stateChanges.next({ isLoading: true });

      const suppliers = await supplierService.getAll();
      const schema = await schemaService.product(ProductFieldPurpose.core);
      
      const structure = await createFormDefinition();

      const newState = {
        ...state,
        structure,
        item: {
          data: { }
        },
        isValid: false,
        suppliers,
        isLoading: false,
        isSaving: false,
        schema
      }

      stateChanges.next(newState);
    },
    edit: async (id: number) => {
      debug('EDIT', id);
      stateChanges.next({ isLoading: true });

      // TODO: Fetch at the same time
      const product = await productService.get(id);
      const suppliers = await supplierService.getAll();
      const schema = await schemaService.product(ProductFieldPurpose.core);

      const structure = await createFormDefinition();

      debug('SCHEMA', schema);

      stateChanges.next({
        structure,
        item: product,
        schema,
        suppliers,
        isLoading: false
      });
    },
    save: async () => {
      stateChanges.next({ isLoading: true });

      const itemToSave = {
        ...state.item
      };

      if (!itemToSave.supplierId) {
        stateChanges.next({ isLoading: false });
      }

      delete itemToSave.supplier;
      
      const itemSaveResult = await productService.create(itemToSave as Product);

      stateChanges.next({ isLoading: false }); // TODO: This would send back some kind of save success / validation error
      return itemSaveResult;
    },
    setSupplierId: (supplierId: number) => {
      const newItem = {
        ...state.item,
        supplierId
      };

      stateChanges.next({
        ...state,
        item: newItem
      })
    },
    mutate: async (structure: FormStructure, data: object) => {
      debug('MUTATE', data);
      // const newItem = {
      //   ...state.item,
      //   ...item,
      //   data: { ...item.data }
      // };

      const newItem = {
        ...state.item,
        data
      }
      
      stateChanges.next({
        ...state,
        structure,
        item: newItem
      })
      return;
    }
  }
}

const logic = {
  getInitialState: () => {

    const item : Product = {
      id: 0,
      supplierId: 0,
      data: { }
    }

    const state: EditProductState = {
      item,
      schema: {},
      suppliers: [],
      isLoading: true,
      isSaving: false,
      isValid: true
    }
  
    return state;
  },
  createActions
}

export default logic;
