import React, { useEffect } from 'react';
import SaveButton from '../components/buttons/save-button';
import { useState } from 'react';
import Definition from '../types/edit-component-definition';
import { useParams, useHistory } from 'react-router-dom'; 
import TemplateProps from './template-props';
import { Button } from 'antd';

export default function createEditComponent<T>(definition: Definition<T>, createTemplate: () => React.ComponentType<TemplateProps<T>>) {
  const EditComponent = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const titleText = id === '-1'
                    ? `Add ${definition.itemTitle}`
                    : `Edit ${definition.itemTitle}`;
  
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [item, setItem] = useState<T>((definition.newObject as T));
  
    const save = async () => {
      setIsSaving(true);
      let newItem = item;
      if (definition.beforeSave) {
        newItem = definition.beforeSave(item);
      }

      await definition.saveItem(newItem);

      // onSave() -> parent would use history to redirect away
      history.push(`${definition.listingLink}`);
      setIsSaving(false);
    };

    const cancel = async () => {
      // onCancel() -> parent would use history to redirect away
      history.push(`${definition.listingLink}`);
    };
  
    useEffect(() => {
      const run = async () => {
        if (id !== '-1') {
          setIsLoading(true);
          const result = await definition.getItem(id);
          setItem(result);
          setIsLoading(false);
        } else {
          if (definition.newObject) {
            setItem( (definition.newObject as T));
          }
        }
      }
      run();
    }, [id]);
  
    const mutate = (replacement: Partial<T>) => {
      const newItem = {
        ...item,
        ...replacement
      };
  
      setItem(newItem);
    }

    const Template = createTemplate();
  
    return (
      <>
        <h1>{titleText}</h1>

        <Template
          item={item}
          mutate={mutate}
          isLoading={isLoading} />
  
        <SaveButton
          onClick={() => save()}
          isSaving={isSaving}
          isLoading={isLoading}
        />

        <Button
          type="link"
          onClick={() => cancel()}>Cancel</Button>
      </>
    );
  };

  return EditComponent;
}

