import React from 'react';
import { Radio } from 'antd';
import { FieldProps } from '../field-props';
import ValidationMessages from './validation-messages';

const debug = require('debug')('mk:dyn-form:radio-select');

export default class RadioSelect extends React.Component<FieldProps> {
  shouldComponentUpdate(nextProps: FieldProps) {
    return nextProps.value !== this.props.value
      || nextProps.field !== this.props.field
      || nextProps.validationState !== this.props.validationState
      || nextProps.isDisabled !== this.props.isDisabled;
  }

  render() {
    debug(`Radio - render ${this.props.field.name}`);

    const { field, value, validationState, isDisabled } = this.props;
    const { name, values, isLoading } = field;
    // const { isValid, messages } = validationState || {};

    if (!values) {
      throw new Error('No values supplied to radio group');
    }

    return (
      <React.Fragment key={name}>
        <Radio.Group
          disabled={isLoading || isDisabled}
          onChange={x => this.props.onChange(x.target.value)}
          value={value}
        >
          {values.map(opt => (
            <Radio
              key={opt.value}
              value={opt.value}
            >
              {opt.label}
            </Radio>
          ))}
        </Radio.Group>

        <ValidationMessages
          validationState={validationState}
        />
    </React.Fragment>
    )
  }
}