import React from 'react';
import styled from 'styled-components';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';

type Item = {
  name: string,
  displayName: string
}

type Props = {
  items: Item[];
  onAddItem: () => void;
  onChangeValue: (index: number, value: string) => void;
  onChangeDisplayText: (index: number, value: string) => void;
  onRemoveItem: (index: number) => void;
  isDisabled?: boolean;
}

const StyledItemsTable = styled.table`
  width: 100%;
  border: none;
`;

const StyledTh = styled.th`
  text-align: left;
  padding: 4px;
  border: none;
`;

const StyledTd = styled.td`
  text-align: left;
  padding: 4px;
  border: none;
`;

const StyledValueTd = styled(StyledTd)`
  width: 35%;
`;

const StyledDisplayTextTd = styled(StyledTd)`
  width: 65%;
`;

const StyledInput = styled.input`
  border: 1px solid lightgray;
  font-size: 14px;
  width: 100%;
  padding: 4px;
`;

export default function KeyValueList(props : Props) {
  const { items, onAddItem, onChangeValue, onChangeDisplayText, onRemoveItem, isDisabled } = props;

  if (!items || !Array.isArray(items)) {
    return <div></div>
  }

  return (
    <div>
      <fieldset disabled={isDisabled}>
        <StyledItemsTable>
          <thead>
            <tr>
              <StyledTh>Name</StyledTh>
              <StyledTh>Display Name</StyledTh>
            </tr>
          </thead>
          <tbody>
            {items.map((x, i) => (
              <tr>
                <StyledValueTd><StyledInput type="text" value={x.name} onChange={x => onChangeValue(i, x.target.value)} /></StyledValueTd>
                <StyledDisplayTextTd><StyledInput type="text" value={x.displayName} onChange={x => onChangeDisplayText(i, x.target.value)} /></StyledDisplayTextTd>
                <StyledTd>
                  <Tooltip title="Remove">
                    <Button
                      size="small"
                      type="primary"
                      shape="circle"
                      onClick={x => onRemoveItem(i)}
                      icon={<MinusOutlined />}
                    />
                  </Tooltip>
                </StyledTd>
              </tr>
            ))}
          </tbody>
        </StyledItemsTable>
      </fieldset>

      <Tooltip title="Add">
        <Button
          size="small"
          type="primary"
          onClick={x => onAddItem()}
          icon={<PlusOutlined />}>
          Add Item
        </Button>
      </Tooltip>
    </div>
  )
};
