import React from 'react';
// import TemplateProps from '../../support/template-props';
// import { Schema } from '../../types/schema';
// import SchemaForm from '../../components/schema-form';
// import schema from './supplier-schema';
import SmartDynamicForm from '../../components/dynamic-form/smart-dynamic-form';
import { Supplier } from '../../types/supplier';
import { EditSupplierState } from './edit/logic';

// type State = { };

interface Props {
  state: EditSupplierState,
  mutateAction: (...args: any[]) => void
};

export default class EditSupplierTemplate extends React.Component<Props> {
  render() {
    const { state, mutateAction } = this.props;

    return (
      <>
        {/* <SchemaForm<Supplier>
          schema={schema as unknown as Schema}
          value={item}
          onChange={x => mutate(x)}
          isLoading={isLoading}
        /> */}

      <SmartDynamicForm<Supplier>
        structure={state.structure}
        value={state.item as unknown as Supplier}
        onChange={mutateAction}
      />
      </>
    )
  }
};