import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

export const AUTH_DOMAIN = 'match-kernel.eu.auth0.com';
export const CLIENT_ID = 'fGobcp6vTn6SHCauFJiW601sfZUrk6iy';
export const REDIRECT_URI = window.location.origin;
export const AUDIENCE = 'https://matchkernel.com/api';

let client: Auth0Client;

export async function getAuthClient() {
  if (!client) {
    client = await createAuth0Client({
      domain: AUTH_DOMAIN,
      client_id: CLIENT_ID,
      audience: AUDIENCE,
      scope: 'manage:data-structure',
      responseType: 'id_token token',
      cacheLocation: 'localstorage'
    });
  }

  return client;
}