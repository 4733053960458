import React from 'react';
import Cleave from "cleave.js/react";
import styled from 'styled-components';
import numeral from 'numeral';
import createDebug from 'debug';
import { FieldProps } from '../field-props';

const debug = createDebug('mk:dyn-form:fields:currency');

type MultiplierMap = {
  [key: string]: number
}

const MULTIPLIERS : MultiplierMap = {
  'k': 1000,
  'm': 1000000
}

const StyledCleave = styled(Cleave)`
  border: 1px solid #d9d9d9;
  padding: 4px 8px;
  width: 90%;

  &:focus {
    outline: none;
  }
`;

export default class NumberField extends React.Component<FieldProps> {
  constructor(props: FieldProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(value: string) {
    const candidate = parseFloat(value);
    if (Number.isNaN(candidate) || value.endsWith('.')) {
      debug('isNaN');
      debug('onChange', value);
      this.props.onChange(value);
    } else {
      debug('is proper number');
      debug('onChange', candidate);
      this.props.onChange(candidate);
    }
  }

  handleKeyPress(key: any) {
    if (key && (key === 'k' || key === 'm')) {
      let num = numeral(this.props.value);
      const multiplier = MULTIPLIERS[key];
      const added = num.multiply(multiplier);
      this.props.onChange(added.value());
      return;
    }
  }

  shouldComponentUpdate(nextProps: FieldProps) {
    debug('nextProps.value', nextProps.value);

    return nextProps.value !== this.props.value
      || nextProps.field !== this.props.field
      || nextProps.validationState !== this.props.validationState
      || nextProps.isDisabled !== this.props.isDisabled;
  }

  render() {
    debug(`Number - render ${this.props.field.name}`);
    const { isDisabled } = this.props;

    return (
      <>
        <StyledCleave
          placeholder="0.00"
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            prefix: '£'
          }}
          value={this.props.value}
          onChange={e => this.handleChange(e.target.rawValue)}
          onKeyDown={e => this.handleKeyPress(e.key)}
          className="textbox"
          disabled={isDisabled}
        />
      </>
    )
  }
}