import _ from 'lodash';
import produce, { castDraft, setAutoFreeze } from 'immer';
import { Schema } from '../../../../types/schema';
import searchItemFieldSchema from '../../search-item-field-schema';
import FieldType from '../../../../types/field-type';
import { FormStructure } from '../../../../components/dynamic-form/types';
import formStructure from '../form-structure';
import SearchItemField from '../../../../types/search-item-field';
import {
  Action,
  MutateAction,
  IS_NOT_SEPARATE_LIST_SPECIFIED,
  IS_SEPARATE_LIST_SPECIFIED,
  MUTATE,
  SEPARATE_LISTS_LOADING,
  CATEGORIES_LOADING,
  CATEGORIES_LOAD_SUCCESS,
  CATEGORIES_LOAD_FAILURE
} from './actions';
import { EditItemState, FETCH_ITEM, FETCH_ITEM_SUCCESS, reducer as editReducer, StandardEditActions } from '../../../../support/edit-wrapper-v2/logic/reducer';
import { Category } from '../../../../types/category';

const debug = require('debug')('mk:edit-search-item-field:reducer');

export interface EditSearchItemFieldState extends EditItemState<SearchItemField> {
  structure: FormStructure,
  schema: Schema,
  isValid: boolean
}

export const initialState: EditSearchItemFieldState = {
  structure: { ...formStructure },
  item: {
    id: -1,
    name: '',
    displayName: '',
    type: FieldType.number,
    isMultiple: false,
    isRequired: false,
    isValuesRestricted: false,
    allowedValues: [],
    minLength: '',
    maxLength: '',
    minimum: '',
    maximum: ''
  },
  schema: searchItemFieldSchema,
  isValid: false,
  isLoading: true,
  isSaving: false,
  isSaveError: false,
  saveErrorMessage: '',
  isFinished: false,
  errors: []
}

const setType = (state: EditSearchItemFieldState): void => {
  const item = state.item as unknown as SearchItemField;
  const { isValuesRestricted } = state.item;

  switch (item.type) {
    case FieldType.number: {
      state.structure.categories.validation.fields.minLength.isVisible = false;
      state.structure.categories.validation.fields.maxLength.isVisible = false;
      state.structure.categories.validation.fields.minimum.isVisible = true;
      state.structure.categories.validation.fields.maximum.isVisible = true;
      state.structure.categories.validation.fields.pattern.isVisible = false;
      state.structure.categories.basic.fields.isMultiple.isVisible = true;
      state.structure.categories.validation.fields.allowedValues.isVisible = true && isValuesRestricted;
      state.structure.categories.validation.fields.allowedValuesListId.isVisible = true && isValuesRestricted;
      state.structure.categories.validation.fields.isSeparateListSpecified.isVisible = true && isValuesRestricted;
  
      state.item.minLength = '';
      state.item.maxLength = '';
      state.item.pattern = '';
      break;
    }
    case FieldType.string: {
      state.structure.categories.validation.fields.minLength.isVisible = true;
      state.structure.categories.validation.fields.maxLength.isVisible = true;
      state.structure.categories.validation.fields.minimum.isVisible = false;
      state.structure.categories.validation.fields.maximum.isVisible = false;
      state.structure.categories.validation.fields.pattern.isVisible = true;
      state.structure.categories.basic.fields.isMultiple.isVisible = true;
      state.structure.categories.validation.fields.allowedValues.isVisible = true && isValuesRestricted;
      state.structure.categories.validation.fields.allowedValuesListId.isVisible = true && isValuesRestricted;
      state.structure.categories.validation.fields.isSeparateListSpecified.isVisible = true && isValuesRestricted;
  
      state.item.minimum = '';
      state.item.maximum = '';
      break;
    }
    case FieldType.boolean: {
      state.structure.categories.validation.fields.minLength.isVisible = false;
      state.structure.categories.validation.fields.maxLength.isVisible = false;
      state.structure.categories.validation.fields.minimum.isVisible = false;
      state.structure.categories.validation.fields.maximum.isVisible = false;
      state.structure.categories.validation.fields.pattern.isVisible = false;
      state.structure.categories.validation.fields.isValuesRestrictedField.isVisible = false;
      state.structure.categories.basic.fields.isMultiple.isVisible = false;
      state.structure.categories.validation.fields.allowedValues.isVisible = false;
      state.structure.categories.validation.fields.allowedValuesListId.isVisible = false;
      state.structure.categories.validation.fields.isSeparateListSpecified.isVisible = false;
  
      state.item.minLength = '';
      state.item.maxLength = '';
      state.item.minimum = '';
      state.item.maximum = '';
      state.item.pattern = '';
      state.item.isMultiple = false;
      state.item.isValuesRestricted = false;
      state.item.allowedValues = [];
      break;
    }
  }
}

const setIsValuesRestricted = (state: EditSearchItemFieldState): void => {
  const item = state.item as unknown as SearchItemField;

  debug('item.isSeparateListSpecified', item.isSeparateListSpecified);

  if (!item.isValuesRestricted) {
    state.item.isSeparateListSpecified = null;
    state.item.allowedValuesListId = null;
  }

  debug('item.isValuesRestricted', item.isValuesRestricted);
  debug('item.isSeparateListSpecified', item.isSeparateListSpecified);

  state.structure.categories.validation.fields.allowedValues.isVisible = item.isValuesRestricted && !item.isSeparateListSpecified;
  state.structure.categories.validation.fields.allowedValuesListId.isVisible = item.isValuesRestricted && !!item.isSeparateListSpecified;
  state.structure.categories.validation.fields.isSeparateListSpecified.isVisible = item.isValuesRestricted;

  debug('state.structure.categories.validation.fields.allowedValues.isVisible', state.structure.categories.validation.fields.allowedValues.isVisible);
}

const setIsSeparateListSpecified = (state: EditSearchItemFieldState): void => {
  const item = state.item as unknown as SearchItemField;
  state.structure.categories.validation.fields.allowedValues.isVisible = !item.isSeparateListSpecified;
}

const setDisplayName = (state: EditSearchItemFieldState): void => {
  const item = state.item as unknown as SearchItemField;
  item.name = _.camelCase(item.displayName);
}

const reduceMutation = (state: EditSearchItemFieldState, action: MutateAction) => {
  switch (action.fieldName as keyof SearchItemField) {
    case 'type': {
      setType(state);
      break;
    }
    case 'isValuesRestricted': {
      setIsValuesRestricted(state);
      break;
    }
    case 'isSeparateListSpecified': {
      setIsSeparateListSpecified(state);
      break;
    }
    case 'displayName': {
      setDisplayName(state);
      break;
    }
  }
}

export const init = (state: EditSearchItemFieldState) => {
  const nextState = produce(state, draft => {
    setType(draft);
    setIsValuesRestricted(draft);
    setDisplayName(draft);
    return draft;
  });

  return nextState;
}

setAutoFreeze(false);

export function reducer(state: EditSearchItemFieldState, action: Action): EditSearchItemFieldState {
  debug('REDUCE', state, action);
  switch (action.type) {
    case MUTATE: {
      const nextState = produce(state, draft => {
        draft.item = action.val;
        draft.structure = action.structure;
        reduceMutation(draft, action);
        return draft;
      });
      return nextState;
    }
    case SEPARATE_LISTS_LOADING: {
      const nextState = produce(state, draft => {
        draft.structure.categories.validation.fields.allowedValuesListId.isVisible = true;
        draft.structure.categories.validation.fields.allowedValuesListId.isLoading = true;

        return draft;
      });
      return nextState;
    }
    case IS_SEPARATE_LIST_SPECIFIED: {
      const nextState = produce(state, draft => {
        draft.structure.categories.validation.fields.allowedValuesListId.isVisible = true;
        draft.structure.categories.validation.fields.allowedValuesListId.values = action.lists;
        draft.structure.categories.validation.fields.allowedValuesListId.isLoading = false;

        return draft;
      });
      return nextState;
    }
    case IS_NOT_SEPARATE_LIST_SPECIFIED: {
      const nextState = produce(state, draft => {
        draft.structure.categories.validation.fields.allowedValuesListId.isVisible = false;
        draft.item.allowedValuesListId = undefined;
        return draft;
      });
      return nextState;
    }
    case CATEGORIES_LOADING: {
      const nextState = produce(state, draft => {
        draft.structure.categories.basic.fields.category.isLoading = true;
        return draft;
      });
      return nextState;
    }
    case CATEGORIES_LOAD_SUCCESS: {
      const nextState = produce(state, draft => {
        draft.structure.categories.basic.fields.category.isLoading = false;
        draft.structure.categories.basic.fields.category.values = action.values.map((x: Category) => ({ value: x.name, label: x.displayName }));
        return draft;
      });
      return nextState;
    }
    case CATEGORIES_LOAD_FAILURE: {
      // TODO...
      return state;
    } 
    case FETCH_ITEM: {
      return produce(state, draft => {
        draft.structure.isDisabled = true;
        return draft;
      });
    }
    case FETCH_ITEM_SUCCESS: {
      const nextState = produce(state, draft => {
        draft.isLoading = false;
        draft.structure.isDisabled = false;
        draft.item = castDraft(action.value);
        setType(draft);
        setIsValuesRestricted(draft);
        setDisplayName(draft);
        
        return draft;
      });

      return nextState;
    }
    default: {
      return editReducer(state, action as StandardEditActions<SearchItemField>);
    }
  }
}
