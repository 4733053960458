import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { User } from '@auth0/auth0-spa-js';
import LoginButton from '../auth/login-button';
import LogoutButton from '../auth/logout-button';
import SignUpButton from '../auth/signup-button';
import PageId from '../types/page-id';
import { isRouteMatch } from './sitemap';

type Props = {
  isAuthenticated: boolean,
  user?: User
}

export default function AccountMenu({ isAuthenticated, user = {} }: Props) {
  const location = useLocation();

  const activePageId = Object.values(PageId).find(x => isRouteMatch(location.pathname, x)) as PageId;

  switch (true) {
    case isAuthenticated && activePageId !== PageId.onboard:
      return (
        <Menu mode="horizontal" theme="dark">
          <SubMenu title={<span><UserOutlined />{user!.email}</span>}>
            <Menu.Item key={PageId.profile}><Link to={PageId.profile}>Account</Link></Menu.Item>
            <Menu.Item key={PageId.triggerSignOut}><LogoutButton /></Menu.Item>
          </SubMenu>
        </Menu>
      )
    case isAuthenticated && activePageId === PageId.onboard: {
      return (
        <div style={{ paddingRight: '20px' }}>
          <LogoutButton />
        </div>
      );
    }
    default: {
      return (
        <div style={{ paddingRight: '20px' }}>
          <Menu mode="horizontal" theme="dark">
            <Menu.Item key={PageId.profile}><LoginButton /></Menu.Item>
            <Menu.Item key={PageId.triggerSignOut}><SignUpButton /></Menu.Item>
          </Menu>
        </div>
      )
    }
  }
}