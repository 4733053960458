import React from 'react';
import { Supplier } from '../../types/supplier';
import ListingComponent from '../../support/create-listing-component';
import services from '../../services';
import Definition from '../../types/listing-component-definition';
import { Link } from 'react-router-dom';

// const debug = require('debug')('mk:pages:suppliers');

const def : Definition<Supplier> = {
  singular: 'supplier',
  columns: [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (val, item) => (
        <Link to={`${def.editLink}/${item.id}`}>
          {val}
        </Link>
      )
    },
    // {
    //   title: 'Users',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (val, item) => (
    //     <Link to={`${urls.editSupplierUsers(item.id)}`}>
    //       Users
    //     </Link>
    //   )
    // }
  ],
  editLink: `/supplier`
}

type Props = {
}

const SuppliersIndex : React.FC<Props> = () => {
  return <>
    <h1>Suppliers</h1>
    <p>The suppliers are the companies whose products are part of your marketplace.</p>
    <ListingComponent
      definition={def}
      getAll={services.supplierService.getAll}
      deleteOne={services.supplierService.delete}
      isLoading={true}
    />
  </>
};

export default SuppliersIndex;
