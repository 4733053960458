import React from 'react';
import { Button } from 'antd';
import { Subject } from 'rxjs';
import createDebug from 'debug';
import SaveButton from '../components/buttons/save-button';
import Errors from './errors';
import ItemSaveResult from '../types/item-save-result';
import ItemSaveOutcome from '../types/item-save-outcome';
import notify from '../events/notify';
import EventCode from '../events/event-code';
import { Error } from './error';
import ButtonsContainer from '../components/layout/buttons-container';

const debug = createDebug('mk:EditWrapper');

interface Logic<TEntity, TState> {
  getInitialState: () => TState,
  createActions: (stateChanges: Subject<Partial<TState>>) => (state: TState) => Actions<TEntity>
}

interface Actions<TEntity> {
  newItem: () => Promise<void>,
  edit: (id: number) => Promise<void>,
  save: () => Promise<ItemSaveResult<TEntity>>,
  mutate: (...args: any[]) => Promise<void>
};

type TemplateProps<TEntity, TState> = {
  state: TState,
  mutateAction: (item: Partial<TEntity>) => void
}

type EditWrapperProps<TEntity, TState> = {
  titleText: string,
  itemTitle: string,
  helpText?: string | React.ReactNode,
  logic: Logic<TEntity, TState>,
  createTemplate: () => React.ComponentType<TemplateProps<TEntity, TState>>,
  listingLink: string,
  id: string,
  history: any
}

type EditComponentState<TEntity> = {
  item: Partial<TEntity>,
  isLoading: boolean,
  isSaving: boolean,
  errors?: Error[]
}

class EditWrapper<TEntity, TState extends EditComponentState<TEntity>> extends React.Component<EditWrapperProps<TEntity, TState>, TState> {
  stateChanges: Subject<Partial<TState>>;
  actions: (state: TState) => Actions<TEntity>;
  subscription: any;
  constructor(props : EditWrapperProps<TEntity, TState>) {
    super(props);
    this.stateChanges = new Subject();
    this.actions = props.logic.createActions(this.stateChanges);
    this.state = props.logic.getInitialState();
    this.mutateHandlerProxy = this.mutateHandlerProxy.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  mutateHandlerProxy(...args: any[]) {
    this.actions(this.state).mutate(...args);
  }

  async componentDidMount() {
    this.subscription = this.stateChanges.subscribe(state => {
      debug('STATE CHANGE', state);
      this.setState(state as TState);
    });

    const { id } = this.props;

    if (id !== '-1') {
      this.actions(this.state).edit(Number(id));
    } else {
      this.actions(this.state).newItem();
    }
  }

  componentWillMount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async save() {
    const saveResult = await this.actions(this.state).save();
    if (saveResult.outcome === ItemSaveOutcome.created || saveResult.outcome === ItemSaveOutcome.updated) {
      this.backToListing();
    } else {
      notify(EventCode.ITEM_SAVE_ERROR, saveResult);
    }    
  }

  cancel() {
    this.backToListing();
  }

  backToListing() {
    const { history, listingLink } = this.props;
    history.push(`${listingLink}`);
  }

  render() {
    const { createTemplate, id, itemTitle } = this.props;
    const { isSaving, isLoading } = this.state;

    const titleText = id === '-1'
                    ? `Add ${itemTitle}`
                    : `Edit ${itemTitle}`;
    
    const Template = createTemplate();
    
    return (
      <>
        <h1>{titleText}</h1>

        {this.props.helpText && <p>{this.props.helpText}</p>}

        <Errors
          errors={this.state.errors}
        />

        <Template
          state={this.state}
          mutateAction={this.mutateHandlerProxy}
        />

        <ButtonsContainer>
          <SaveButton
            onClick={() => this.save()}
            isSaving={isSaving}
            isLoading={isLoading}
          />

          <Button
            type="link"
            onClick={() => this.cancel()}>Cancel</Button>
          </ButtonsContainer>
      </>
    )
  }
};

export default EditWrapper;
