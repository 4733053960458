import React from 'react';
import styled from 'styled-components';

export const ContainerDiv = styled.div`
  font-size: 10px;
  display: flex;
  flex-direction: row;
`;

export const OpportunityFormWrapper = styled.div`
  width: 45%;
`;

export const MatchesContainer = styled.div`
  padding: 8px 4px;
  width: 55%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const colors = {
  exact: '#73d13d',
  near: '#ffc069',
  non: '#ff4d4f'
}

export const Results = styled.div<{ type: 'exact' | 'near' | 'non' }>`
  margin-bottom: 4px;
  border: 1px solid ${props => colors[props.type]};
  

  ${(props) => {
    switch (props.type) {
      case 'exact':
        return 'height: 50%;'
      case 'near':
        return 'height: 25%;'
      case 'non':
        return 'height: 25%;'
    }
  }}
`;

export const StyledDd = styled.dd`
  font-size: normal;
`;

export const StyledDt = styled.dt`
  font-size: bold;
  color: black;
`;

export const BoxHeader = styled.div<{ type: 'exact' | 'near' | 'non' }>`
  padding: 4px;
  color: white;
  font-weight: bold;
  background-color: ${props => colors[props.type]};
`;

export const HelpText = styled.div<{ isVisible: boolean }>`
  display: ${props => props.isVisible ? 'block' : 'none'};
  padding: 4px 0;
`;
