import React from 'react';
import styled from 'styled-components';
import { WarningTwoTone } from '@ant-design/icons';
import { Popover } from 'antd';
import { ValidationState } from '../types';

const debug = require('debug')('mk:dyn-form:validation-messages');

type Props = {
  validationState?: ValidationState,
}

const StyledWarningTwoTone = styled(WarningTwoTone)`
  padding: 0 0 0 4px;
  width: 10%;
`;

const WarningIndicator = ({ isValid }: { isValid: boolean }) => (
  <StyledWarningTwoTone
    twoToneColor="#ff7a45"
    style={{ visibility: isValid ? 'hidden' : 'visible' }}
  />
)

const ValidationErrorsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

export default class ValidationMessages extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    debug('current validationState', this.props.validationState);
    debug('next validationState', nextProps.validationState);
    const shouldUpdate = nextProps.validationState !== this.props.validationState;
    debug('shouldUpdate', shouldUpdate);
    return shouldUpdate;
  }

  render() {
    debug(`ValidationMessages - render`);
    const { validationState } = this.props;

    if (!validationState) {
      return <></>;
    }

    const validationMessages = (
      <ValidationErrorsList>
        {validationState.messages.map((message, i) => <li key={i}>{message}</li>)}
      </ValidationErrorsList>
    )

    return (
      <React.Fragment>
        <Popover content={validationMessages} title="Problems">
          <WarningIndicator
            isValid={validationState.isValid}
          />
        </Popover>
    </React.Fragment>
    )
  }
}