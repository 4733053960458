import React from 'react';
import ListingComponent from '../../support/create-listing-component';
import services from '../../services';
import { Link } from 'react-router-dom';
import { CategoryGroup } from '../../types/category';

const def = {
  singular: 'category',
  columns: [
    {
      title: 'Type',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (val : any, item: any) => (
        <Link to={`categories/${item.type}`}>
          {val}
        </Link>
      )
    },
    // {
    //   title: 'Number of items',
    //   dataIndex: 'numItems',
    //   key: 'numItems',
    //   render: (val: any, record : List) => {
    //     return record.values.length
    //   }
    // },
  ],
  editLink: (item: CategoryGroup) => `/categories/${item.type}`
}

type Props = {
};

class CategoriesIndex extends React.Component<Props> {
  async getCategoryGroups() : Promise<CategoryGroup[]> {
    const categoryGroups : CategoryGroup[] = [
      { id: 1, type: 'product_field', displayName: 'Product Field Categories', values: [] },
      { id: 2, type: 'search_item_field', displayName: 'Search Item Field Categories', values: [] }
    ]
  
    return categoryGroups;
  }

  render() {
    return (
      <>
        <h1>Categories</h1>

        <ListingComponent
          definition={def}
          getAll={this.getCategoryGroups}
          deleteOne={services.listService.delete}
          isLoading={true}
          isDeleteNotSupported={true}
          isAddButtonHidden={true}
        />
      </>
    );
  }  
}

export default CategoriesIndex;
