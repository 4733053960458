import urls from './urls';
import { Schema } from '../types/schema';
// import backoff from "../util/retry";

// TODO: CHANGE THIS. Not supposed to use local storage
const addAuth = (requestInfo: any) : any => {
  const token = window.localStorage.getItem('token');
  const headers = {
    ...requestInfo.headers,
    authorization: `Bearer ${token}`
  }

  const newInfo = {
    ...requestInfo,
    headers
  }

  return newInfo;
}

export default {
  create: async (paymentMethodId: string) : Promise<Schema> => {
    let requestInfo : any = {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ paymentMethodId })
    };

    requestInfo = addAuth(requestInfo);
    
    const response = await fetch(`${urls.api()}/subscriptions`, requestInfo); // backoff(10, () => fetch(`${urls.api()}/schema`, requestInfo));
    const data = await response.json();

    return data;
  }
}