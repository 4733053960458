import { FormStructure } from "../../../components/dynamic-form/types";
import schema from '../search-item-field-schema';

const form : FormStructure = {
  validationSummary: {
    map: {}
  },
  categories: {
    basic: {
      name: 'basic',
      title: 'Identification',
      isDisabled: false,
      isVisible: true,
      fields: {
        displayName: {
          name: 'displayName',
          title: 'Display Name',
          description: "The display name is the label that appears next to the field in the product editor. It's a friendly name for the field.",
          type: 'text',
          isMultiple: false,
          isRequired: true,
          schema: schema.properties['displayName']
        },
        name: {
          name: 'name',
          title: 'Name',
          description: "The name is the system name for the field. It will be returned in the results from the matching API.",
          type: 'text',
          isMultiple: false,
          isRequired: true,
          schema: schema.properties['name']
        },
        type: {
          name: 'type',
          title: 'Type',
          type: 'select',
          description: 'The data type of the field. This sets what type of values the field is used for.',
          values: [
            { value: 'string', label: 'Text' },
            { value: 'number', label: 'Number' },
            { value: 'integer', label: 'Integer' },
            { value: 'boolean', label: 'Checkbox (Yes/No)' }
          ],
          isMultiple: false,
          schema: schema.properties['type']
        },
        isMultiple: {
          name: 'isMultiple',
          title: 'Multiple values',
          type: 'check',
          isDisabled: false,
          isMultiple: false,
          schema: schema.properties['isMultiple']
        },
        category: {
          name: 'category',
          title: 'Category',
          type: 'select',
          isDisabled: false,
          isMultiple: false,
          schema: schema.properties['category']
        }
      }
    },
    validation: {
      name: 'validation',
      title: 'Validation',
      isDisabled: false,
      isVisible: true,
      fields: {
        isRequired: {
          name: 'isRequired',
          title: 'Required',
          type: 'check',
          isDisabled: false,
          isMultiple: false,
          schema: { type: 'boolean' }
        },
        isValuesRestricted: {
          name: 'isValuesRestricted',
          title: 'Restrict Values',
          type: 'check',
          isDisabled: false,
          isMultiple: false,
          schema: { type: 'boolean' }
        },
        isSeparateListSpecified: {
          name: 'isSeparateListSpecified',
          title: 'Use data source',
          type: 'check',
          isDisabled: false,
          isMultiple: false,
          schema: { type: 'boolean' }
        },
        allowedValuesListId: {
          name: 'allowedValuesListId',
          title: 'Data Source',
          type: 'select',
          isLoading: true,
          values: [],
          isDisabled: false,
          isMultiple: false,
          schema: { type: 'number' }
        },
        allowedValues: {
          name: 'allowedValues',
          title: 'Allowed Values',
          type: 'text',
          isLoading: false,
          values: [],
          isDisabled: false,
          isMultiple: true,
          schema: { type: 'string' }
        },
        minLength: {
          name: 'minLength',
          title: 'Min Length',
          type: 'number',
          isDisabled: false,
          isMultiple: false,
          isVisible: true,
          schema: schema.properties['minLength']
        },
        maxLength: {
          name: 'maxLength',
          title: 'Max Length',
          type: 'number',
          isDisabled: false,
          isMultiple: false,
          isVisible: true,
          schema: schema.properties['maxLength']
        },
        minimum: {
          name: 'minimum',
          title: 'Minimum',
          type: 'number',
          isDisabled: false,
          isMultiple: false,
          schema: schema.properties['minimum']
        },
        maximum: {
          name: 'maximum',
          title: 'Maximum',
          type: 'number',
          isDisabled: false,
          isMultiple: false,
          schema: schema.properties['maximum']
        },
        pattern: {
          name: 'pattern',
          title: 'Pattern',
          type: 'text',
          isDisabled: false,
          isMultiple: false,
          schema: schema.properties['category']
        }
      }
    }
  }
}

export default form;






// import { FormStructure } from "../../../components/dynamic-form/types";
// import schema from '../search-item-field-schema';

// const form : FormStructure = {
//   validationSummary: {
//   },
//   categories: {
//     basic: {
//       name: 'basic',
//       title: 'Identification',
//       isDisabled: false,
//       isVisible: true,
//       validationState: {
//         isDirty: false,
//         isValid: true,
//         messages: []
//       },
//       fields: {
//         displayName: {
//           name: 'displayName',
//           title: 'Display Name',
//           type: 'text',
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['displayName']
//         },
//         name: {
//           name: 'name',
//           title: 'Name',
//           type: 'text',
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['name']
//         },
//         type: {
//           name: 'type',
//           title: 'Type',
//           type: 'select',
//           values: [
//             { value: 'string', label: 'Text' },
//             { value: 'number', label: 'Number' },
//             { value: 'integer', label: 'Integer' },
//             { value: 'boolean', label: 'Checkbox (Yes/No)' }
//           ],
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['type']
//         },
//         isMultiple: {
//           name: 'isMultiple',
//           title: 'Multiple values',
//           type: 'check',
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: { type: 'boolean' }
//         },
//         category: {
//           name: 'category',
//           title: 'Category',
//           type: 'select',
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['category']
//         },
//       }
//     },
//     validation: {
//       name: 'validation',
//       title: 'Validation',
//       isDisabled: false,
//       isVisible: true,
//       validationState: {
//         isDirty: false,
//         isValid: true,
//         messages: []
//       },
//       fields: {
//         isRequired: {
//           name: 'isRequired',
//           title: 'Required',
//           type: 'check',
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: { type: 'boolean' }
//         },
//         isValuesRestricted: {
//           name: 'isValuesRestricted',
//           title: 'Restrict Values',
//           type: 'check',
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: { type: 'boolean' }
//         },
//         isSeparateListSpecified: {
//           name: 'isSeparateListSpecified',
//           title: 'Use data source',
//           type: 'check',
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: { type: 'boolean' }
//         },
//         allowedValuesListId: {
//           name: 'allowedValuesListId',
//           title: 'Data Source',
//           type: 'select',
//           isLoading: true,
//           values: [],
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: { type: 'boolean' }
//         },
//         allowedValues: {
//           name: 'allowedValues',
//           title: 'Allowed Values',
//           type: 'text',
//           isLoading: false,
//           values: [],
//           isDisabled: false,
//           isMultiple: true,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: { type: 'boolean' }
//         },
//         minLength: {
//           name: 'minLength',
//           title: 'Min Length',
//           type: 'number',
//           isDisabled: false,
//           isMultiple: false,
//           isVisible: true,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['minLength']
//         },
//         maxLength: {
//           name: 'maxLength',
//           title: 'Max Length',
//           type: 'number',
//           isDisabled: false,
//           isMultiple: false,
//           isVisible: true,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['maxLength']
//         },
//         minimum: {
//           name: 'minimum',
//           title: 'Minimum',
//           type: 'number',
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['minimum']
//         },
//         maximum: {
//           name: 'maximum',
//           title: 'Maximum',
//           type: 'number',
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['maximum']
//         },
//         pattern: {
//           name: 'pattern',
//           title: 'Pattern',
//           type: 'text',
//           isDisabled: false,
//           isMultiple: false,
//           validationState: {
//             isDirty: false,
//             isValid: true,
//             messages: []
//           },
//           schema: schema.properties['category']
//         }
//       }
//     }
//   }
// }

// export default form;
