import React from 'react';
import DumbEditTemplate from './edit-template/dumb-2';
import logic, { EditProductFieldState } from './edit-template/logic';
import PageId from '../../types/page-id';
import EditWrapper from '../../support/EditWrapper';
import ProductField from '../../types/product-field';
import ProductFieldPurpose from '../../types/product-field-purpose';

type Props = {
  id: string,
  purpose: ProductFieldPurpose,
  history: any
}

const listingLinks : any = {
  [ProductFieldPurpose.core]: PageId.productDefinitionCore,
  [ProductFieldPurpose.common]: PageId.productDefinitionCommon,
}

const EditOrdering : React.FC<Props> = ({ id, purpose, history }) => {
  const listingLink : string = listingLinks[purpose];

  return (
    <EditWrapper<ProductField, EditProductFieldState>
      titleText="Product Field"
      itemTitle="product field"
      logic={logic(purpose)}
      createTemplate={() => DumbEditTemplate}
      listingLink={listingLink}
      id={id}
      history={history}
    />
  )
};

export default EditOrdering;
