import React from 'react';
import { message } from 'antd';

const ErrorDetail = ({ message, detail } : { message: string, detail: any }) => (
  <div>
    <strong>{message}</strong>
    <div>{JSON.stringify(detail)}</div>
  </div>
);

export default (detail: any) => message.error(<ErrorDetail message="Error when saving" detail={detail} />);