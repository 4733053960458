import React from 'react';
import { Actions } from './logic/actions';
import { EditSearchItemFieldState } from './logic/reducer';
import SmartDynamicForm from '../../../components/dynamic-form/smart-dynamic-form';
import SearchItemField from '../../../types/search-item-field';
import { TemplateProps } from '../../../support/edit-wrapper-v2/edit-wrapper-v2';

const Template : React.FunctionComponent<TemplateProps<SearchItemField, EditSearchItemFieldState, Actions>> = ({ state, actions }) => {
  return (
    <>
      <SmartDynamicForm<SearchItemField>
        structure={state.structure}
        value={state.item as unknown as SearchItemField}
        onChange={(...args) => actions.mutate(...args)}
      />
    </>
  )
}

export default Template;