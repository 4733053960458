import React, { useState, useEffect } from 'react';
import { notification, Modal } from 'antd';
import createDebug from 'debug';
import OpportunityForm from './opportunity-form';
import ProductMatches from './product-matches';
import Product from '../../types/product';
import { ProductWithMatchResult } from '../../types/results';
import SearchItem from '../../types/search-item';
import matchingEngine from '../../services/matching-engine';
import SearchItemField from '../../types/search-item-field';
import searchItemFieldService from '../../services/search-item-field-service';
import productFieldService from '../../services/product-field-service';
import Help from '../../components/forms/help';
import { BoxHeader, ContainerDiv, HelpText, MatchesContainer, OpportunityFormWrapper, Results, StyledDd, StyledDt } from './components';

const debug = createDebug('mk:dummyui:app');

const flatten = (result : ProductWithMatchResult) => ({
  supplierDisplayName: result.product.supplier.displayName,
  ...result.product,
  ...result.product.data,
  matchType: result.matchType,
  categories: result.categories,
  codes: result.codes
});

type ProductFieldMap = {
  [key: string]: string;
}

type CategoryToFieldMap = {
  [key: string]: string[];
}

const renderValue = (val: any) => {
  if (Array.isArray(val)) {
    return <span>{val.join(', ')}</span>;
  } else {
    return <span>{val}</span>;
  }
}

// .ant-select {
//   line-height: 10px;
// }

// .ant-select-item-option-content {
//   font-size: 10px;
// }

const Main : React.FC<{}> = () => {
  const [isMatchesLoading, setIsMatchesLoading] = useState(false);
  const [matches, setMatches] = useState<Product[]>([]);
  const [nearMatches, setNearMatches] = useState<any>([]);
  const [nonMatches, setNonMatches] = useState<any>([]);
  const [matchCount, setMatchCount] = useState(0);
  const [nearMatchCount, setNearMatchCount] = useState(0);
  const [nonMatchCount, setNonMatchCount] = useState(0);
  const [searchItemFields, setSearchItemFields] = useState<SearchItemField[]>([]);
  const [inspectedProduct, setInspectedProduct] = useState<Product>();
  const [isProductInspecting, setIsProductInspecting] = useState<boolean>(false);
  const [productFields, setProductFields] = useState<ProductFieldMap>({});
  const [categoryToFieldMap, setCategoryToFieldMap] = useState<CategoryToFieldMap>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    const run = async () => {
      const searchItemFields = await searchItemFieldService.getAll();
      setSearchItemFields(searchItemFields);

      const productFields = await productFieldService.getAllSorted();

      const productFieldMap: ProductFieldMap = {};
      const categoryToFieldMap: CategoryToFieldMap = {};

      for (const pf of productFields) {
        productFieldMap[pf.name] = pf.displayName;

        let category = 'Uncategorised';
        if (pf.category) {
          category = pf.category;
        }

        if (!categoryToFieldMap[category]) {
          categoryToFieldMap[category] = [];
        }

        categoryToFieldMap[category].push(pf.name);
      }

      setCategoryToFieldMap(categoryToFieldMap);
      setProductFields(productFieldMap);
    };
    run();
  }, []);
  
  const getMatchingProducts = async (opportunity: SearchItem) => {
    debug(opportunity);

    const mergedOpportunity: SearchItem = {
      ...opportunity
    };

    setIsMatchesLoading(true);
    // setIsDirty(true);

    try {
      const data = await matchingEngine.matches(mergedOpportunity);

      // @ts-ignore
      const matches = data.matches.results.map(flatten);

      // flatten data
      // @ts-ignore
      const nearMatches = data.nearMatches.results.map(flatten);

      // flatten data
      // @ts-ignore
      const nonMatches = data.nonMatches.results.map(flatten);

      debug('nonMatches', nonMatches);

      // @ts-ignore
      setMatches(matches);
      setMatchCount(data.matches.count);

      setNearMatches(nearMatches);
      setNearMatchCount(data.nearMatches.count);

      setNonMatches(nonMatches);
      setNonMatchCount(data.nonMatches.count);
    } catch (e) {
      notification.error({
        message: 'Error when calling the matching engine',
        description:
          `Status code: ${JSON.stringify(e)}. Error: ${e.body} ${e}`,
        onClick: () => {
        },
      });

      setMatches([]);
      setMatchCount(0);

      setNearMatches([]);
      setNearMatchCount(0);
    }
    
    setIsMatchesLoading(false);
  }

  const categoryNames = Object.keys(categoryToFieldMap);

  return (
    <>
      <Modal
        title={`Product Details (product id: ${inspectedProduct ? inspectedProduct.id : ''})`}
        centered
        visible={isProductInspecting}
        onOk={() => setIsProductInspecting(false)}
        onCancel={() => setIsProductInspecting(false)}
      >
        {categoryNames.map((catName: string, index: number) => (
          <div key={`category-${index}`}>
            <h1>{catName}</h1>
            <dl>
              {categoryToFieldMap[catName].map((fieldName: string, fieldIndex: number) => (
                <React.Fragment key={`field-${fieldIndex}`}>
                  <StyledDt>{productFields[fieldName]}</StyledDt>
                  <StyledDd>{inspectedProduct ? renderValue(inspectedProduct.data[fieldName]) || '-' : ''}</StyledDd>
                </React.Fragment>
              ))}
            </dl>
          </div>
        ))}
      </Modal>
      <ContainerDiv>
        <OpportunityFormWrapper>
          <h1>Search Item</h1>
          <HelpText isVisible={!isDirty}>
            This is where you can test out your rules to make sure they are working as expected
          </HelpText>
          <OpportunityForm
            onDirty={() => setIsDirty(true)}
            onFinish={getMatchingProducts}
            searchItemFields={searchItemFields}
          />
        </OpportunityFormWrapper>

        <MatchesContainer>
          <Results type="exact">
            <BoxHeader type="exact">Matches {matchCount}</BoxHeader>
            <div>
              <ProductMatches
                problemColumnTitle={<span>Unchecked rules <Help helpText="These rules haven't been checked because the search item data hasn't been supplied" /></span>}
                height={35}
                results={matches}
                isLoading={isMatchesLoading}
                onInspectProduct={(product) => {
                  setInspectedProduct(product);
                  setIsProductInspecting(true);
                }}
              />
            </div>
          </Results>

          <Results type="near">
            <BoxHeader type="near">Near Matches {nearMatchCount}</BoxHeader>
            <div>
              <ProductMatches
                problemColumnTitle="Near match rules"
                height={12}
                results={nearMatches}
                isLoading={isMatchesLoading}
                onInspectProduct={(product) => {
                  setInspectedProduct(product);
                  setIsProductInspecting(true);
                }}
              />
            </div>
          </Results>

          <Results type="non">
            <BoxHeader type="non">Near Matches {nonMatchCount}</BoxHeader>
            <div>
              <ProductMatches
                problemColumnTitle="Non-matched rules"
                height={12}
                results={nonMatches}
                isLoading={isMatchesLoading}
                onInspectProduct={(product) => {
                  setInspectedProduct(product);
                  setIsProductInspecting(true);
                }}
              />
            </div>
          </Results>
        </MatchesContainer>
      </ContainerDiv>
    </>
  );
};

export default Main;
