export default {
  api: () : string => {
    if (window.localStorage.getItem('isUsingLocalApi') === 'true') {
      return 'http://localhost:4000';
    } else {
      if (!process.env.REACT_APP_API_URL) {
        throw new Error('API URL not defined');
      }
      return process.env.REACT_APP_API_URL;
    }
  }
}