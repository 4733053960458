import Field from "../../../../types/field";
import FieldType from "../../../../types/field-type";

const convertAllowedValuesToEnclosingType = (item: Field): void => {
  if (!Array.isArray(item.allowedValues)) {
    return;
  }

  if (item.type === FieldType.number) {
    for (let i = 0; i < item.allowedValues.length; i++) {
      const candidateValue = Number(item.allowedValues[i]);
      if (Number.isNaN(candidateValue)) {
        item.allowedValues.splice(i, 1);
      } else {
        item.allowedValues[i] = Number(item.allowedValues[i]);
      }
    }
  }
}

export default convertAllowedValuesToEnclosingType;
