import React from 'react';
import { Input, Select, Spin, Checkbox } from 'antd';
import FormItem from '../../../components/forms/form-item';
import Condition from '../../../types/condition';
import SelectConditionType from './select-condition-type';
import { EditConditionState } from './logic';
import Examples from './examples';

const { Option } = Select;

interface EditConditionDumbTemplateProps {
  state: EditConditionState,
  mutateAction: (item: Partial<Condition>) => void
};

const Template : React.FunctionComponent<EditConditionDumbTemplateProps> = ({ state, mutateAction }) => {
  const item = state.item as Condition;

  return (
    <>
      <FormItem
        label="Display Name"
      >
        <Input
          value={item.displayName}
          disabled={state.isLoading}
          onChange={x => mutateAction({ displayName: x.target.value })} />
      </FormItem>

      <FormItem
        label="Code"
      >
        <Input
          value={item.code}
          disabled={state.isLoading}
          onChange={x => mutateAction({ code: x.target.value })} />
      </FormItem>

      <FormItem
        label="Category"
      >
        <Input
          value={item.category}
          disabled={state.isLoading}
          onChange={x => mutateAction({ category: x.target.value })} />
      </FormItem>

      <FormItem
        label="Product Field"
      >
        <Select
          value={item.productFieldId}
          style={{ width: '100%' }}
          notFoundContent={state.isFetchingReferenceData ? <Spin size="small" /> : null}
          onChange={x => mutateAction({ productFieldId: x })}>
          {state.productFields.map(d => (
            <Option key={d.id} value={d.id}>{d.displayName}  ({d.name})</Option>
          ))}
        </Select>
      </FormItem>

      <FormItem
        label="Search Item Field"
      >
        <Select
          value={item.searchItemFieldId}
          style={{ width: '100%' }}
          notFoundContent={state.isFetchingReferenceData ? <Spin size="small" /> : null}
          onChange={x => mutateAction({ searchItemFieldId: x })}>
          {state.searchItemFields.map(d => (
            <Option key={d.id} value={(d.id as number)}>{d.displayName} ({d.name})</Option>
          ))}
        </Select>
      </FormItem>

      <FormItem
        label="Type"
      >
        <SelectConditionType state={state} mutateAction={mutateAction} />
      </FormItem>

      <FormItem
        label="Empty Product Value Matches"
      >
        <Checkbox
          checked={item.isEmptyProductValueMatches}
          onChange={e => mutateAction({ isEmptyProductValueMatches: e.target.checked })}
        />
      </FormItem>

      <FormItem
        label="Examples"
      >
        <Examples
          productFields={state.productFields}
          searchItemFields={state.searchItemFields}
          item={item}
        />
      </FormItem>

      <FormItem
        label="Enabled"
      >
        <Checkbox
          checked={item.isEnabled}
          onChange={e => mutateAction({ isEnabled: e.target.checked })}
        />
      </FormItem>
    </>
  )
};

export default Template;