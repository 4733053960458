import SearchItemField from '../../../../types/search-item-field';
import { FormStructure } from '../../../../components/dynamic-form/types';
import { EditorActions, createActions as createEditorActions, StandardEditActions } from '../../../../support/edit-wrapper-v2/logic/reducer';
import services from '../../../../services';
import toUndefinedOrNumber from '../../../../util/to-undefined-or-number';
import convertAllowedValuesToEnclosingType from './convert-allowed-values-to-enclosing-type';
import { Category } from '../../../../types/category';

const debug = require('debug')('mk:edit-search-item-field:actions');

export const MUTATE = 'MUTATE';
export const CHANGE_IS_SEPARATE_LIST_SPECIFIED = 'CHANGE_IS_SEPARATE_LIST_SPECIFIED';
export const SEPARATE_LISTS_LOADING = 'SEPARATE_LISTS_LOADING';
export const IS_SEPARATE_LIST_SPECIFIED = 'IS_SEPARATE_LIST_SPECIFIED';
export const IS_NOT_SEPARATE_LIST_SPECIFIED = 'IS_NOT_SEPARATE_LIST_SPECIFIED';
export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATEGORIES_LOAD_SUCCESS = 'CATEGORIES_LOAD_SUCCESS';
export const CATEGORIES_LOAD_FAILURE = 'CATEGORIES_LOAD_FAILURE';

export interface MutateAction {
  type: typeof MUTATE,
  structure: FormStructure,
  val: SearchItemField,
  categoryName: string,
  fieldName: string,
  fieldValue: any
}

interface ChangeIsSeparateListSpecified {
  type: typeof CHANGE_IS_SEPARATE_LIST_SPECIFIED, 
  value: boolean
}

interface SeparateListsLoading {
  type: typeof SEPARATE_LISTS_LOADING
}

interface IsSeparateListSpecified {
  type: typeof IS_SEPARATE_LIST_SPECIFIED,
  lists: { value: number, label: string }[]
}

interface IsNotSeparateListSpecified {
  type: typeof IS_NOT_SEPARATE_LIST_SPECIFIED
}

interface CategoriesLoading {
  type: typeof CATEGORIES_LOADING
}

interface CategoriesLoadSuccess {
  type: typeof CATEGORIES_LOAD_SUCCESS,
  values: Category[]
}

interface CategoriesLoadFailure {
  type: typeof CATEGORIES_LOAD_FAILURE,
  error?: any
}

export type Action = StandardEditActions<SearchItemField> |
  MutateAction |
  ChangeIsSeparateListSpecified |
  SeparateListsLoading |
  IsSeparateListSpecified |
  IsNotSeparateListSpecified |
  CategoriesLoading |
  CategoriesLoadSuccess |
  CategoriesLoadFailure;


export interface Actions extends EditorActions<SearchItemField> {
  mutate: (structure: FormStructure, val: SearchItemField, categoryName: string, fieldName: string, fieldValue: any) => Promise<void>
}

export const changeIsSeparateListSpecified = async (dispatch: React.Dispatch<Action>, value: boolean) : Promise<void> => {
  if (value) {
    dispatch({
      type: SEPARATE_LISTS_LOADING
    });

    const lists = await services.listsService.getAll();
    const mapped = lists.map(x => ({ value: x.id, label: x.displayName }));

    dispatch({
      type: IS_SEPARATE_LIST_SPECIFIED,
      lists: mapped
    });
  } else {
    dispatch({
      type: IS_NOT_SEPARATE_LIST_SPECIFIED
    });
  }
}

const loadCategories = async (dispatch: React.Dispatch<Action>) : Promise<void> => {
  dispatch({
    type: CATEGORIES_LOADING
  });

  try {
    const categoryGroup = await services.categoriesService.getByType('search_item_field');

    debug('loadCategories - categoryGroup', categoryGroup);

    dispatch({
      type: CATEGORIES_LOAD_SUCCESS,
      values: categoryGroup.values
    });
  } catch (e) {
    dispatch({
      type: CATEGORIES_LOAD_FAILURE,
      error: e
    });
  }
}

export const createActions = (dispatch: React.Dispatch<Action>): Actions => {
  const save = async (item: SearchItemField) => {
    
    const itemToSave = {
      ...item,
      minimum: toUndefinedOrNumber(item.minimum),
      maximum: toUndefinedOrNumber(item.maximum),
      minLength: toUndefinedOrNumber(item.minLength),
      maxLength: toUndefinedOrNumber(item.maxLength)
    };

    convertAllowedValuesToEnclosingType(item);

    return services.searchItemFieldService.create(itemToSave);
  }

  const getOne = async (id: number) => {
    const result = await services.searchItemFieldService.get(id);

    if (result.isSeparateListSpecified) {
      changeIsSeparateListSpecified(dispatch, true);
    }

    return result;
  }

  const setup = async () => {
    return loadCategories(dispatch);
  }
  
  const actions : Actions = {
    ...createEditorActions<SearchItemField>(dispatch, {
      setup,
      getOne,
      save
    }),
    mutate: async (structure: FormStructure, val: SearchItemField, categoryName: string, fieldName: string, fieldValue: any) => {
      switch (fieldName as keyof SearchItemField) {
        case 'isSeparateListSpecified': {
          dispatch({
            type: MUTATE,
            structure,
            val,
            categoryName,
            fieldName,
            fieldValue
          })
          await changeIsSeparateListSpecified(dispatch, fieldValue);
          break;
        }
        default: {
          debug('raising MUTATE...');
          dispatch({
            type: MUTATE,
            structure,
            val,
            categoryName,
            fieldName,
            fieldValue
          })
        }
      }
    }
  }

  return actions;
};

// const TEMP__MOCK_LIST_SERVICE = {
//   getAll: async () => {
//     return [
//       { id: 1, displayName: 'List One' }
//     ]
//   }
// }
