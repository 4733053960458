import { Subject, Subscription } from 'rxjs';
import { produce } from 'immer';
import categoriesService from '../../../services/categories-service';
import productFieldService from '../../../services/product-field-service';
import _ from 'lodash';
import ItemSaveOutcome from '../../../types/item-save-outcome';

const debug = require('debug')('mk:product-fields:reorder-product-fields');

enum EventType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export type UserNotification = {
  type: EventType,
  message: string
}

export type Actions = {
  edit: () => Promise<void>,
  onFieldSortEnd: (categoryName: string, fields: ReorderField[]) => Promise<void>,
  onCategoriesSortEnd: (categories: ReorderCategory[]) => Promise<void>,
  save: () => Promise<void>
}

type ReorderField = {
  id: number,
  displayName: string,
  index: number
}

type ReorderCategory = {
  displayName: string,
  name: string,
  index: number,
  fields: ReorderField[],
  fieldOrder: FieldOrderMap
}

// id -> index
type FieldOrderMap = {
  [id: number]: number
}

type CategoryMap = {
  [key: string]: ReorderCategory
}

export type State = {
  categories: CategoryMap,
  isLoading: boolean,
  isSaving: boolean,
  isSaveSuccess: boolean
}

const assignIndex = (fields: ReorderField[]) => {
  return fields.length;
}

const logic = {
  createActions: (stateChanges: Subject<State>, notifications: Subject<UserNotification>) => {
    return (state: State) : Actions => ({
      edit: async () => {
        stateChanges.next({
          isLoading: true,
          isSaving: false,
          categories: state.categories,
          isSaveSuccess: false
        })

        const catetgoryGroup = await categoriesService.getByType('product_field');
        const rawCategories = catetgoryGroup.values;

        const productFields = await productFieldService.getAll();

        const categories : CategoryMap = {};
        for (const category of rawCategories) {
          let index = category.index;

          if (index === undefined) {
            index = Object.keys(categories).length;
          }

          categories[category.name] = {
            ...category,
            index,
            fields: []
          }
        }

        for (const field of productFields) {
          const categoryName = field.category || 'Uncategorised';
          const category = categories[categoryName] || { displayName: 'Uncategorised', name: 'uncategorised', fields: [] };
          const fieldOrder = category.fieldOrder || {};
          let index = fieldOrder[field.id];

          const reorderField: ReorderField = { ...field, index };
          if (!categories[categoryName]) {
            categories[categoryName] = {
              displayName: 'Uncategorised',
              name: 'uncategorised',
              index: 0,
              fields: [],
              fieldOrder: {}
            }
          }

          categories[categoryName].fields.push(reorderField);

          // if there is no index defined, assign one
          if (index === undefined) {
            index = assignIndex(categories[categoryName].fields);
            reorderField.index = index;
          }
        }

        for (const category of Object.values(categories)) {
          category.fields = _.sortBy(category.fields, ['index']);
        }

        stateChanges.next({
          isLoading: false,
          isSaving: false,
          isSaveSuccess: false,
          categories
        })
      },
      onFieldSortEnd: async (categoryName: string, fields: ReorderField[]) => {
        const newState = produce(state, draft => {
          draft.categories[categoryName].fields = fields;
        });

        stateChanges.next(newState);
      },
      onCategoriesSortEnd: async (categories: ReorderCategory[]) => {
        const newState = produce(state, draft => {         
          for (let i = 0; i < categories.length; i++) {
            debug('finding category ', categories[i].name);
            draft.categories[categories[i].name].index = i;
          }
        });

        stateChanges.next(newState);
      },
      save: async () => {
        stateChanges.next({
          ...state,
          isSaving: true          
        })

        const categoryGroup = await categoriesService.getByType('product_field');

        const categoryValues = Object.values(state.categories);

        const sortedCategoties = _.sortBy(categoryValues, ['index']);

        for (let i = 0; i < sortedCategoties.length; i++) {
          const category = sortedCategoties[i];

          const fieldOrder : FieldOrderMap = {};
          category.fields.reduce((prev: FieldOrderMap, curr: ReorderField, index: number) => {
            prev[curr.id] = index;
            return prev;
          }, fieldOrder);

          const savedCategory = categoryGroup.values.find(x => x.name === category.name);
          if (savedCategory) {
            savedCategory.fieldOrder = fieldOrder;
            savedCategory.index = i;
          }
        }

        const saveResult = await categoriesService.create(categoryGroup);

        if (saveResult.outcome === ItemSaveOutcome.updated || saveResult.outcome === ItemSaveOutcome.created) {
          stateChanges.next({
            ...state,
            isSaving: false
          })

          notifications.next({
            type: EventType.success,
            message: 'Sort order saved successfully'
          })
        }
      }
    })
  },
  getInitialState: () => {
    return {
      categories: {},
      isLoading: true,
      isSaving: false,
      isSaveSuccess: false
    }
  }
}

export default logic;
