import React from "react";
import styled from 'styled-components';
import authClient from './auth-client';

const debug = require('debug')('mk:components:logout-button');

type Props = {};
type State = {
  isLoading: boolean
}

const StyledLinkButton = styled.button`
  font-size: 1em;
  color: rgba(0, 0, 0, 0.65);
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;

  &:focus {
    outline:0;
    outline: none;
  }

  &:hover {
    color: #1890ff;
  }
`;

export default class LogoutButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true
    }

    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  async logout() {
    debug('logging out...');
    authClient.logout();
    debug('logged out.');
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>
    }
    return (
      <StyledLinkButton
        onClick={() => this.logout()}>Sign out
      </StyledLinkButton>
    )
  }
}
