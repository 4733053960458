/* eslint-disable */
import React from 'react';
import createDebug from 'debug';
import { debounce } from 'rxjs/operators';
import { Subject, interval, Subscription } from 'rxjs';
import SearchItem from '../../types/search-item';
import SearchItemField from '../../types/search-item-field';
import { Schema } from '../../types/schema';
import SmartDynamicForm from '../../components/dynamic-form/smart-dynamic-form';
import createFormStructure from './create-form-structure';
import { FormStructure } from '../../components/dynamic-form/types';

const debug = createDebug('mk:opportunity-form');

type OpportunityFormProps = {
  onFinish (opp : Partial<SearchItem>) : void,
  searchItemFields: SearchItemField[],
  onDirty: () => void
}

const initialValues : Partial<SearchItem> = {
}

type State = {
  item: Partial<SearchItem>;
  isLoading: boolean;
  structure?: FormStructure;
}

class OpportunityForm extends React.Component<OpportunityFormProps, State> {
  private stateChanges : Subject<Partial<SearchItem>>;
  private subscription? : Subscription;

  constructor(props : OpportunityFormProps) {
    super(props);
    this.postStateChange = this.postStateChange.bind(this);
    this.stateChanges = new Subject();
    this.subscription = undefined;
  
    this.state = {
      item: {},
      isLoading: false
    };
  }

  async componentDidMount() {
    const structure = await createFormStructure();
    this.setState({ structure });

    this.stateChanges.subscribe(state => {
      this.setState({ item: state });
    });

    const throttledChanges = this.stateChanges.pipe(
      debounce(() => interval(500))
    );

    this.subscription = throttledChanges.subscribe(x => {
      this.props.onFinish({ ...this.state.item });
    });

    // this.postStateChange({ ...this.state.item });
    this.props.onFinish({ ...this.state.item });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  postStateChange(values: Partial<SearchItem>) {
    this.props.onDirty();
    this.stateChanges.next(values);
  }

  render() {
    debug('render');
    if (!this.state.structure) {
      return <div>Loading...</div>
    }

    return (
      <>
        <SmartDynamicForm
          structure={this.state.structure}
          value={this.state.item}
          onChange={(structure, value) => this.postStateChange(value)}
        />
      </>
    )
  }
}

export default OpportunityForm;
