import EventCode from './event-code';
import displayError from './display-error';
import displaySuccess from './display-success';
import redirectToLoginPage from './redirect-to-login-page';

const allHandlers : any  = {
  [EventCode.ITEM_SAVE_ERROR]: [displayError],
  [EventCode.ITEM_SAVED_SUCCESSFULLY]: [displaySuccess],
  [EventCode.ITEM_DELETED_SUCCESSFULLY]: [displaySuccess],
  [EventCode.ITEM_NOT_DELETED_SUCCESSFULLY]: [displayError],
  [EventCode.USER_NOT_LOGGED_IN]: [redirectToLoginPage]
}

const notify = (event : string, detail: any) => {
  const handlers = allHandlers[event];

  if (handlers && Array.isArray(handlers)) {
    for (const handler of handlers) {
      if (handler) {
        handler(detail)
      }
    }
  }
}

export default notify;