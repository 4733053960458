import React from 'react';
// import cognitoUtils from '../../auth/cognito/cognito-utils';

const Home = () => (
  <>
    {/* <h1>Login</h1>

    <div>
      <p>You are not logged in.</p>

      <p>
        <a data-testid="sign-in-link" className=".sign-in-link" href={cognitoUtils.getCognitoSignInUri()}>Sign in</a>
      </p>

      <p>
        <a className=".sign-up-link" href={cognitoUtils.getCognitoSignUpUri()}>Sign up</a>
      </p>
    </div> */}
  </>
);

export default Home;