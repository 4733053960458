import React from 'react';
import produce, { castDraft } from 'immer';
import ItemSaveResult from '../../../types/item-save-result';
import ItemSaveOutcome from '../../../types/item-save-outcome';
import { Error } from '../../error';

export const NEW_ITEM = 'edit-wrapper/NEW_ITEM';
export const EDIT_ITEM = 'edit-wrapper/EDIT_ITEM';
export const FETCH_ITEM = 'edit-wrapper/FETCH_ITEM';
export const FETCH_ITEM_SUCCESS = 'edit-wrapper/FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_FAILURE = 'edit-wrapper/FETCH_ITEM_FAILURE';
export const SAVE_ITEM = 'edit-wrapper/SAVE_ITEM';
export const SAVE_ITEM_SUCCESS = 'edit-wrapper/SAVE_ITEM_SUCCESS';
export const SAVE_ITEM_FAILURE = 'edit-wrapper/SAVE_ITEM_FAILURE';
export const CANCEL = 'edit-wrapper/CANCEL';

export type EditItemAction = {
  type: typeof EDIT_ITEM,
  id: number
}

export type NewItemAction = {
  type: typeof NEW_ITEM
}

export type FetchItemAction = {
  type: typeof FETCH_ITEM
}

export type FetchItemSuccessAction<TEntity> = {
  type: typeof FETCH_ITEM_SUCCESS,
  value: TEntity
}

export type FetchItemFailureAction = {
  type: typeof FETCH_ITEM_FAILURE,
  error: any
}

export type SaveItemAction = {
  type: typeof SAVE_ITEM
}

export type SaveItemSuccessAction<TEntity> = {
  type: typeof SAVE_ITEM_SUCCESS,
  value: TEntity
}

export type SaveItemFailureAction = {
  type: typeof SAVE_ITEM_FAILURE,
  error: any
}

export type CancelAction = {
  type: typeof CANCEL
}

export type StandardEditActions<TEntity> = NewItemAction
  | EditItemAction
  | FetchItemAction
  | FetchItemSuccessAction<TEntity>
  | FetchItemFailureAction
  | SaveItemAction
  | SaveItemSuccessAction<TEntity>
  | SaveItemFailureAction
  | CancelAction;

export type Definition<TEntity> = {
  setup?: () => Promise<void>,
  getOne: (id: number) => Promise<TEntity>,
  save: (item: TEntity) => Promise<ItemSaveResult<TEntity>>
}

export type EditorActions<TEntity> = {
  edit: (id: string) => Promise<void>;
  save: (item: TEntity) => Promise<void>;
  cancel: () => Promise<void>;
}

export const createActions = <TEntity>(dispatch: React.Dispatch<StandardEditActions<TEntity>>, definition: Definition<TEntity>) : EditorActions<TEntity> => ({
  edit: async (id: string) => {
    if (id === '-1') {
      dispatch({
        type: NEW_ITEM
      });

      if (definition.setup) {
        await definition.setup();
      }
    } else {
      dispatch({
        type: FETCH_ITEM
      });

      try {
        const promises = [];

        if (definition.setup) {
          promises.push(definition.setup());
        }

        // const item = await definition.getOne(Number(id));
        
        promises.push(definition.getOne(Number(id)));
        const [, item] = await Promise.all<any>(promises);
  
        dispatch({
          type: FETCH_ITEM_SUCCESS,
          value: item
        });
      } catch (e) {
        dispatch({
          type: FETCH_ITEM_FAILURE,
          error: e
        });
      }
    }
  },
  save: async (item: TEntity) => {
    dispatch({
      type: SAVE_ITEM
    });

    try {
      const saveResult = await definition.save(item);

      if (saveResult.outcome === ItemSaveOutcome.created || saveResult.outcome === ItemSaveOutcome.updated) {
        dispatch({
          type: SAVE_ITEM_SUCCESS,
          value: item
        });
      } else {
        dispatch({
          type: SAVE_ITEM_FAILURE,
          error: saveResult.error
        });
      }
    } catch (e) {
      dispatch({
        type: SAVE_ITEM_FAILURE,
        error: e
      });
    }
  },
  cancel: async () => {
    dispatch({
      type: CANCEL
    })
  }
})

export type EditItemState<TEntity> = {
  isValid: boolean,
  isLoading: boolean,
  isSaving: boolean,
  item: TEntity,
  isSaveError: boolean,
  saveErrorMessage: string,
  isFinished: boolean,
  errors: Error[]
}

export function getInitialState(): EditItemState<{}> {
  return {
    item: {},
    isValid: true,
    isLoading: true,
    isSaving: false,
    isSaveError: false,
    saveErrorMessage: '',
    isFinished: false,
    errors: []
  }
}

export function reducer<TState extends EditItemState<TEntity>, TEntity extends { id?: number }>(state: TState, action: StandardEditActions<TEntity>): TState {
  const result = produce(state, draft => {
    switch (action.type) {
      case FETCH_ITEM: {
        draft.isLoading = true;
        return draft;
      }
      case FETCH_ITEM_SUCCESS: {
        draft.isLoading = false;
        draft.item = castDraft(action.value);
        return draft;
      }
      case FETCH_ITEM_FAILURE: {
        draft.isLoading = false;
        return draft;
      }
      case NEW_ITEM: {
        draft.isLoading = false;
        draft.item.id = undefined;
        return draft;
      }
      case SAVE_ITEM: {
        draft.isSaving = true;
        return draft;
      }
      case SAVE_ITEM_SUCCESS: {
        draft.isSaving = false;
        draft.item = castDraft(action.value);
        draft.isFinished = true;
        return draft;
      }
      case SAVE_ITEM_FAILURE: {
        draft.isSaving = false;
        draft.isSaveError = true;
        draft.saveErrorMessage = action.error;
        return draft;
      }
      case CANCEL: {
        draft.isFinished = true;
        return draft;
      }
    }
  })

  return result as TState;
}



// type MutationFn<TState, TAction> = (state: TState, action: TAction) => void; // Produced<TState, Draft<TState>>;

// export type MutationList<TState> = {
//   [FETCH_ITEM]: MutationFn<TState, FetchItemFailureAction>,
//   [FETCH_ITEM_SUCCESS]: MutationFn<TState, FetchItemSuccessAction<any>>,
//   [FETCH_ITEM_FAILURE]: MutationFn<TState, FetchItemFailureAction>,
//   [SAVE_ITEM]: MutationFn<TState, SaveItemAction>,
//   [SAVE_ITEM_SUCCESS]: MutationFn<TState, SaveItemSuccessAction<any>>,
//   [SAVE_ITEM_FAILURE]: MutationFn<TState, SaveItemFailureAction>
// }

// function createMutations<TEntity, TState extends EditItemState<TEntity>>() : MutationList<TState> {
//   return {
//     [FETCH_ITEM]: (state, action) => {
//       state.isLoading = true;
//     },
//     [FETCH_ITEM_SUCCESS]: (state, action) => {
//       state.isLoading = false;
//       state.item = action.value;
//     },
//     [FETCH_ITEM_FAILURE]: (state, action) => {
//       state.isLoading = false;
//     },
//     [SAVE_ITEM]: (state, action) => {
//       state.isSaving = true;
//     },
//     [SAVE_ITEM_SUCCESS]: (state, action) => {
//       state.isSaving = false;
//       state.item = action.value;
//     },
//     [SAVE_ITEM_FAILURE]: (state, action) => {
//       state.isLoading = false;
//     }
//   }
// }

// function inheritedReducer(state, action) {
//   switch (action.type) {
//     case FETCH_ITEM: {
//     }
//     default: {
//     }
//   }
// }
