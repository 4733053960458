enum PageId {
  root = '/',
  home = '/home',
  productDefinitionCore = '/product-definition/core',
  productDefinitionCommon = '/product-definition/common',
  editProductField = '/product-fields/:purpose/:id',
  reorderProductFields = '/product-fields/reorder',
  searchItemDefinition = '/search-item-definition',
  editSearchItemField = '/search-item-fields/:id',
  conditions = '/conditions-index',
  editCondition = '/conditions/:id',
  products = '/products-index',
  productsCommon = '/products-common-index',
  productsEditCommon = '/products-common/:id',
  editProduct = '/products/:id',
  playground = '/playground',
  callback = '/callback',
  triggerSignOut = '/triggerSignOut',
  signOut = '/signout',
  account = '/account',
  suppliers = '/suppliers',
  editSupplier = '/supplier/:id',
  supplierUsers = '/supplier/:supplierId/users',
  lists = '/lists',
  editList = '/lists/:id',
  categories = '/categories',
  editCategories = '/categories/:type',
  subscription = '/subscription',
  profile = '/profile',
  login = '/login',
  apiAccess = '/api-access',
  onboard = '/onboard',
  clients = '/clients',
  editClient = '/clients/:id'
}

export default PageId;
