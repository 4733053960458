import React from 'react';
import { Select } from 'antd';
import Condition, { ConditionType } from '../../../types/condition';
import ProductField from '../../../types/product-field';
import SearchItemField from '../../../types/search-item-field';
import { EditConditionState } from './logic';

const { Option } = Select;

type ConditionTypeProps = {
  state: EditConditionState,
  mutateAction: (value: Partial<Condition>) => void
}

type IEnumToProp<R> = {[key in keyof typeof ConditionType]: R };

type RendererFn = (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => any;

const renderers : IEnumToProp<RendererFn> = {
  [ConditionType.GTE]                      : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.GTE}>Greater Than or Equal ({searchItemField.name} {'>='} {productField.name})</Option>,
  [ConditionType.LTE]                      : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.LTE}>Less Than or Equal ({searchItemField.name} {'<='} {productField.name})</Option>,
  [ConditionType.EQ]                       : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.EQ}>Equal ({searchItemField.name} {'='} {productField.name})</Option>,
  [ConditionType.ITEM_EXISTS_IN_ARRAY]     : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.ITEM_EXISTS_IN_ARRAY}>Product field ({productField.name}) contains item ({searchItemField.name})</Option>,
  [ConditionType.ITEM_NOT_EXISTS_IN_ARRAY] : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.ITEM_NOT_EXISTS_IN_ARRAY}>Product field ({productField.name}) does not contain item ({searchItemField.name})</Option>,
  [ConditionType.SOME_ITEMS_EXIST_IN_ARRAY]: (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.SOME_ITEMS_EXIST_IN_ARRAY}>Some values of ({searchItemField.name}) exist in ({productField.name})</Option>,
  [ConditionType.NO_ITEMS_EXIST_IN_ARRAY]  : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.NO_ITEMS_EXIST_IN_ARRAY}>Not one value of ({searchItemField.name}) exists in ({productField.name})</Option>,
  [ConditionType.ALL_ITEMS_EXIST_IN_ARRAY]: (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.ALL_ITEMS_EXIST_IN_ARRAY}>All items in ({searchItemField.name}) exist in ({productField.name})</Option>,
  [ConditionType.ARRAYS_ARE_IDENTICAL] : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.ALL_ITEMS_EXIST_IN_ARRAY}>All items in ({searchItemField.name}) exist in ({productField.name})</Option>,
  [ConditionType.GEO_COORDS_WITHIN]        : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.GEO_COORDS_WITHIN}>({searchItemField.name}) is near to ({productField.name})</Option>,
  [ConditionType.GEO_POSTCODE_WITHIN]      : (type: ConditionType, searchItemField: SearchItemField, productField: ProductField) => <Option value={ConditionType.GEO_POSTCODE_WITHIN}>Postcode ({searchItemField.name}) is near to ({productField.name})</Option>,
};

export default ({ state, mutateAction } : ConditionTypeProps) => {
  const { productField, searchItemField } = state.item;

  if (! (productField && searchItemField)) {
    return (
      <Select
        disabled={true}
        style={{ width: '100%' }}
        value="Select a product field and search item field...">
      </Select>
    );
  }

  return (
    <Select value={state.item.type} style={{ width: '100%' }} onChange={x => mutateAction({ type: x })} disabled={state.isConditionTypeDisabled}>
      {state.types.map(x => renderers[x](x, searchItemField, productField))}
    </Select>
  );
};
