import React from 'react';
import arrayMove from 'array-move';
import SortableTable from './sortable-table';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { OrderMap } from '../../../types/category';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const DragHandleInner = styled(MenuOutlined)`
  cursor: move;
  color:#999;
  padding: 0 10px;
`;

const DragHandle = SortableHandle(() => <DragHandleInner />);

type OnSortEndArgs = { oldIndex: number, newIndex: number };

type Field = {
  id: number,
  displayName: string,
  index: number
}

type Category = {
  name: string,
  displayName: string,
  index: number,
  fields: Field[],
  fieldOrder: OrderMap
}

type Props = {
  categories: Category[],
  onFieldSortEnd: (categoryName: string, fields: Field[]) => void;
  onCategoriesSortEnd: (categories: Category[]) => void;
}

type ItemProps = {
  category: Category,
  onFieldSortEnd: (categoryName: string, fields: Field[]) => void;
}

const DraggableCategory = styled.div`
  padding: 10px 5px;
  margin-bottom: 10px;
  border: 1px solid rgb(238, 238, 238);

  h2 {
    padding: 10px 0 20px 0;
  }
`;

const SortableItem = SortableElement(({ category, onFieldSortEnd } : ItemProps) => (
  <DraggableCategory>
    <h2>
      <DragHandle />
      {category.displayName}
    </h2>
    <SortableTable fields={category.fields} onSortEnd={fields => onFieldSortEnd(category.name, fields)} />
  </DraggableCategory>
))

const SortableCategories = SortableContainer(({ categories, onFieldSortEnd }: any) => {
  return (
    <div>
      {categories.map((category: Category, index: number) => (
        <SortableItem
          key={`category-${category.name}`}
          index={index}
          category={category}
          onFieldSortEnd={onFieldSortEnd}
        />
      ))}
    </div>
  )
});

class DumbReorderProductFields extends React.Component<Props> {
  onSortEnd = ({ oldIndex, newIndex }: OnSortEndArgs) => {
    // const dataSource: Field[] = this.state.dataSource;
    const dataSource: Category[] = this.props.categories;
    if (oldIndex !== newIndex) {
      // @ts-ignore
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter((el) => !!el) as Category[];

      this.props.onCategoriesSortEnd(newData);
    }
  };

  render() {
    const { categories, onFieldSortEnd } = this.props;
    return (
      <SortableCategories
        categories={categories}
        onFieldSortEnd={onFieldSortEnd}
        onSortEnd={this.onSortEnd}>
      </SortableCategories>
    )
  }
}

export default DumbReorderProductFields;
