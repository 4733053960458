import React from 'react';
import styled from 'styled-components';
import { FieldProps } from '../field-props';
import ValidationMessages from './validation-messages';

const debug = require('debug')('mk:dyn-form:fields:text');

const Readonly = styled.div`
      padding: 4px 8px;
`;

const StyledInput = styled.input<{ isValid: boolean }>`
  border: 1px solid #d9d9d9;
  padding: 4px 8px;
  width: 90%;
  background-color: ${props => !props.isValid ? '#ffccc7' : '#fff'};

  &:focus {
    outline: none;
  }
`;

type WithOnKeyDown = {
  onKeyDown?: (event: any) => void;
  shouldAutoFocus?: boolean;
}

export default class TextField extends React.Component<FieldProps & WithOnKeyDown> {
  inputRef?: HTMLInputElement | null = undefined;

  changeValue(value: string) {
    this.props.onChange(value);
  }

  componentDidMount() {
    if (this.props.shouldAutoFocus) {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    }
  }

  shouldComponentUpdate(nextProps: FieldProps) {
    const shouldUpdate = nextProps.value !== this.props.value
      || nextProps.field !== this.props.field
      || nextProps.validationState !== this.props.validationState
      || nextProps.isDisabled !== this.props.isDisabled;
    
    debug(`shouldUpdate - ${this.props.field.name}`, shouldUpdate);
    return shouldUpdate;
  }

  focus() {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  }

  render() {
    debug(`DumbText - render ${this.props.field.name}`);

    const { field, value, validationState, isDisabled } = this.props;
    const { name, schema } = field;
    const { isValid } = validationState || { isValid: true };

    const component = schema.readOnly && schema.readOnly === true
                    ? <Readonly>{value}</Readonly>
                    : <StyledInput
                        disabled={isDisabled}
                        type="text"
                        isValid={isValid}
                        onChange={x => this.changeValue(x.target.value)}
                        value={value}
                        onKeyDown={this.props.onKeyDown}
                        ref={(input) => this.inputRef = input}
                      />

    return (
      <React.Fragment key={name}>
        
        {component}

        <ValidationMessages
          validationState={validationState}
        />
    </React.Fragment>
    )
  }
}