export enum SchemaType {
  string = 'string',
  number = 'number',
  object = 'object',
  array = 'array',
  boolean = 'boolean'
}

export type SubSchema = {
  [key : string]: Schema
};

export type ConditionalSection = {
  properties: SubSchema;
};

export type Schema = {
  type?: SchemaType | string;
  title?: string;
  minLength?: number;
  maxLength?: number;
  minimum?: number;
  maximum?: number;
  properties?: SubSchema;
  items?: Schema;
  enum?: string[] | number[];
  required?: string[];
  additionalProperties?: boolean;
  const?: string | number | boolean; // not sure if this is correct, but it's enough for now.
  pattern?: string;
  if?: ConditionalSection;
  then?: ConditionalSection;
  else?: ConditionalSection;
  readOnly?: boolean;
}