import { FormStructure } from "../../../components/dynamic-form/types";
import userDetailsSchema from '../user-details/user-details-schema';
import accountDetailsSchema from '../account-details/account-schema';

const form : FormStructure = {
  validationSummary: {
  },
  categories: {
    userDetails: {
      name: 'userDetails',
      title: 'User Details',
      isDisabled: false,
      isVisible: true,
      fields: {
        firstName: {
          name: 'firstName',
          title: 'First Name',
          type: 'text',
          isMultiple: false,
          isRequired: true,
          schema: userDetailsSchema.properties['firstName']
        },
        lastName: {
          name: 'lastName',
          title: 'Last Name',
          type: 'text',
          isMultiple: false,
          isRequired: true,
          schema: userDetailsSchema.properties['lastName']
        }
      }
    },
    accountDetails: {
      name: 'accountDetails',
      title: 'Account Details',
      isDisabled: false,
      isVisible: true,
      fields: {
        companyName: {
          name: 'companyName',
          title: 'Company Name',
          description: "The name of your company or business",
          type: 'text',
          isMultiple: false,
          isRequired: true,
          schema: accountDetailsSchema.properties['companyName']
        }
      }
    }
  }
}

export default form;
