import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Skeleton, Tag } from 'antd';
import schemaService from '../../../services/schema-service';
import { generate } from './generate-example';

// TODO: Use Faker on the server to generate an example
type Props = {
}

const ExampleTextArea = styled.textarea`
  height: 200px;
  width: 100%;
  font-size: 10px;
`;

// TODO: Use react-syntax-highlighter
// https://react-syntax-highlighter.github.io/react-syntax-highlighter/demo/
const ApiEndpointDetails : React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [example, setExample] = useState<string>();

  useEffect(() => {
    const run = async () => {
      const schema = await schemaService.searchItem();

      const exampleObj = generate(schema);
      const exampleStr = JSON.stringify(exampleObj, null, 2);

      setExample(exampleStr);

      setIsLoading(false);
    }

    run();
  }, []);

  if (isLoading) {
    return <>
      <h2>API Endpoint Details</h2>
      <Skeleton />
    </>
  }

  return (
    <>
      <h2>API Endpoint Details</h2>
      <p>
        <code>
          <Tag color="blue">POST</Tag> {process.env.REACT_APP_API_URL}/matches
        </code>
      </p>

      <div>
        <h2>Body Example</h2>
        <ExampleTextArea
          value={example}
          readOnly
        />
      </div>
    </>
  )
}

export default ApiEndpointDetails;
