import React from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const debug = require('debug')('mk:components:help');

type Props = {
  helpText?: string
}

const HelpDiv = styled.div<Props>`
  padding: 5px 6px 0 0;
  display: inline;
  visibility: ${props => !!props.helpText ? 'visible' : 'hidden'};
`;

const HelpText: React.FC<Props> = ({ helpText }) => {
  debug('HelpText', helpText);
  return (
    <HelpDiv helpText={helpText}>
      <Popover content={<div>{helpText}</div>}>
        <QuestionCircleOutlined />
      </Popover>
    </HelpDiv>
  )
}

export default React.memo(HelpText, (prevProps, nextProps) => prevProps.helpText === nextProps.helpText);
