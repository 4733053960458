import React from 'react';
import createDebug from 'debug';
import FormItem from '../forms/form-item';
import { FormCategory, FormField } from './types';
import factory from './factory';
import MultipleItemsField from './fields/multiple-items/multiple-items';

const debug = createDebug('mk:dyn-form:field-set');

type Indexable = {
  [key: string]: any;
}

type Props<T> = {
  categoryName: string,
  structure: FormCategory,
  value: T,
  isDisabled?: boolean,
  onChange: (categoryTitle: string, name: string, value: T) => void
}

export default class DynamicForm<T extends Indexable> extends React.Component<Props<T>> {
  constructor(props : Props<T>) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.formComponentFactory = this.formComponentFactory.bind(this);
  }

  shouldComponentUpdate(nextProps : Props<T>) {
    const propsChanged = this.props.structure !== nextProps.structure;

    const oldValue = this.props.value;
    const newValue = nextProps.value;

    const fieldNames = Object.keys(this.props.structure.fields);

    let valueChanged = false;
    for (const name of fieldNames) {
      if (oldValue[name] !== newValue[name]) {
        valueChanged = true;
        break;
      }
    }

    const shouldUpdate = propsChanged || valueChanged;

    debug('FieldSet shouldUpdate:', shouldUpdate);

    return shouldUpdate;
  }

  onChange(categoryName: string, name: string, value: any) {
    this.props.onChange(categoryName, name, value);
  }

  // TODO: Could this just generate a list when the structure changes, then just insert the new values as and when needed?
  formComponentFactory(categoryName: string, field: FormField, value: any, isDisabled: boolean) {
    debug('creating component for', field);

    // memoise using field and value
    if (field.isMultiple) {
      return (
        <MultipleItemsField
          field={field}
          value={value}
          validationState={field.validationState}
          onChange={v => this.onChange(categoryName, field.name, v)}
          isDisabled={isDisabled}
        />
      );
    }

    return factory(field, value, v => this.onChange(categoryName, field.name, v), {}, field.validationState, isDisabled);
  }

  render() {
    const { value, categoryName, structure } = this.props;
    const isFormDisabled = this.props.isDisabled;
    const { title, fields } = structure;
    const isCategoryDisabled = structure.isDisabled;

    debug('Render fieldset ', title);
    debug('fields', fields);

    const fieldNames = Object.keys(fields);

    return (
      <div key={`field-set-${categoryName}`} className="field-set">
        <h2>{title}</h2>
        {fieldNames.map(fieldName => {
          const val = value[fieldName];

          debug(`${fieldName} - field`, fields[fieldName]);

          const isDisabled = fields[fieldName].isDisabled || isCategoryDisabled || isFormDisabled;

          if (fields[fieldName].hasOwnProperty('isVisible') && fields[fieldName].isVisible === false) {
            return <React.Fragment key={fieldName}></React.Fragment>;
          }

          const fieldComponent = this.formComponentFactory(categoryName, fields[fieldName], val, isDisabled);
          return (
            <FormItem
              key={fieldName}
              label={fields[fieldName].title}
              helpText={fields[fieldName].description}
              isRequired={fields[fieldName].isRequired}
              isDisabled={isDisabled}
            >
              {fieldComponent}
            </FormItem>
          )
        })}
      </div>
    )
  }
}
