import React, { useEffect, useState } from 'react';
import createEditComponent from '../../support/create-edit-component';
import productCommonValuesService from '../../services/product-common-values';
import Definition from '../../types/edit-component-definition';
import PageId from '../../types/page-id';
import TemplateProps from '../../support/template-props';
// import SchemaForm from '../../components/schema-form';
import { Schema } from '../../types/schema';
import schemaService from '../../services/schema-service';
import ProductFieldPurpose from '../../types/product-field-purpose';
import BIGDEBUG from '../../util/bigdebug';
import { CommonProductValues } from '../../types/common-product-values';
import FormItem from '../../components/forms/form-item';
import { Select } from 'antd';
import { Supplier } from '../../types/supplier';
import supplierService from '../../services/supplier-service';

const { Option } = Select;

type Props = TemplateProps<CommonProductValues>;

const Template : React.FunctionComponent<Props> = ({ item, mutate, isLoading }) => {
  const [schema, setSchema] = useState<Schema>();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  useEffect(() => {
    const run = async () => {
      const schema = await schemaService.product(ProductFieldPurpose.common);
      const supps = await supplierService.getAll();

      setSuppliers(supps);

      BIGDEBUG('schema', schema);
      setSchema(schema);
    }

    run();
  });

  if (!schema) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div>TODO: REPLACE WITH DYNAMIC FORM</div>

      <FormItem
        label="Supplier"
      >
        <Select
          disabled={isLoading}
          value={item.supplierId}
          onChange={x => mutate({ ...item, supplierId: x })}
          
        >
          {suppliers.map(d => (
            <Option key={d.id} value={d.id}>{d.displayName}</Option>
          ))}
        </Select>
      </FormItem>
      {/* <SchemaForm
        schema={schema}
        onChange={mutate}
        value={item}
      /> */}
    </>
  )
};

const definition : Definition<CommonProductValues> = {
  itemTitle: 'Common Values',
  listingLink: PageId.productsCommon,
  saveItem: productCommonValuesService.save,
  getItem: productCommonValuesService.getCommonValues,
  newObject: {
  }
};

export default createEditComponent(definition, () => Template);
