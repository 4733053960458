import React from 'react';
import StyledButton from './button';
import {
  LoadingOutlined,
} from '@ant-design/icons';

type SaveButtonProps = {
  isSaving: boolean;
  isLoading: boolean;
  onClick: () => void;
}

export default (props : SaveButtonProps) => {
  switch (true) {
    case props.isSaving:
      return (
        <StyledButton
          disabled={true}
          type="primary"
        >
          <LoadingOutlined spin /> Saving...
        </StyledButton>
      )
    case props.isLoading:
      return (
        <StyledButton
          type="primary"
          disabled={true}
        >
          <LoadingOutlined spin /> Loading...
        </StyledButton>
      )
    default:
      return (
        <StyledButton
          type="primary"
          onClick={props.onClick}
        >
          Save
        </StyledButton>
      )
  }
}