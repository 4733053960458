import React from 'react';
import styled from 'styled-components'; 
import { FieldProps } from '../../field-props';
import AddButton from './add-button';
import RemoveButton from './remove-button';
import { FormField } from '../../types';
import factory from '../../factory';

const debug = require('debug')('mk:dyn-form:multiple-items');

const Items = styled.div`
  width: 100%;
`;

const ItemContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0;
`;

const Item = styled.div`
  padding: 0;
  width: 100%;
`;

const RemoveContainer = styled.div`
  padding: 4px 0px 0px 5px;
`;

export default class MultipleItems extends React.Component<FieldProps> {
  constructor(props : FieldProps) {
    super(props);

    this.addItem = this.addItem.bind(this);
    this.handleChangeItem = this.handleChangeItem.bind(this);
  }

  addItem() {
    const currentItems = this.props.value || [];
    const newItems = [...currentItems, ''];
    this.props.onChange(newItems);
  }

  shouldComponentUpdate(nextProps: FieldProps) {
    return nextProps.value !== this.props.value
      || nextProps.field !== this.props.field
      || nextProps.validationState !== this.props.validationState
      || nextProps.isDisabled !== this.props.isDisabled;
  }

  handleChangeItem(index: number, val: any) {
    const newValue = [...this.props.value];
    newValue[index] = val;
    this.props.onChange(newValue);
  }

  createItemField(index: number, field: FormField, value: any) {
    let additionalProps = {};
    if (field.type === 'text') {
      // @ts-ignore
      additionalProps.onKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.addItem();
        }

        if (e.keyCode === 40) { // dow  n arrow
          // this.
        }

        if (e.keyCode === 38) { // up arrow
          // this.
        }
      }
      // @ts-ignore
      additionalProps.shouldAutoFocus = true;
    }

    const itemValidationState = this.safeGetItemValidationState(field, index);

    debug(`itemValidationState ${field.name} ${index}`, itemValidationState);

    const { isDisabled } = this.props;

    const component = factory(field, value, v => this.handleChangeItem(index, v), additionalProps, itemValidationState, isDisabled);

    return component;
  }

  removeItem(index: number) {
    const currentItems = this.props.value;
    const newItems = [...currentItems];
    newItems.splice(index, 1);
    setImmediate(() => this.props.onChange(newItems)); // i'm not quite sure why, but this has to be done as setImmediate. Otherwise it was as if the addItem function was called
  }

  safeGetItemValidationState(field: FormField, index: number) {
    debug('field.validationState', field.validationState);
    if (!field.validationState) {
      return undefined;
    }

    if (!Array.isArray(field.validationState.items)) {
      return undefined;
    }

    return field.validationState.items[index];
  }

  render() {
    debug('Multiple Items - render', this.props.field.name);

    const { field, isDisabled } = this.props;
    const { name } = field;

    const value = this.props.value || [];
  
    return (
      <Items key={name}>
        <fieldset disabled={isDisabled}>
          {value.map( (val : any, index: number) => {
            return (
              <ItemContainer key={`${name}-${index}`}>
                <Item>
                  {this.createItemField(index, field, val)}
                </Item>
                <RemoveContainer>
                  <RemoveButton
                    isDisabled={isDisabled}
                    onClick={() => this.removeItem(index)}
                  />
                </RemoveContainer>
              </ItemContainer>
            )
          })}
        </fieldset>
  
        <div>
          <AddButton
            isDisabled={isDisabled}
            onClick={() => this.addItem()} />
        </div>
      </Items>
    )
  }
};