import React from 'react';
import { EditListState } from './logic';
import FormItem from '../../../components/forms/form-item';
import { Input } from 'antd';
import { List } from '../../../types/list';
import KeyValueList from '../../../components/key-value-list';

const debug = require('debug')('mk:pages:lists:edit:dumb');

interface Props {
  state: EditListState,
  mutateAction: (item: Partial<List>) => void
};

const addItem = (mutateAction: any, item: any) => {
  mutateAction({
    values: [...item.values!, { name: '', displayName: '' }]
  })
};

const removeItem = (mutateAction: any, item : any, index: number) => {
  const values = item.values!;

  values.splice(index, 1);

  debug('VALUES', values);

  setImmediate(() => {
    mutateAction({
      values
    })
  })  
};

const changeValue = (mutateAction: any, item: any, index: number, value: string) => {
  const values = item!.values!;
  values[index].name = value;

  mutateAction({ values })
}

const changeDisplayText = (mutateAction: any, item: any, index: number, value: string) => {
  const values = item!.values!;
  values[index].displayName = value;

  mutateAction({ values })
}

const Template: React.FunctionComponent<Props> = ({ state, mutateAction }) => {
  const item = state.item as List;

  return (
    <>
      <FormItem
        label="Display Name"
      >
        <Input
          value={item.displayName}
          disabled={state.isLoading}
          onChange={x => mutateAction({ displayName: x.target.value })} />
      </FormItem>

      <FormItem
        label="Items"
      >
        <KeyValueList
          items={item.values}
          onAddItem={() => addItem(mutateAction, item)}
          onRemoveItem={(index: number) => removeItem(mutateAction, item, index)}
          onChangeValue={(index: number, value: string) => changeValue(mutateAction, item, index, value)}
          onChangeDisplayText={(index: number, value: string) => changeDisplayText(mutateAction, item, index, value)}
        />
      </FormItem>
    </>
  )
}

export default Template;